import React, {Component} from 'react';
// components
import Slider from './components/BannerSlider';
import OurProject from './components/OurProjects';
import About from './components/AboutUs';
import Services from './components/Services';
import News from './components/NewsEvents';
import Map from './components/Map';
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Partner from './components/ParterShip'
import Footer from '../../components/Footer'
import {fetchHome} from "../../redux/actions/home";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import Message from '../../components/MessageBox';

import messageImg from '../../assets/images/msg.svg';
import {fetchProjectsMain} from "../../redux/actions/projects";
import {fetchNews} from "../../redux/actions/news";

import ScrollStyle from "../../scrollStyle";
import {Loader} from "../../components/Loader";


class HomePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_id]: '1'
        };
        this.props.getHome(mainParam);
        this.props.getProjectsMain();
        let newsParam = {
            [ApiParams.page_type]: 'news'
        };
        this.props.getNews(newsParam);
    }

    render() {
        let {homeData} = this.props;
        let {homeDataLoading} = this.props;
        let projectMain = this.props?.projectMain?.list
        const {news} = this.props;


        let filterNews = news ? news.posts ? news.posts.list.filter(f => f.data.featured === 'on') : [] : []

        let filterFeaturesProject = projectMain ? projectMain?.filter(f => f?.product_data && f?.product_data?.is_featured === 1) : [];

        let homeBanner = homeData ? homeData.find(f => f.page_data.slug === 'home-banner') : [];
        let aboutData = homeData ? homeData.find(f => f.page_data.slug === 'about-us') : [];
        let serviceData = homeData ? homeData.find(f => f.page_data.slug === 'services') : [];
        let partnerData = homeData ? homeData.find(f => f.page_data.slug === 'partnership') : [];
        let mapPageData = homeData ? homeData.find(f => f.page_data.slug === 'home-map') : [];

        let mapPost = mapPageData ? mapPageData.posts ? mapPageData.posts.list : [] : []

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {/*<Loader/>*/}
                {homeDataLoading ? <Loader/> : null}
                <ScrollStyle/>
                <Slider data={homeBanner}/>
                <OurProject data={filterFeaturesProject}/>
                <About data={aboutData}/>
                <Services data={serviceData}/>
                <News data={filterNews}/>
                <Partner data={partnerData}/>
                <Map mapData={mapPost}/>
                <Message msgImg={messageImg} bgColor={'#A65E58'} hoverColor={'#5D4E4D'}/>
            </motion.div>
        );
    }
}

function mapStateToProps(state) {
    return {
        homeData: state.homeReducer.homeData,
        homeDataLoading: state.homeReducer.homeDataLoading,
        projectMain: state.projectsReducer.projectMain,
        news: state.newsReducer.news,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHome: params => dispatch(fetchHome(params)),
        getProjectsMain: () => dispatch(fetchProjectsMain()),
        getNews: params => dispatch(fetchNews(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);




