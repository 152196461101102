import React, {Component, useEffect, useRef, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../../../globalStyleVars';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import {fetchHomeBannerSlider} from "../../../redux/actions/home";
import {ApiParams} from "../../../constants/apiParams";
import {LoaderOne} from '../../../components/Loader';
import {BsChevronRight, BsSearch, BsChevronDown} from 'react-icons/bs';
import {Link} from 'react-router-dom'
import {gsap} from "gsap/gsap-core";
import {CSSPlugin} from 'gsap/CSSPlugin'
import {TimelineLite} from "gsap/gsap-core";
import CountUp from 'react-countup';
import goDown from '../../../assets/images/go-down-caret.svg';



// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img
import banner from '../../../assets/images/dynamic/banner.jpg';

const BannerSlider = ({data}) => {

    const bannerRef = useRef(null)
    const wordRef = useRef(null)

    gsap.registerPlugin(CSSPlugin);
    const [tl] = useState(new TimelineLite());

    useEffect(() => {
        const getImgWidth = document.querySelector('.banner-bg').offsetWidth;
        const getImgHeight = document.querySelector('.banner-bg').offsetHeight;
        // document.querySelector('.animated-img p').style.backgroundSize = getImgWidth * 1.5 + 'px' + ' ' +getImgHeight * 1.5 +'px';

        window.addEventListener('load',()=>{
           // setTimeout(()=>{
           //
           // },100)
            tl.to(bannerRef.current, {
                duration: 2.5,
                transform: 'scale(1)',
                delay: .5
            })
                .to(wordRef.current, {
                    duration: 1,
                    opacity: '.8',
                    // transform:'scale(1.01)',
                }, '-=.7')
                .fromTo(wordRef.current, {
                    duration: '-1',
                    backgroundSize: `${getImgWidth * 1.6}px   ${getImgHeight * 1.6}px`
                }, {
                    duration: 3.5,
                    backgroundSize: `${getImgWidth}px   ${getImgHeight}px`
                }, '-=.9')

                .to(wordRef.current, {
                    duration: 3,
                    opacity: '0',
                    // transform:'scale(.95)',
                }, '-=.7');
        })




    }, [])




    let pageData = data ? data.page_data : '';
    let BannerImage = data ? data.images ? data.images.list[0] : [] : [];
    let bannerImagePath = BannerImage ? baseUrl + BannerImage.path + BannerImage.img_name : '';

    return (
        <StyledBanner className='home-banner' img={bannerImagePath}>
            <div className="banner-bg">
                <img ref={bannerRef} src={baseUrl + BannerImage.path + BannerImage.img_name} alt=""/>
            </div>

            <div className="animated-img">
                <p ref={wordRef}>N</p>
                {/*<p>N</p>*/}
            </div>

            <Container>
                <Row>
                    <Col>
                        <div className="banner-text">
                            <p>{pageData ? pageData.sub_title : ''}</p>
                            <h2>{pageData ? pageData.short_desc : ''}</h2>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="project-menu">
                        <div className="project-menu__single">
                            <div className="project-menu__single__inner">
                                <p>Our Projects</p>
                            </div>

                        </div>

                        <div className="project-menu__single">
                            <div className="project-menu__single__inner">
                                <p>Ongoing</p>
                                <h4>Paving the path</h4>
                                <span><BsChevronRight/></span>
                                <Link to='/projects/ongoing'/>
                            </div>

                        </div>

                        <div className="project-menu__single">
                            <div className="project-menu__single__inner">
                                <p>Completed</p>
                                <h4>Dream turned to reality</h4>
                                <Link to='/projects/completed'/>
                                <span><BsChevronRight/></span>
                            </div>

                        </div>

                        <div className="project-menu__single">
                            <div className="project-menu__single__inner">
                                <p>Upcoming</p>
                                <h4>New footprints</h4>
                                <Link to='/projects/upcoming'/>
                                <span><BsChevronRight/></span>
                            </div>

                        </div>

                        <div className="project-menu__single">
                            <div className="project-menu__single__inner">
                                <p>Search</p>
                                <h4>Explore our projects</h4>
                                <Link to='/projects/ongoing'/>
                                <span><BsSearch/></span>
                            </div>

                        </div>
                    </div>
                </Row>

                <div className="go-down">
                    <img src={goDown} alt=""/>
                    <span></span>
                    <a href="#go-down"/>
                </div>

            </Container>

        </StyledBanner>
    )
}


const StyledBanner = styled.section`
  height: 100vh;
  position: relative;

  .animated-img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 500px;
      margin: 0;
      font-weight: 900;
      color: rgba(255, 255, 255, .09);
      background-image: url(${props => props.img});
      overflow: hidden;
      -webkit-background-clip: text !important;
      background-position: center;
      //transition:all 2s ease;
      //transform: scale(1.08);
      opacity: 0;
    }
  }

  .container {
    position: relative;
    height: 100%;

    .row {
      height: 100%;
    }
  }

  .banner-bg {
    position: absolute;
    height: 100%;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      top: 0;
      bottom: 0;
      left: 0;
    }

    img {
      object-fit: cover;
      height: 100vh;
      width: 100%;
      transform: scale(1.09);
      //transition:all 1.8s ease
    }
  }

  .banner-text {
    position: absolute;
    height: fit-content;
    padding-bottom: 15px;
    border-bottom: 1px solid #EBE7E7;
    //top: 0;
    bottom: 170px;
    left: 15px;
    z-index: 3;
    //right: 15px;
    margin: auto;

    p {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 4px;
      padding-left: 3px;
    }

    h2 {
      font-size: 50px;
      font-weight: bold;
      //line-height: 20px;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 0;
    }
  }

  .project-menu {
    height: 180px !important;
    position: absolute;
    bottom: -90px !important;
    z-index: 9;
    display: flex;
    width: calc(100% - 30px);
    left: 15px;

    &__single {
      height: 100%;
      background-color: #F2E8DC;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      border-right: 1px solid #5D4E4D;

      &:nth-of-type(1) {
        border-right: 0;
        background-color: #5D4E4D;

        p {
          font-size: 27px;
          color: #ffffff;
        }
      }

      &:nth-last-of-type(1) {
        border-right: 0;
      }

      &__inner {
        padding: 30px 20px 20px 25px;
        position: relative;
        width: 100%;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
        }

        p {
          font-size: 27px;
          font-weight: bold;
          color: #5D4E4D;
          line-height: 30px;
          text-transform: capitalize;
        }

        h4 {
          font-size: 14px;
          line-height: 20px;
          color: #5d4e4d;
          margin: 0;
          position: absolute;
          border: 30px;
          bottom: 30px;
        }

        span {
          height: 30px;
          width: 30px;
          background: #FFF;
          border-radius: 50%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 30px;
          right: 15px;

          &:after {
            content: '';
            height: 0;
            width: 0;
            background-color: #5D4E4D;
            position: absolute;
            border-radius: 50%;
            transition: all .3s ease;
          }

          svg {
            height: 12px;
            transition: color .4s ease;
            position: relative;
            z-index: 3;
          }
        }

        &:hover {
          span {
            &:after {
              height: 100%;
              width: 100%;
            }

            svg {
              color: #FFF;
            }
          }

        }
      }

    }


    @media (max-width: 1290px) {
      .project-menu__single__inner p {
        font-size: 24px;
      }
    }
    @media (max-width: 1200px) {
      .project-menu__single__inner p {
        font-size: 17px;
      }
    }
    @media (max-width: 900px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    height: 600px;
    .OurProject__left-text {
      min-width: 50%;
      display: inline-block;
    }

  }


  &.banner-loaded {

    .banner-bg img {
      transform: scale(1.01);
    }

    .animated-img p {
      transform: scale(1.01);
      transition-delay: 2s;
      //opacity:1;
    }
  }

  &.banner-loaded-again {
    .animated-img p {
      opacity: 0;
      //transition:.9s ease;
    }
  }

  .go-down {
    position: absolute;
    height: 36px;
    width: 36px;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.52);
    bottom: 110px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9;
    overflow: visible;

    img {
      height: 7px;
      z-index: 3;
    }

    span {
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      background-color: #A65E58;
      border-radius: 50%;
      opacity: 0;
      transition: all .5s ease;
    }

    &:before {
      height: 100%;
      width: 100%;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.60);
      animation: pulseOne 2s ease infinite;
    }

    &:after {
      height: 100%;
      width: 100%;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.60);
      animation: pulseOne 2s ease infinite;
      animation-delay: .35s;
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 4;
    }

    &:hover {
      border: 1px solid #A65E58 !important;
      span {
        height: 100%;
        width: 100%;
        opacity: 1;
      }
    }

  }

  @keyframes pulseOne {
    0% {
      transform: scale(1);
    }
    80% {
      transform: scale(1.7);
    }
    100% {
      transform: scale(1.9);
      opacity: 0;
    }
  }

  
  
  @media(max-width: 991px){
    .go-down{
      bottom: 30px;
    }
    
    .banner-text{
      bottom: 150px;
    }
  }
  
  @media(max-width: 767px){
    .banner-text h2{
      font-size: 35px;
    }

    .animated-img p{
      font-size: 400px;
    }
  }
  

`;

export default BannerSlider;