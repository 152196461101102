import {FOOTER} from "../../constants/footer";

const initialState = {
  footer: '',
  footerLoading: false,
  footerErr: ''
}

export default function footerReducer(state=initialState, action) {
  switch (action.type) {
    case FOOTER.FETCH_FOOTER.MAIN:
      return {
        ...state,
        footer: '',
        footerLoading: true,
        footerErr: ''
      }

    case FOOTER.FETCH_FOOTER.SUCCESS:
      return {
        ...state,
        footer: action.result,
        footerLoading: false,
        footerErr: ''
      }
    case FOOTER.FETCH_FOOTER.FAILURE:
      return {
        ...state,
        footer:'',
        footerLoading: false,
        footerErr: 'Error fetching data'
      }

    default:
      return state
  }
}
