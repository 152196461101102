import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {ABOUT} from "../../constants/about";

function* fetchAboutMain(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: ABOUT.FETCH_ABOUT_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: ABOUT.FETCH_ABOUT_PAGE.FAILURE
    })
  }
}

function* fetchAboutChild(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    )

    yield put({
      type: ABOUT.FETCH_ABOUT_CHILD.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: ABOUT.FETCH_ABOUT_CHILD.FAILURE
    })
  }
}


export default function* aboutSagas() {
  yield takeLatest(ABOUT.FETCH_ABOUT_PAGE.MAIN, fetchAboutMain);
  yield takeLatest(ABOUT.FETCH_ABOUT_CHILD.MAIN, fetchAboutChild)
}
