import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {fetchLandowners} from "../../redux/actions/landowners";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Text from '../../components/TextSection';
import LandownersForm from './components/LandownersContactForm';
import htmlParser from "react-html-parser";
import BannerImg from "../../assets/images/dynamic/aboutBg.jpg";
import ContactText from './components/Contact-text';
import Address from './components/Address';
import Footer from '../../components/Footer'
import {Loader} from "../../components/Loader";

class Landowners extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'landowner'
        };
        this.props.getLandowners(mainParam);

    }

    render() {

        let {landowners} = this.props;

        let pageData = landowners && landowners.page_data;
        let bannerImage = landowners && landowners.images && landowners.images.list[0];

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loader/>}
                <InnerBanner
                    text={pageData && pageData.title}
                    subTitle={'Contact'}
                    image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}
                />

                <ContactText data={pageData}/>
                <LandownersForm/>

            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        landowners: state.landownersReducer.landowners,
        loading: state.landownersReducer.landownersLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLandowners: params => dispatch(fetchLandowners(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landowners);
