import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {PARTNERS} from "../../constants/partners";
import {ApiServices} from "../../../network/ApiServices";

function* fetchPartners(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: PARTNERS.FETCH_PARTNERS.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: PARTNERS.FETCH_PARTNERS.FAILURE
        })
    }
}


export default function* partnersSaga() {
    yield takeLatest(PARTNERS.FETCH_PARTNERS.MAIN, fetchPartners)
}
