import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import mapImage from '../../../assets/images/dynamic/map.jpg';
import Link from '../../../components/Link';
import backgroundImg from '../../../assets/images/contact-bg.svg'
import {baseUrl} from '../../../components/BaseUrl';
import HtmlParser from 'react-html-parser';
import {post} from 'jquery';


const Address = ({top, posts}) => {


    let mapImage = posts ? posts.images ? posts.images[0] : '' : '';


    return (
        <StyleAddress top={top}>
            <Container>
                <Row>
                    <Col sm={4} className='contact-map'>
                        <div className="contact-map__img">
                            <a target="_blank" href={mapImage ? mapImage.extra_url : ''}/>
                            <img src={mapImage ? baseUrl + mapImage.path + mapImage.img_name : ''} alt=""/>
                        </div>
                    </Col>

                    <Col sm={{span: 6, offset: 1}} className='contact-address'>
                        <div className="contact-address__top">
                            <h2>Corporate Office</h2>
                            <p>{posts ? posts.data ? posts.data.extra_deacription_one : '' : ''}</p>
                        </div>

                        <div className="contact-address__bottom">
                            <div className="contact-address__bottom__single">
                                <p>Phone</p>

                                {HtmlParser(posts ? posts.data ? posts.data.description : '' : '')}
                                {/*<Link background={'#A65E58'} hoverBg={'#5d4e4d'} text={'Write to us'} />*/}
                            </div>

                            {posts?.data?.extra_deacription_two &&
                                <div className="contact-address__bottom__single">
                                    <p>Fax</p>
                                    <a>{posts ? posts.data ? posts.data.extra_deacription_two : '' : ''}</a>
                                </div>
                            }

                        </div>

                    </Col>
                </Row>
            </Container>
        </StyleAddress>
    )
}
const StyleAddress = styled.section`
  background-color: #EBE7E7;
  padding-top: 150px;
  position: relative;

  &:after {
    content: "";
    width: 80%;
    top: ${props => props.top || '0'};
    bottom: 0;
    background-image: url(${backgroundImg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
  }

  .contact-map {
    &__img {
      padding-top: 128.09%;
      position: relative;
      height: 100%;
      z-index: 1;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }

  .contact-address {
    padding-bottom: 150px;
    position: relative;
    z-index: 2;

    &__top {
      margin-bottom: 80px;

      h2 {
        font-size: 50px;
        font-weight: bold;
        line-height: 50px;
        color: #5d4e4d;
        text-transform: capitalize;
        margin: 0 0 25px 0;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        color: #5D4E4D;
        margin: 0;
      }

    }

    &__bottom {
      display: flex;
      justify-content: space-between;

      &__single {
        p {
          color: #5d4e4d;
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 14px 0;

        }

        a:not(.link a) {
          font-size: 20px;
          font-weight: bold;
          color: #A65E58;
          line-height: 25px;
          display: block;

          &.mail {
            margin-top: 53px;
            color: #5d4e4d;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        .link {
          margin-top: 50px;
        }
      }
    }

  }

  @media (max-width: 769px) {
    padding-top: 75px;
    //padding-bottom: 75px;
    .contact-map {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .contact-address {
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: 50px;

      &__top {
        margin-bottom: 30px;

        h2 {
          font-size: 30px;
          line-height: 35px;
        }
      }
    }

  }

`;
export default Address;
