import {ABOUT} from "../../constants/about";

export function fetchAboutMain(params) {
  return {
    type: ABOUT.FETCH_ABOUT_PAGE.MAIN,
    params
  }
}

export function fetchAboutChild(params) {
  return {
    type: ABOUT.FETCH_ABOUT_CHILD.MAIN,
    params
  }
}
