import React, { isValidElement, useEffect, useRef, useState} from 'react';
import Splitting from 'splitting';
import styled from "styled-components";
import VisibilitySensor from 'react-visibility-sensor';
import ReactHtmlParser from "react-html-parser";
window.React = React;
window.Splitting = Splitting;
const TextSlideChildrenAnimation = ({
                                        by = 'lines',
                                        direction = "up", // currently support only up and down
                                        duration = "1.6s", // in second or millisecond like 1.6s or 1600ms
                                        className = "",
                                        children,
                                        once = false,
                                        offset = {},
                                        minTopValue = 10,
                                        partialVisibility = true
                                    }) => {
    const [isActive, setActive] = useState(true);
    let elRef = useRef(null),
        timeOutId,
        durationToMilliSecond = parseFloat(duration) * (duration.indexOf('ms') === -1 ? 1000 : 1);
    useEffect(() => {
        if(isValidElement(children) || (Array.isArray(children) && children.length)) {
            let splitConfig = {
                target: elRef.current,
                by,
                force: true
            };
            const splittings = Splitting(splitConfig);
            if(!elRef.current.querySelector('.word-wrap')) {
                splittings.forEach((splitting) => {
                    splitting.words.forEach((word) => {
                        /*
                        let span = document.createElement('span');
                        span.classList.add('word-wrap');
                        span.innerHTML = ReactHtmlParser(word.innerHTML);
                        word.innerHTML = span.outerHTML;
                        */
                        word.innerHTML = `<span class="word-wrap">${ReactHtmlParser(word.innerHTML)}</span>`;
                        // word.innerHTML = `<span class="word-wrap">${word.innerHTML}</span>`;
                    });
                });
            }
        }
        return () => {
            window.clearTimeout(timeOutId);
        };
    }, [children]);
    return (
        <VisibilitySensor partialVisibility={partialVisibility} minTopValue={minTopValue} active={isActive} offset={offset} onChange={(inView) => {
            elRef.current.classList[inView ? 'remove' : 'add']("dc-animation--disabled");
            elRef.current.classList[inView ? 'add' : 'remove']("dc-animation--active");
            if (inView) {
                if(timeOutId) {
                    window.clearTimeout(timeOutId);
                }
                timeOutId = window.setTimeout(() => {
                    if(elRef && elRef.current) {
                        elRef.current.classList.add("word-no-padding-margin");
                    }
                }, durationToMilliSecond);
                if(once && isActive) {
                    setActive(false);
                }
            } else {
                elRef.current.classList.remove("word-no-padding-margin");
            }
        }}>
            <StyledDiv duration={duration} ref={elRef}
                       className={`dc-animation text-slide text-slide--${direction} ${className}`}
                       style={{'--duration': duration}}>
                {children}
            </StyledDiv>
        </VisibilitySensor>

    );
};

const StyledDiv = styled.div`
`;

export default TextSlideChildrenAnimation;