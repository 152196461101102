import React, {Component, useLayoutEffect} from 'react';
import Error from './components/404';
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import ErrorStyle from '../../errorStyle';

const ErrorPage =()=>{
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(

        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ErrorStyle />
            <Error />
        </motion.div>
    )
};

export default ErrorPage;









