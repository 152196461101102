import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {baseUrl} from "../../../components/BaseUrl";
import Slick from "react-slick";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import htmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";

const FeatureAmenities = ({data}) => {


    useEffect(() => {
        // let getAllLi = document.querySelectorAll('.detail-menu li');
        //
        // getAllLi.forEach(e=>{
        //     e.addEventListener('click',()=>{
        //         if( document.querySelector('.detail-menu .active') ){
        //             document.querySelector('.detail-menu .active').classList.remove('active')
        //         }
        //         e.classList.add('active')
        //     })
        // })
    }, []);


    function RightArrow(props) {
        const {className, style, onClick} = props;
        return (
            <li className="go-right" onClick={onClick}><BsChevronRight/></li>
        );
    }

    function LeftArrow(props) {
        const {className, style, onClick} = props;
        return (
            <li className="go-left" onClick={onClick}><BsChevronLeft/></li>
        );
    }

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5500,
        autoplay: false,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>
    };


    return (
        <StyledIntegrity id='features'>
            <Container>

                {data.length && <Slick {...settings}>

                    {data && data.map(item => (
                        <div key={item.data.id}>
                            <Row>
                                <Col sm='5' className='left-content'>
                                    <h3>Features and Amenities</h3>
                                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                        {({isVisible}) =>
                                            <div className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                                                <h2>{item.data.title}</h2>
                                                {htmlParser(item.data.description)}
                                            </div>

                                        }
                                    </VisibilitySensor>
                                </Col>

                                <Col sm={{span: 5, offset: 2}} className="right-content">
                                    <div className="right-content__inner">
                                        <img
                                            src={item.images && baseUrl + item.images[0].path + item.images[0].img_name}
                                            alt=""/>
                                        <h3>{item.data.post_type}</h3>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    ))
                    }


                </Slick>}

            </Container>
        </StyledIntegrity>
    )
};
const StyledIntegrity = styled.section`
  padding-top: 45px;
  background-color: #5D4E4D;
  padding-bottom: 200px;

  .left-content {
    h3 {
      font-size: 16px;
      padding-bottom: 20px;
      min-width: 80%;
      display: inline-block;
      color: #ffffff;
      border-bottom: 1px solid #FFFFFF;
      margin: 0;
    }

    h2 {
      font-size: 50px;
      margin: 50px 0 40px 0;
      text-transform: capitalize;
      color: #ffffff;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      color: #ffffff;
      line-height: 20px;
      margin: 61px 0 0 0;
    }

    ul {
      padding-left: 16px;
      padding-bottom: 100px;

      li {
        list-style: disc;
        font-size: 16px;
        line-height: 21px;
        color: #ffffff;
        margin-bottom: 5px;
      }
    }


  }

  .right-content {
    //padding-left: 130px;

    &__inner {
      padding-top: 66.62%;
      position: relative;
      min-height: 100%;

      h3 {
        font-size: 100px;
        line-height: 50px;
        font-weight: bold;
        color: #ffffff;
        position: absolute;
        bottom: 100px;
        left: -95px;
        margin: 0;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }
  }

  .go-right {
    height: 50px;
    width: 50px;
    background-color: #CFCDCC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    left: 70px;
    z-index: 5;

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      height: 0;
      width: 0;
      background-color: #A65E58;
      position: absolute;
      border-radius: 50%;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }

  .go-left {
    height: 50px;
    width: 50px;
    background-color: #CFCDCC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    z-index: 5;
    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      height: 0;
      width: 0;
      background-color: #A65E58;
      position: absolute;
      border-radius: 50%;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }

  @media (max-width: 991px) {
    .row {
      flex-direction: row-reverse;
    }

    .col-sm-5{
      flex: 0 0 50%;
      max-width: 50%;
    }
    
    .right-content {
      padding-left: 15px;
      margin: 0;
      flex: 0 0 50%;
      
      
      h3 {
        left: 15px;
        font-size: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 70px;
    padding-top: 0px;
    .container {
      padding: 0;
    }

    .slick-slider {
      padding-bottom: 70px;
    }

    .row {
      flex-direction: column-reverse;
    }

    .left-content {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 30px;

      p {
        margin-top: 30px;
      }

      h3 {
        display: none;
      }

      h2 {
        font-size: 28px;
      }
    }

    .right-content {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0;

      h3 {
        top: -23px;
        font-size: 40px;
        line-height: 45px;
      }
    }

    .slick-track {
      padding-top: 30px;
    }

    .go-left {
      left: 15px;
    }

    .go-right {
      left: 80px;
    }
  }



`;

export default FeatureAmenities;










