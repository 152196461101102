import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightIcon from "../../../assets/images/next-black.svg";
import LeftIcon from "../../../assets/images/prev-black.svg";
import HtmlParser from 'react-html-parser';
import {hover} from "../../../globalStyleVars";

function RightArrow(props) {
    const {className, style, onClick} = props;
    return (
        <li className='go-right' onClick={onClick}><img src={RightIcon} alt=""/></li>
    );
}

function LeftArrow(props) {
    const {className, style, onClick} = props;
    return (
        <li className='go-left' onClick={onClick}><img src={LeftIcon} alt=""/></li>
    );
}


const Chairman = ({post}) => {

  let pageData = post ? post.page_data : '';

  let getImage = post ? post.images ? post.images.list[0] :'':'';
  

    const settings = {
        dots: false,
        // fade: true,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplaySpeed: 7500,
        autoplay: false,
        arrows: true,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4500
                }
            },
        ]
    };

  

    return (
        <StyledChairman>
            <Container>
                <Row>

                    <Col sm={6} className='chairman-left'>
                        <div className="chairman-left__inner">
                            <img src={getImage ? baseUrl + getImage.path + getImage.img_name : ''} alt="image"/>
                            {/*<div className="chairman-left__inner__content">*/}
                            {/*    <h4>{pageData ? pageData.sub_title:''}</h4>*/}
                            {/*    <p>{pageData ? pageData.title:''}</p>*/}
                            {/*</div>*/}

                        </div>
                    </Col>

                    <Col sm={6} className='chairman-right'>

                        <div className="chairman-right__content">
                            <h4>{pageData ? pageData.sub_title : ''}</h4>
                            <h5>{pageData ? pageData.title : ''}</h5>
                        </div>
                        <p>{HtmlParser(pageData ? pageData.description : '')}</p>
                    </Col>

                </Row>
            </Container>
        </StyledChairman>
    )
};

const StyledChairman = styled.section`
  background-color: #F2E8DC;

  .chairman-left {

    &__inner {
      padding-top: 91%;
      position: relative;
      min-height: 100%;
      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }

     
      &__content{
        position: absolute;
        left: -85px;
        top: 120px;
        width: 50%;
        h4 {
          font-size: 50px;
          font-weight: bold;
          color: #5D4E4D;
          margin: 0;
          line-height: 50px;
        }
        p{
          color: #5D4E4D;
          font-size: 16px;
          line-height: 20px;
          margin: 10px 0 0 0;

        }
      }
    }
  }

  .chairman-right {
    padding-top: 120px;
    padding-left: 60px;
    padding-bottom: 150px;
    p {
      color: #5D4E4D;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }

    &__content {
      h4 {
         font-size: 40px;
        font-weight: bold;
        color: ${hover};
      }
      h5{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 40px;
        color: ${hover};
      }
    }

  }

  @media (max-width: 1199px) {
    .chairman-right {
      padding-top: 90px;
      padding-left: 50px;
    }

    .chairman-left__inner__content {
      width: 80%;
    }
  }
  
  @media(max-width:991px){
    .chairman-right {
      padding-top: 45px;
      padding-left: 5px;
    }
  }
  
  @media(max-width:769px){
    overflow: visible;
    .container{
      overflow: visible;
    }
    
    .chairman-left {
      padding-left: 15px;
      .chairman-left__inner__content{
        display: none;
      }
    }
  }

  @media(max-width:767px){
    padding-bottom: 60px;
    .container{
      padding: 0;
    }
    .chairman-right{
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 0px;
      img{
        height: 60px;
        top: -40px;
      }
      &__content{
        display: block;
        margin-top: 0px;
        //text-align: right;
        h4{
          color: #5d4e4d;
          font-size: 25px;
          margin: 0;
          font-weight: bold;
          text-transform: uppercase;
        }
        h5{
          color: #5d4e4d;
          font-size: 16px;
          line-height: 20px;
          margin: 10px 0 30px 0;
        }
      }
    }
  }
  
`;

export default Chairman;
