import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import htmlParser from "react-html-parser";
import Links from '../../../components/Link'
import Title from '../../../components/Title';
import {baseUrl} from "../../../components/BaseUrl";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";

const PartnerShip = ({data}) => {
    let banner = data ? data.images ? data.images.list[0] : [] : [];
    let partnersPost = data ? data.posts ? data.posts.list : [] : [];

    return (
        <StyledPartner>
            <img src={banner ? baseUrl + banner.path + banner.img_name : ''} alt=''/>

            <Container className='partner-ship'>
                <Title text='Partnership'/>
                <Row>

                    {partnersPost ? partnersPost.map(data => (
                        <Col key={data.data.id} sm={6} className='partner-ship__single'>
                            {/*<TextSlideChildrenAnimation>*/}
                                <h4>{data.data.title}</h4>
                            {/*</TextSlideChildrenAnimation>*/}
                            {/*<TextSlideChildrenAnimation>*/}
                                <p>{htmlParser(data.data.extra_deacription_one)}</p>
                            {/*</TextSlideChildrenAnimation>*/}

                            <Links link={data.data.extra_description} background={'#F2E8DC'} hoverColor={'#FFF'}
                                   color={'#5D4E4D'} text='Learn More'/>
                        </Col>
                    )) : 'Loading...'}
                </Row>
            </Container>
        </StyledPartner>
    )
};
const StyledPartner = styled.section`
  padding-top: 185px;
  padding-bottom: 184px;
  position: relative;

  &:after {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

  }

  .row {
    position: relative;
    z-index: 2;
  }

  > img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  .title {
    top: -18px;
    z-index: 2;
  }

  .partner-ship__single {
    &:nth-of-type(1) {
      padding-right: 100px;
    }
      
    &:nth-of-type(2){
      padding-left: 100px;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding-bottom: 16px;
    min-width: 200px;
    display: inline-block;
    border-bottom: 1px solid #FFFFFF;
    margin: 0;
    font-weight: bold;
  }

  p {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    margin: 52px 0 0 0;
    color: #ffffff;
  }

  .link {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    padding-top: 90px;
    padding-bottom: 90px;
    overflow: visible !important;
    .container, .container-sm {
      overflow: visible !important;
    }

    .partner-ship {
      .col-sm-5 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;

        &:nth-of-type(1) {
          position: relative;
          margin-bottom: 90px;
          padding-bottom: 90px;

          &:after {
            content: '';
            bottom: 0;
            left: 15px;
            right: 15px;
            height: 1px;
            position: absolute;
            background-color: #FFFFFF;
          }
        }

        p {
          font-size: 18px;
          margin-top: 30px;
        }
      }
    }

    .link {
      margin-top: 45px;
    }
  }
  
  @media(max-width: 767px){
    .partner-ship__single{
      flex:0 0 100%;
      max-width: 100%;
      padding:0 15px !important;
      &:nth-of-type(1){
        padding-bottom: 90px !important;
        margin-bottom: 90px;
        position: relative;
        &:after{
          content: '';
          height: 1px;
          left: 15px;
          right: 15px;
          bottom: 0;
          background-color: #FFF;
          position: absolute;
        }
      }
      p{
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  
  @media(min-width: 1500px){
    min-height: 80vh;
    display: flex;
  }

`;

export default PartnerShip;