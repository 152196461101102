import React, {Component} from "react";
import InnerBanner from '../../components/InnerBanner';
import TeamItem from './components/Team';
import Footer from '../../components/Footer'
import banner from '../../assets/images/dynamic/team.jpg';
import {fetchTeam,fetchDirectors} from "../../redux/actions/team";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import Directors from "./components/Directors";
import {Loader} from "../../components/Loader";

class Team extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'our-team'
        };
        let directorParam = {
            [ApiParams.page_id]: '13'
        };
        this.props.getTeam(mainParam);
        this.props.getDirectors(directorParam);
    }

    render() {

        const {team} = this.props;
        const {teamLoading} = this.props;
        const {directors} = this.props;



        let teamPost = team ? team.posts ? team.posts.list:[]:[];
        let Banner = team ? team.images? team.images.list[0]:[]:[];
        let pageData = team ? team.page_data:'';

        let getDirectors = directors && directors[0] && directors[0].posts && directors[0].posts.list;

        return (
            <>
                {teamLoading ? <Loader/> : null}
                <InnerBanner
                    text={pageData?pageData.title:''}
                    subTitle={pageData? pageData.sub_title:''}
                    image={Banner ? baseUrl+ Banner.path+Banner.img_name:''}
                />
                <Directors data={getDirectors} />
                <TeamItem post={teamPost} />
            </>

        )
    }


}

function mapStateToProps(state) {
    return {
        team: state.teamReducer.team,
        teamLoading: state.teamReducer.teamLoading,
        directors: state.teamReducer.directors,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTeam: params => dispatch(fetchTeam(params)),
        getDirectors: params => dispatch(fetchDirectors(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);


