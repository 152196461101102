import {PROJECTS} from "../../constants/projects";

let initialState = {
    projectMain: [],
    projectDetail:[],
    projectDetailLoading: false,
    projectMainLoading: false,
    projectMainErr: '',
    projectDetailErr: '',
};

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case PROJECTS.FETCH_PROJECTS_PAGE.MAIN:
            return {
                ...state,
                projectMain: [],
                projectMainLoading: true,
                projectMainErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_PAGE.SUCCESS:
            return {
                ...state,
                projectMain: action.result,
                projectMainLoading: false,
                projectMainErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_PAGE.FAILURE:
            return {
                ...state,
                projectMain: [],
                projectMainLoading: false,
                projectMainErr: 'Error from Server'
            };

        case PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.MAIN:
            return {
                ...state,
                projectDetail: [],
                projectDetailLoading: true,
                projectDetailErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.SUCCESS:
            return {
                ...state,
                projectDetail: action.result,
                projectDetailLoading: false,
                projectDetailErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.FAILURE:
            return {
                ...state,
                projectDetail: [],
                projectDetailLoading: false,
                projectDetailErr: 'Error from Server'
            };

        default:
            return state
    }
}


