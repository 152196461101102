import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {fetchAboutMain, fetchAboutChild} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import WhoWeAre from "./components/WhoWeAre";
import Integrity from "./components/Integrity";
import Team from "./components/Team";
import Chairman from "./components/Chairman";
import Footer from '../../components/Footer'
import BannerImg from "../../assets/images/dynamic/aboutBg.jpg";
import {fetchTeam} from "../../redux/actions/team";
import {Loader} from "../../components/Loader";

class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'about-us'
        };

        let childParam = {
            [ApiParams.page_id]: '7'
        };

        let teamParam = {
            [ApiParams.page_type]: 'our-team'
        };
        


        this.props.getAboutMain(mainParam);
        this.props.getAboutChild(childParam);
        this.props.getTeam(teamParam);

        if (this.props.sectionId) {
            let elem = document.getElementById(this.props.sectionId);
            if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }


   

    render() {

        let {aboutMainLoading} = this.props;
        let {aboutChild} = this.props;
        let {aboutMain} = this.props;
        const {team} = this.props;

        let pageData = aboutMain ? aboutMain.page_data : '';
        let bannerImage = aboutMain ? aboutMain.images ? aboutMain.images.list[0]:[]:[];
        let fullBannerImage = bannerImage ? baseUrl + bannerImage.path + bannerImage.img_name : '';

        let findWhoWeAre = aboutChild ? aboutChild.find(f=>f.page_data ? f.page_data.slug === 'who-we-are' :'') :'';
        let findContDown = aboutChild ? aboutChild.find(f=>f.page_data ? f.page_data.slug === 'count-down' :'') :'';
        let findOurValue = aboutChild ? aboutChild.find(f=>f.page_data ? f.page_data.slug === 'our-values' :'') :'';
        let findChairman = aboutChild ? aboutChild.find(f=>f.page_data ? f.page_data.slug === 'chairman' :'') :'';

        let teamPost = team ? team.posts ? team.posts.list:[]:[];


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">

                {aboutMainLoading ? <Loader/> : null}
                <InnerBanner
                    text={pageData? pageData.short_desc:''}
                    subTitle={pageData? pageData.sub_title:''}
                    image={fullBannerImage}

                />
                <WhoWeAre mission={findWhoWeAre} count={findContDown}/>
                <Integrity post={findOurValue}/>
                {/*<Chairman post={findChairman}/>*/}
                {/*<Team post={teamPost}/>*/}

            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        aboutMain: state.aboutReducer.aboutMain,
        aboutMainLoading: state.aboutReducer.aboutMainLoading,
        aboutChild: state.aboutReducer.aboutChild,
        team: state.teamReducer.team
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAboutMain: params => dispatch(fetchAboutMain(params)),
        getAboutChild: params => dispatch(fetchAboutChild(params)),
        getTeam: params => dispatch(fetchTeam(params))
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
