import React, {useEffect, useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import styled from 'styled-components';
import ButtonSubmit from '../../../components/ButtonSubmit';
import {fetchFormData, postFormSubmit} from '../../../redux/actions/form';
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import {Loading} from '../../../components/Loading';
import {emailValidation, emptyValidation} from '../../../config/validator';


const ContactForm = (props) => {

    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'contact-form'

        };
        props.getFormData(mainParam);

    }, []);

    // let {onSubmit={handleSubmit}, submitForm, formSubmitErr, popup} = props;
    let {submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        name: '',
        email: '',
        message: ''
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };


    let handleInit = () => {
        setState({
            name: '',
            email: '',
            message: ''
        })
    }


    let handleSubmit = (e) => {

        e.preventDefault()
        let formField = {
            form_id: 'contact-form'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }


        formField["name"] = state.name
        formField["email"] = state.email
        formField["message"] = state.message

        let bodyFormData = new FormData()

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };


    return (
        <StyledContactForm>
            {!popup ? loading ? <Loading/> : '' : null}
            <Message/>
            <Container>
                <Row>
                    <Col md={7} className='contact-from-left '>
                        <p className='form-top'>The Nassa Holdings Limited has a dedicated team of highly trained
                            professionals to
                            provide excellent customer support service. After all, our prestigious clients
                            deserve only the best.and aftermarket service.</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className='form-group'>
                                <Form.Label>Name*</Form.Label>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              value={state.name} name='name' onChange={handleChange} type="text"
                                              placeholder="Enter your full name here"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Email*</Form.Label>
                                <Form.Control className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                              value={state.email} name='email' onChange={handleChange} type="email"
                                              placeholder="Enter your email ID here"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Message*</Form.Label>
                                <Form.Control value={state.message} name='message' onChange={handleChange}
                                              placeholder='Enter your message here' as="textarea" rows={1}/>
                            </Form.Group>

                            {/*<p className='form-bottom'>By pressing the «Send» button, I agree to the processing of my*/}
                            {/*    personal data in*/}
                            {/*    accordance with the Federal Law dated 27.07.2006 N 152-FZ "About personal date" and*/}
                            {/*    agree to following terms</p>*/}


                            <ButtonSubmit onSubmit={handleSubmit} border={'1px solid rgba(26,24,24,0.75)'}
                                          color={'rgba(26,24,24,0.75)'} margin={'80px 0 0 0'} text='Send'/>


                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledContactForm>
    )
};


const StyledContactForm = styled.section`
  padding-top: 124px;
  padding-bottom: 124px;

  .contact-from-left {
    p {
      &.form-top {
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        color: #5D4E4D;
        margin: 0;
      }
    }

    form {
      margin-top: 80px;
      padding-right: 100px;
    }

    label {
      font-size: 14px;
      color: #5D4E4D;
      line-height: 20px;
      font-weight: bold;
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 40px !important;
    }

    .form-control {
      height: 50px;
      line-height: 53px;
      color: #1a1818;
      font-size: 14px;
      font-weight: 300;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #5D4E4D;
      background-color: transparent;
      padding-left: 60px;

      &::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #CFCDCC;
      }
    }

    textarea {
      height: 50px;
      min-height: 50px !important;
      max-width: 100%;
      padding-top: 15px;
      line-height: 21px !important;
    }
  }

  @media (max-width: 769px) {
    .contact-from-left {
      max-width: 100%;
      flex: 0 0 100%;
      margin-left: 0px;
      padding-left: 40px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
    .contact-from-left {
      max-width: 100%;
      flex: 0 0 100%;
      margin-left: 0px;
      padding-left: 15px;

      .form-top {
        font-size: 16px !important;
      }

      form {
        margin-top: 40px;
        padding-right: 0;
      }
    }
  }

`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);



