import Index from './container/home/';
import React, {useEffect} from 'react';
import About from './container/about/';
import Career from './container/career/';
import CareerApply from './container/career/apply';
import StyledGlobal from './globalStyle';
import Landowners from './container/contact/landowner';
import Buyers from './container/contact/buyer';
import Footer from './components/Footer';
import Contact from './container/contact';
import News from './container/news';
import Error from './container/error';
import {Route, Switch, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import Menu from "./components/Menu";
import Projects from './container/projects';
import ProjectDetail from './container/projects/ProjectDetail';
import newsDetail from './container/news/details';
import Team from './container/team';
import Services from './container/services';
import AnimationStyle from './AnimationGlobalStyle';
import ReactGA from 'react-ga';

function App() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize('G-E26Z338B93');
        ReactGA.pageview(window.location.pathname + window.location.search);
        // console.log('running analytics')
    },[])
    return (
        <React.Fragment>
            <StyledGlobal/>
            <AnimationStyle/>
            <Menu/>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/" component={Index}/>
                    <Route exact path="/projects/:category" component={Projects}/>
                    <Route exact path="/project/:slug" component={ProjectDetail}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/team" component={Team}/>
                    <Route exact path="/career" component={Career}/>
                    <Route exact path="/career/:slug" component={Career}/>
                    <Route exact path="/Services" component={Services}/>
                    <Route exact path="/career/apply/:slug" component={CareerApply}/>
                    <Route exact path="/landowner" component={Landowners}/>
                    <Route exact path="/buyer" component={Buyers}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/news" component={News}/>
                    <Route exact path="/news/:slug" component={newsDetail}/>
                    <Route component={Error}/>
                </Switch>
            </AnimatePresence>
            <Footer/>
        </React.Fragment>
    );
}

export default App;
