import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import VisibilitySensor from "react-visibility-sensor";

const subTitle = ({text, fontSize, fontWeight, color, letterSpacing, lineHeight, margin}) => {
    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledSubTitle
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    lineHeight={lineHeight}
                    letterSpacing={letterSpacing}
                    margin={margin}
                    className={`${isVisible ? 'anim-active' : ''} fade-up sub-title`} >{text}
                </StyledSubTitle>
            }
        </VisibilitySensor>
    )
};


const StyledSubTitle = styled.h3`
    font-size: ${props => props.fontSize || 16}px;
    font-weight:${props => props.fontWeight || 500};
    margin:${props => props.margin || '0 0 80px 0'}; ;
    line-height:${props => props.lineHeight || 21}px;
    color:${props => props.color || 'rgba(0,0,0,0.51)'};
    text-transform: uppercase;
    letter-spacing:${props => props.letterSpacing || 2}px;
    @media (max-width:767px){
      font-size: 14px;
      margin-bottom: 30px;
    }
`;

export default subTitle;
