import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux'
import {fetchFooter} from '../redux/actions/footer'
import {Link} from "react-router-dom";
import {FaFacebookF, FaInstagram, FaYoutube} from 'react-icons/fa';
import moment from 'moment';
import {ApiParams} from "../constants/apiParams";
import htmlParser from "react-html-parser";

const Footer = (props) => {
    let {setSectionId} = props
    useEffect(() => {
        let elem = document.getElementById("main-container")
        if (elem && elem.offsetLeft) {
            props.getFooterOffset(elem.offsetLeft)
        }

        let mainParam = {
            [ApiParams.page_type]: 'footer'
        };
        props.getFooter(mainParam);

    }, [])

    let newDate = new Date()
    let {footer} = props;

    let pageData = footer && footer.page_data;

    return (
        <StyledFooter id="footer" className="footer pt-110">
            <Container>
                <Row>
                    <Col sm={2}>
                        <ul className='footer-social'>
                            <li><a href="#"><FaYoutube/></a></li>
                            <li><a href="#"><FaFacebookF/></a></li>
                            <li><a href="#"><FaInstagram/></a></li>
                        </ul>
                    </Col>

                    <Col sm={{span: 7, offset: 1}} className='pl-0'>
                        <div className="footer-menu d-flex flex-wrap">
                            <Col sm={4}>
                                <h4>Corporate Office</h4>
                                <p>{htmlParser(pageData && pageData.short_desc)}</p>

                            </Col>

                            <Col sm={4} className='pl-30'>
                                <h4>About Us</h4>
                                <ul>
                                    <li><Link to='/about'>About Us</Link></li>
                                    <li><Link to='/team'>Our Team</Link></li>
                                </ul>
                            </Col>

                            <Col sm={4}>
                                <h4>Services</h4>
                                <ul>
                                    <li><Link to='/services'>Landscaping</Link></li>
                                    <li><Link to='/services'>Contracting Maintenance</Link></li>
                                    <li><Link to='/services'>Consultancy</Link></li>
                                    <li><Link to='/services'>Civil Construction</Link></li>
                                </ul>
                            </Col>

                            <Col sm={4} className='contact-numbers'>
                                <h4>Contact</h4>
                                {htmlParser(pageData && pageData.description)}
                            </Col>

                            <Col sm={4} className='pl-30'>
                                <h4>Projects</h4>
                                <ul>
                                    <li><Link to='/projects/ongoing'>Ongoing</Link></li>
                                    <li><Link to='/projects/upcoming'>Upcoming</Link></li>
                                    <li><Link to='/projects/completed'>Completed</Link></li>
                                </ul>
                            </Col>



                        </div>
                    </Col>

                </Row>


                <Row className='bottom-copyright'>
                    <Col sm={3}>
                        <h4> © {moment(newDate).format('YYYY')} Nassa Holdings Limited.</h4>
                    </Col>


                    <Col sm={{span: 9}} className='d-flex justify-content-between'>
                        <p>All Rights Reserved.</p>
                        <a href='https://dcastalia.com/' target='_blank'>Designed & Developed by <span>Dcastalia</span></a>
                    </Col>
                </Row>
            </Container>

        </StyledFooter>
    )
};


const StyledFooter = styled.section`
    padding-top: 110px;
    padding-bottom: 110px;
    background-color: #5D4E4D;
    .footer-social{
      li{
        display: inline-block;
        a{
          display: flex;
          height: 37px;
          width: 37px;
          border-radius: 50%;
          background-color: #fff;
          align-items: center;
          justify-content: center;
          margin-right: 17px;
          position: relative;
          svg{
            width: 20px;
            position: relative;
            z-index: 2;
            transition:color .3s ease;
            color: #5d4e4d;
            font-size: 20px;
          }
          
          &:after{
            height: 0;
             width: 0;
             background-color: #A65E58;
             left: 0;
             right: 0;  
             top: 0;
             content: '';
             position: absolute;
             bottom: 0;
             transition:all .5s ease;
             margin: auto;
             border-radius:25px; 
          }
          &:hover{
            svg{
              color: #ffffff;
            }
            &:after{
             height: 100%;
             width: 100%;
           }
          }
        }
        &:nth-last-of-type(1){
          a{
            margin-right: 0;
          }
        }
      }
    }
    
    .footer-menu{
      h4{
        font-size: 14px;
        line-height: 20px;
        color: #ababab;
        margin:0 0 7px 0 ;
        text-transform: capitalize;
      }
      a{
        font-size: 14px;
        line-height: 16px;
         display: block;
         margin-bottom: 5px;
         text-transform: capitalize;
         color: #f7f3f0;
        &:hover{
          color: #cfcdcc !important;
        }
      }
      p{
        margin: 0;
        font-size: 14px;
        line-height: 20px;
         color: #f7f3f0;
      }
      .col-sm-4{
        margin-bottom: 50px;
        &:nth-last-of-type(2){
          li:nth-last-of-type(1) a{
            margin-top: 17px;
          }
        }
      }
    }
    
    .bottom-copyright{
    margin-top: 40px;
      h4{
        margin: 0;
        font-size: 14px;
        color: #ffffff;
      }
      p{
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        line-height: 20px;
      }
      a{
        color: #ababab;
        font-weight: 100;
        line-height: 20px;
        font-size: 14px;
        span{
          color: #ffffff;
          font-weight: bold;
        }
        &:hover{
          color: #ababab !important;
          text-decoration: underline;
        }
      }
    }
    @media(max-width:1200px){
      .footer-social li{
      display: block;
      margin-bottom: 15px;
      } 
    }
   
    @media(max-width:768px){
        padding-top: 70px;
        padding-bottom: 70px;
        .row:nth-of-type(1){
          flex-direction: column-reverse;
        }
        .footer-social li{
          display: inline-block !important;
        }
        .col-sm-2{
         flex:0 0 100%;
         max-width: 100%;
        }
        .col-sm-7{
         flex:0 0 100%;
         max-width: 100%;
         margin-left:0
        }
        .bottom-copyright{
          .col-sm-3{
             flex:0 0 40%;
             max-width: 40%;
          }
          .col-sm-9{
             flex:0 0 60%;
             max-width: 60%;
          }
        }
    }
    
    @media(max-width:767px){
      .footer-menu{
        .col-sm-4 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      .pl-30 {
        padding: 0 15px 0 15px !important;
      }

      .bottom-copyright {
        .col-sm-3, .col-sm-9 {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .d-flex {
          flex-wrap: wrap;

          p, a {
            display: block;
            flex: 0 0 100%;
            max-width: 100%;
          }

          a {
            margin-top: 20px;
          }
        }
      }
    }

  .contact-numbers {
    a {
      text-transform: unset;
    }
  }

`;


function mapStateToProps(state) {
    return {
        footer: state.footerReducer.footer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFooter: params => dispatch(fetchFooter(params))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);







