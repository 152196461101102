export const SERVICESTWO = {
  FETCH_SERVICESTWO_PAGE: {
    MAIN: 'FETCH_SERVICESTWO_PAGE',
    SUCCESS: 'FETCH_SERVICESTWO_PAGE_SUCCESS',
    FAILURE: 'FETCH_SERVICESTWO_PAGE_FAILURE'
  },
  FETCH_SERVICESTWO_LIST: {
    MAIN: 'FETCH_SERVICESTWO_LIST',
    SUCCESS: 'FETCH_SERVICESTWO_LIST_SUCCESS',
    FAILURE: 'FETCH_SERVICESTWO_LIST_FAILURE'
  },
  FETCH_SERVICESTWO_CHILD: {
    MAIN: 'FETCH_SERVICESTWO_CHILD',
    SUCCESS: 'FETCH_SERVICESTWO_CHILD_SUCCESS',
    FAILURE: 'FETCH_SERVICESTWO_CHILD_FAILURE'
  }
}
