import {SERVICESTWO} from "../../constants/services/servicesTwo";

let initialState = {
  serviceTwoPage: [],
  serviceTwoPageLoading: false,
  serviceTwoPageErr: ''
}

export default function serviceTwoReducer(state = initialState, action) {
  switch(action.type) {
    case SERVICESTWO.FETCH_SERVICESTWO_PAGE.MAIN:
      return {
        ...state,
        servicePage: [],
        servicePageLoading: true,
        servicePageErr: ''
      }

    case SERVICESTWO.FETCH_SERVICESTWO_PAGE.SUCCESS:
      return {
        ...state,
        servicePage: action.result,
        servicePageLoading: false,
        servicePageErr: ''
      }

    case SERVICESTWO.FETCH_SERVICESTWO_PAGE.FAILURE:
      return {
        ...state,
        servicePage: [],
        servicePageLoading: false,
        servicePageErr: 'Error from service page fetching'
      };

    default:
      return state
  }
}
