import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import {Link} from 'react-router-dom';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import bgImg from '../../../assets/images/bg.svg'
import {BsChevronLeft, BsChevronRight,} from 'react-icons/bs';
import plusImg from '../../../assets/images/plus.svg';
import {baseUrl} from "../../../components/BaseUrl";
import htmlParser from "react-html-parser";
import {Loader} from "../../../components/Loader";

const OurProjects = ({data}) => {

    const containerRef = useRef();
    const bgRef = useRef();
    const nextRef = useRef();
    const prevRef = useRef();

    let [rotate, setRotate] = useState(0)


    // console.log('get the data ==========', data)


    let sliderParams = {
        slidesPerView: 1,
        spaceBetween: 20,
        // centeredSlides: true,
        autoplay: {
            delay: 7000,
            disableOnInteraction: false
        },
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            // when window width is >= 320px
            768: {
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false
                },
                spaceBetween: 30,
                slidesPerView: 2,
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev'
                // },
            },

        }
    };


    useEffect(() => {
        let getOffset = containerRef.current.offsetLeft + 30;
        let getWidth = containerRef.current.offsetWidth;
        if (window.screen.width > 768) {
            document.querySelector('.OurProject__left-text').style.paddingLeft = getOffset + 'px';
        }
        //
        nextRef?.current?.addEventListener('click', () => {
            document.querySelector('.swiper-button-prev').click()
            setRotate(rotate => rotate - 25)
            document.querySelector('.bg-img').style.transform = `rotate(${rotate}deg)`;
            if (document.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled')) {
                nextRef.current.classList.add('disabled')
            } else {
                nextRef.current.classList.remove('disabled');
                prevRef.current.classList.remove('disabled');

            }
        });

        prevRef?.current?.addEventListener('click', () => {
            document.querySelector('.swiper-button-next').click()
            setRotate(rotate => rotate + 25)
            if (document.querySelector('.swiper-button-next').classList.contains('swiper-button-disabled')) {
                prevRef.current.classList.add('disabled')
            } else {
                prevRef.current.classList.remove('disabled');
                nextRef.current.classList.remove('disabled');
            }
        });


    }, [])

    useEffect(() => {
        document.querySelector('.bg-img').style.transform = `rotate(${rotate}deg)`
    }, [rotate])

    return (
        <StyledProject id='go-down'>
            <img className='bg-img' src={bgImg} alt=""/>
            <Container ref={containerRef}/>
            <Container fluid className='OurProject p-0'>
                <Row>
                    <Col sm={4} className='OurProject__left-text'>
                        {/*<TextSlideChildrenAnimation>*/}
                        <h4>Featured Projects</h4>
                        <h2>Testaments <br/>
                            of our skills <br/>
                            and ingenuity</h2>
                        <p>From cloud touching monuments
                            to aesthatic villas - we can and have covered all of it. Our business motto relies upon
                            our ability to deliver what we promise, transparency in work and the trust that we build
                            with our clients.</p>
                        {/*</TextSlideChildrenAnimation>*/}

                        {data && data?.length > 0 &&
                            <ul className="slider-nav">
                                <li ref={nextRef}><span><BsChevronLeft/></span></li>
                                <li ref={prevRef}><span><BsChevronRight/></span></li>
                            </ul>
                        }


                    </Col>


                    <Col sm={8} ref={bgRef}>
                        <div className="project-slider">

                            {data.length > 0 ?
                                <Swiper  {...sliderParams}>
                                    {data.length &&

                                        data.map(item => (
                                            <div key={item.product_data.id} className="project-slider__single">
                                                <div className="project-slider__single__inner">
                                                    <Link to={`/project/${item.product_data.slug}`}/>

                                                    <img
                                                        src={item.images.list.find(f => f.is_thumb === 'on') ? baseUrl + item.images.list.find(f => f.is_thumb === 'on').path + item.images.list.find(f => f.is_thumb === 'on').img_name : 'no image found'}
                                                        alt="product"/>

                                                    <div className="project-slider__single__inner__content">
                                                        <p>{item.product_data.title}</p>
                                                    </div>
                                                </div>
                                                <div className="project-slider__single__hover">
                                                    <div className="plus">
                                                        <img src={plusImg} alt="plusImg"/>
                                                    </div>

                                                    <div className="content">
                                                        <div className="content__inner">
                                                            <p>
                                                                {htmlParser(item.product_data.address)}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))

                                    }


                                </Swiper>
                                : <p>Loading...</p>
                                // <Loader/>
                            }


                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledProject>
    )

}

const StyledProject = styled.section`
  padding-top: 250px;
  padding-bottom: 200px;
  position: relative;

  .swiper-button-next, .swiper-button-next {
    opacity: 0;
    height: 50px;
    width: 50px;
  }

  .bg-img {
    position: absolute;
    width: 47%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    transition: transform .6s ease;
    object-fit: cover;
  }

  .OurProject {
    &__left-text {
      h4 {
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 36px 0;
        padding-bottom: 27px;
        color: #5D4E4D;
        border-bottom: 1px solid #5D4E4D;
      }

      h2 {
        font-size: 30px;
        font-weight: bold;
        margin: 0 0 60px 0;
        color: #5D4E4D;
        line-height: 30px;
      }

      p {
        font-size: 16px;
        color: #958d87;
        line-height: 20px;
        margin: 0;
      }

      .slider-nav {
        margin-top: 60px;
        bottom: 0;
        position: absolute;

        li {
          display: inline-block;
          cursor: pointer;
          height: 50px;
          width: 50px;

          &:nth-of-type(1) {
            margin-right: 20px;
          }

          span {
            height: 50px;
            width: 50px;
            //background:#5D4E4D;
            border: 1px solid #5D4E4D;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: all .3s ease;

            &:after {
              content: '';
              height: 0;
              width: 0;
              background-color: #5D4E4D;
              position: absolute;
              border-radius: 50%;
              transition: all .3s ease;
            }

            svg {
              height: 20px;
              transition: color .4s ease;
              position: relative;
              z-index: 3;
              color: #5D4E4D;
            }

            &:hover {
              border-color: transparent;

              &:after {
                height: 100%;
                width: 100%;
              }

              svg {
                color: #FFF;
              }
            }
          }
        }

      }
    }

    .project-slider {
      padding-left: 40px;
      position: relative;

      .loading-before-content {
        position: absolute;
        height: 200px;
        width: 200px;
        background-color: transparent;
        z-index: 5;

        .ldio-2p01d405ya4 div {
          height: 25px;
          width: 25px;
        }
      }


      &__single {
        &__inner {
          padding-top: 135.14%;
          position: relative;
          overflow: hidden;

          a {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
          }

          img {
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            transform: scale(1.01);
            transition: transform .6s ease;
          }

          p {
            font-size: 22px;
            line-height: 22px;
            position: absolute;
            bottom: 40px;
            width: 100%;
            padding: 0px 10px 0 40px;
            z-index: 2;
            color: #ffffff;
            margin: 0;
            text-transform: capitalize;
          }

          &:after {
            content: '';
            position: absolute;
            background-color: rgba(26, 24, 24, 0.25);
            z-index: 1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }

        &__hover {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .plus {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #A65E58;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .4s ease;

            img {
              object-fit: contain;
              height: 20px;
            }
          }

          .content {
            position: absolute;
            width: 50px;
            left: 23px;
            right: 23px;
            padding-top: 12%;
            border: 1px solid #FFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            margin: auto;
            transition: all .6s ease;
            opacity: 0;

            &__inner {
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              display: flex;
              align-items: center;
              padding-left: 20px;
              opacity: 0;
              transition: opacity .4s ease;

              p {
                margin: 0;
                color: #ffffff;
                font-size: 16px;
                line-height: 20px;
                opacity: 0;
                transition-delay: 0s;
                transition: opacity .3s ease;
                width: 50%;
              }
            }

          }
        }

        &:hover {
          .project-slider__single__inner {
            img {
              transform: scale(1.06);
            }
          }

          .project-slider__single__hover {
            .plus {
              width: 250px;
              height: 250px;
              opacity: 0;
            }

            .content {
              width: calc(100% - 46px);
              padding-top: 89%;
              opacity: 1;

              &__inner {
                opacity: 1;

                p {
                  opacity: 1;
                  transition-delay: .6s;

                }
              }
            }
          }
        }
      }

    }

    .swiper-container {
      padding-right: 80px;
    }

  }

  @media (max-width: 991px) {
    .OurProject__left-text p {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 70px 15px 70px 15px ;
    .OurProject__left-text {
      flex: 0 0 100%;
      max-width: 100%;

      p {
        display: block;
      }

      h2 {
        margin-bottom: 50px;
      }

      br {
        display: none;
      }
    }

    .col-sm-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .slider-nav {
      display: none;
    }

    .col-sm-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .project-slider {
      margin-top: 50px;
      padding-left: 0 !important;

      .swiper-container {
        padding-right: 100px !important;
      }
    }

  }

`;

export default OurProjects;