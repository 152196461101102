import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {fetchContactPageData} from "../../redux/actions/contact";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import imgs from '../../assets/images/contact-banner.jpg';
import Map from './components/Map';
import ContactForm from './components/ContactForm';
import BannerImg from "../../assets/images/dynamic/aboutBg.jpg";
import Footer from '../../components/Footer'
import Address from './components/Address';
import {baseUrl} from '../../components/BaseUrl';
import {Loader} from "../../components/Loader";

class Contact extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let param = {
            [ApiParams.page_type]: 'contact'
        };
        this.props.getContactData(param)
    }


    render() {

        let {address} = this.props;

        let pageData = address ? address.page_data : '';
        let bannerImage = address ? address.images ? address.images.list[0] : [] : [];

        let getPosts = address ? address.posts ? address.posts.list[0] : [] : [];


        return (
            <motion.div variants={PageAnimation} animate="show" exit="exit" initial="hidden">
                {this.props.loading && <Loader/>}
                <InnerBanner
                    text={pageData ? pageData.title : 'Contact'}
                    subTitle={pageData ? pageData.sub_title : ''}
                    image={bannerImage ? baseUrl + bannerImage.path + bannerImage.img_name : ''}
                />
                <Address posts={getPosts}/>
                <ContactForm/>


            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        address: state.contactReducer.address,
        loading: state.contactReducer.addressMainLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getContactData: params => dispatch(fetchContactPageData(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
