import React, {Component, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Links from '../../../components/Link';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';


// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gradient from '../../../assets/images/progress-overlay.png';
import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";
import {baseUrl} from "../../../components/BaseUrl";
import VisibilitySensor from "react-visibility-sensor";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";


const Team = ({post}) => {

    let sliderParams = {
        slidesPerView: 1,
        spaceBetween: 20,
        // centeredSlides: true,
        autoplay: {
            delay: 4500,
            disableOnInteraction: false,
            autoplay: false
        },
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            // when window width is >= 320px
            768: {
                autoplay: false,
                spaceBetween: 30,
                slidesPerView: 2,
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev'
                // },
            },

        }
    };
    const nextRef = useRef();
    const prevRef = useRef();


    useEffect(() => {
        let liSelector = document.querySelectorAll('.team-filter li');

        for (let i of liSelector) {
            i.addEventListener('click', () => {
                document.querySelector('.team-filter li.active').classList.remove('active');
                i.classList.add('active');
            })
        }

        // nextRef.current.addEventListener('click', () => {
        //     document.querySelector('.swiper-button-prev').click()
        // });
        //
        // prevRef.current.addEventListener('click', () => {
        //     document.querySelector('.swiper-button-next').click()
        // });


    }, [])


    const [showTeam, setShowTeam] = useState('')

    let corporatePost = post ? post.find(f => f.data.slug === 'corporate') : []
    let managementsPost = post ? post.find(f => f.data.slug === 'managements') : []

    let corporateImg = corporatePost ? corporatePost.images : []
    let managementsImg = managementsPost ? managementsPost.images : []

    let allPost = [...corporateImg, ...managementsImg]

    const handleFilterClick = (data) => {
        setShowTeam(data);
    }

    let filterTeam = post.length ? showTeam !== '' ? post.find(f => f.data.slug === showTeam).images : allPost : []


    return (
        <StyledTeam>
            <Container>
                <Row className='team-top'>
                    <Col>
                        <p>Our Team Members</p>

                    </Col>
                    <Col className="text-right">
                        <Links link={'/team'} text={'Show All'}/>
                    </Col>
                </Row>

                <Row className="team-filter">
                    <Col>
                        <ul className='filters'>
                            <li className='active' onClick={() => handleFilterClick('')}>All</li>
                            <li onClick={() => handleFilterClick('corporate')}>Corporate</li>
                            <li onClick={() => handleFilterClick('managements')}>Managements</li>
                        </ul>
                    </Col>
                    {/*<ul className="slider-nav">*/}
                    {/*    <li ref={nextRef}><span><BsChevronLeft/></span></li>*/}
                    {/*    <li ref={prevRef}><span><BsChevronRight/></span></li>*/}
                    {/*</ul>*/}
                </Row>

                <Row className='team-wrap'>
                    {/*<Swiper  {...sliderParams}>*/}
                        {filterTeam ? filterTeam.map((data, index) => (

                            <Col key={index} sm={3}>
                                <div className="team-wrap__single">
                                    <div className="team-wrap__single__img">
                                        <img src={baseUrl + data.path + data.img_name} alt=""/>
                                    </div>
                                    <div className="team-wrap__single__content">
                                        <h4>John Smith</h4>
                                        <p>Chairman</p>
                                    </div>
                                </div>
                            </Col>
                        )) : 'loading...'}
                    {/*</Swiper>*/}
                </Row>

            </Container>
        </StyledTeam>
    )
}


const StyledTeam = styled.section`
  padding-top: 120px;
  padding-bottom: 150px;

  .team-top {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 16px;
      padding-bottom: 20px;
      border-bottom: 1px solid #5D4E4D;
      min-width: 250px;
      color: #5d4e4d;
      display: inline-block;
    }
  }


  .team-filter {
    margin-top: 50px;
    position: relative;

    ul.filters {
      display: flex;
      border-bottom: 1px solid #5D4E4D;

      li {
        display: inline-flex;
        height: 77px;
        align-items: center;
        justify-content: center;
        padding: 0 55px;
        border-right: 1px solid #5D4E4D;
        cursor: pointer;

        &.active {
          background-color: #5D4E4D;
          color: #FFF;
        }
      }
    }

    .slider-nav {
      margin-top: 0px;
      position: absolute;
      right: 0;
      bottom: 13px;

      li {
        display: inline-block;
        cursor: pointer;
        height: 50px;
        width: 50px;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        span {
          height: 50px;
          width: 50px;
          background: #CFCDCC;
          border: 1px solid #CFCDCC;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: all .3s ease;

          &:after {
            content: '';
            height: 0;
            width: 0;
            background-color: #5D4E4D;
            position: absolute;
            border-radius: 50%;
            transition: all .3s ease;
          }

          svg {
            height: 20px;
            transition: color .4s ease;
            position: relative;
            z-index: 3;
            color: #FFF;
          }

          &:hover {
            border-color: transparent;

            &:after {
              height: 100%;
              width: 100%;
            }

            svg {
              color: #FFF;
            }
          }
        }
      }

    }

  }

  .team-wrap {
    margin-top: 80px;

    &__single {
      &__img {
        padding-top: 121.85%;
        position: relative;

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }

      &__content {
        h4 {
          font-size: 20px;
          font-weight: bold;
          line-height: 22px;
          margin: 20px 0 0 0;
          color: #5D4E4D;
          text-transform: capitalize;
        }

        p {
          margin: 10px 0 0 0;
          color: #5D4E4D;
          font-size: 16px;
          line-height: 22px;
        }

      }
    }
  }




`;


export default Team;
