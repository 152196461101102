import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Jobs from './components/Jobs';
import Menu from "../../components/Menu";
import {fetchCareerPageData, fetchCareerListData} from "../../redux/actions/career";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import BannerImg from "../../assets/images/dynamic/aboutBg.jpg";
import CareerText from './components/CareerText';
import Footer from '../../components/Footer';
import {withRouter} from 'react-router-dom'
import {Loader} from "../../components/Loader";

// img

class Career extends Component {
    componentDidMount() {
        // window.scrollTo(0, 0);

        let param = {
            [ApiParams.page_type]: 'career'
        }
        let childParam = {
            [ApiParams.page_id]: '15'
        }
        this.props.getCareerPageData(param)
        this.props.getCareerList(childParam)
    };

    render() {
        let {careerPage} = this.props
        let {careerPageList} = this.props
        let {slug} = this.props.match.params;


        let careerPageImg = careerPage ?
            careerPage.images ?
                careerPage.images.list ? careerPage.images.list[0] : null
                : null
            : null

        let careerBanner = careerPageImg ? baseUrl + careerPageImg.path + careerPageImg.img_name : '';

        let page_data = careerPage ? careerPage.page_data : '';


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loader/>}
                <InnerBanner
                    text={page_data ? page_data.title : ''}
                    subTitle={page_data ? page_data.sub_title : ''}
                    image={careerBanner}
                />
                <CareerText pageData={page_data}/>
                <Jobs pages={careerPageList} slug={slug}/>

            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        careerPage: state.careerReducer.careerPage,
        careerPageList: state.careerReducer.careerPageList,
        loading: state.careerReducer.careerPageLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCareerPageData: params => dispatch(fetchCareerPageData(params)),
        getCareerList: params => dispatch(fetchCareerListData(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Career));
