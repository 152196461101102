import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

const Title = ({text, fontSize, fontWeight, color, letterSpacing, lineHeight, textTransform, margin}) => {
    return (

        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledTitle className={`${isVisible ? 'anim-active' : ''} fade-up title`}
                             fontSize={fontSize}
                             fontWeight={fontWeight}
                             color={color}
                             lineHeight={lineHeight}
                             letterSpacing={letterSpacing}
                             textTransform={textTransform}
                             margin={margin}>{text}
                </StyledTitle>
            }
        </VisibilitySensor>
    )
};


const StyledTitle = styled.h2`
  font-size: ${props => props.fontSize || 80}px;
  font-weight: ${props => props.fontWeight || 'bold'};
  margin: ${props => props.margin || '0px'};;
  line-height: ${props => props.lineHeight || 42}px;
  color: ${props => props.color || '#FFF'};
  text-transform: ${props => props.textTransform || 'capitalize'};
  position: absolute;
  @media (max-width: 768px) {
    font-size: 30px !important;
    line-height: 30px;
  }
`;


export default Title;
