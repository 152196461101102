import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeServicesData} from "../../../redux/actions/home";
import ReactHtmlParser from "react-html-parser";
import Links from '../../../components/Link'
import {Link} from 'react-router-dom';
import 'swiper/css/swiper.css';
import {hoverNd} from "../../../globalStyleVars";
import moment from "moment";

class OurServices extends Component {

    componentDidMount() {

    }


    render() {
        let sliderParams = {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 1000,
            breakpoints: {
                767: {
                    autoplay: false,
                    spaceBetween: 30,
                    slidesPerView: 2,
                },
                768: {
                    autoplay: false,
                    spaceBetween: 30,
                    slidesPerView: 3,
                },
                992: {
                    autoplay: false,
                    spaceBetween: 30,
                    slidesPerView: 4,
                },

            }
        };

        let {data} = this.props


        return (
            <StyledServices>
                <Container>
                    <Row className='news-top'>
                        <Col>
                            <h4>News and Events</h4>
                        </Col>

                        <Col className='text-right'>
                            <Links link={'/news'} text={'Learn more'}/>
                        </Col>
                    </Row>

                    <div className='news-list'>
                        {data.length &&
                        <Swiper  {...sliderParams}>

                            {data.length ? data.map(item => (
                                <dvi key={item.data.id} className="news-list__single ">
                                    <div className="news-list__single__inner">
                                        <Link to={`/news/${item.data.slug}`}/>
                                        <p>{item.data.title}</p>
                                        <h4>{moment(item.data.Date || item.data.created_at).format('D')}</h4>
                                        <h5>{moment(item.data.Date || item.data.created_at).format('MMM')}</h5>
                                    </div>
                                </dvi>
                            )) : 'no data found'

                            }


                        </Swiper>
                        }


                        <Links link={'/news'} text={'Learn more'}/>
                    </div>

                </Container>
            </StyledServices>
        )
    }
}

const StyledServices = styled.section`
  padding-top: 160px;
  padding-bottom: 200px;
  background-color: #F4F4F4;

  .swiper-container {
    width: 100%;
  }

  .news-top {
    h4 {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 20px;
      border-bottom: 1px solid #5D4E4D;
      min-width: 260px;
      color: #5d4e4d;
      display: inline-block;
      margin: 0;
    }

    @media (max-width: 767px) {
      .link {
        display: none;
      }
    }
  }

  .news-list {
    margin-top: 50px;

    .link {
      display: none;
    }

    &__single {
      &__inner {
        padding-top: 140.74%;
        background-color: #5D4E4D;
        position: relative;

        &:after {
          height: 0;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: ${hoverNd};
          content: '';
          position: absolute;
          transition: height 0.6s cubic-bezier(0.76, 0, 0.24, 1);
        }

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        p {
          font-size: 20px;
          line-height: 22px;
          color: #ffffff;
          margin: 0;
          position: absolute;
          top: 40px;
          left: 30px;
          right: 48px;
          z-index: 2;
        }

        h4 {
          position: absolute;
          left: 30px;
          bottom: 80px;
          margin: 0;
          font-size: 60px;
          font-weight: bold;
          line-height: 50px;
          color: #ffffff;
          z-index: 2;
        }

        h5 {
          font-size: 16px;
          color: #ffffff;
          position: absolute;
          line-height: 20px;
          left: 30px;
          bottom: 30px;
          margin: 0;
          z-index: 2;
        }
      }

      &:hover {
        .news-list__single__inner {
          &:after {
            height: 100%;
          }
        }
      }

      &:nth-of-type(n+5) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      .link {
        display: block;
        padding-left: 15px;
        margin-top: 70px;
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
    .swiper-container {
      padding-right: 80px;
    }

    .news-list {
      margin-top: 70px;
    }
  }

`;

export default OurServices;