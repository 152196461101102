import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import alertIcon from '../assets/images/alert-icon.png';
import successIcon from '../assets/images/thumbs-up.svg';
import closeBlack from '../assets/images/close-black.svg';
import {connect} from 'react-redux'


const PopupMessage = ({title, message, popup}) => {

    let [popupMessage, setPopupMessage] = useState(false);

    useEffect(() => {
        setPopupMessage(popup)
    }, [popup])

    let handleClose = () => {
        setPopupMessage(false);
    };

    return (
        <>
            {
                popupMessage ?
                    <StyledPopup>
                        <div className="message-inner">
                            <img src={message.length ? alertIcon : successIcon} alt=""/>
                            <div className="messages">
                                <p>{title}</p>
                                {message ? <h4>{message}</h4> : <h4>{'Submitted successfully'}</h4>}
                            </div>
                            <div className="close-message" onClick={handleClose}>
                                <img src={closeBlack} alt=""/>
                            </div>
                        </div>

                    </StyledPopup>

                    : ''
            }

        </>

    )
};

const StyledPopup = styled.div`
   position: fixed;
   height: 100vh;
   width: 100vw;
   background-color:rgba(0,0,0,0.80);
   left: 0;
   top: 0;
   z-index: 9999999;
   .message-inner{
       width: 330px;
        padding: 40px 30px;
        background-color: #fff;
        position: absolute;
        z-index: 9;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        height: fit-content;
        box-shadow:0 0 20px rgba(0,0,0,0.2);
        img{
          height: 45px;
        }
   } 
   
    >img{
      height: 42px;
      object-fit: contain;
    }
    h4{
      margin-top: 10px;
      font-weight: bold;
      font-size: 18px;
    }
    p{
      font-size: 14px;
      color: rgba(0,0,0,0.6);
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
    }
    
    .close-message{
        position: absolute;
        right: 15px;
        top: 9px;
        cursor: pointer;
        img{
          height: 15px;
        }
    }
`;

function mapStateToProps(state) {
    return {
        popup: state.formReducer.popup,
        message: state.formReducer.formSubmitErr
    }
}

export default connect(mapStateToProps)(PopupMessage);









