import React, {useEffect, useRef, useState} from "react";
import {gsap, TimelineLite} from "gsap/gsap-core";
import {CSSPlugin} from 'gsap/CSSPlugin'
import styled from 'styled-components';
import {Form} from 'react-bootstrap';
import {BsFillEnvelopeFill, BsX} from "react-icons/bs";
import {ApiParams} from "../constants/apiParams";
import {emailValidation, emptyValidation} from "../config/validator";
import {fetchFormData, postFormSubmit} from "../redux/actions/form";
import {connect} from "react-redux";
import {Loading} from "./Loading";
import Message from "./PopupMessage";

const MessageBox = (props) => {

    let {bgColor, hoverColor, msgImg} = props;

    gsap.registerPlugin(CSSPlugin)
    const wrapRef = useRef(null)
    const circularRef = useRef(null)
    const circularImageRef = useRef(null)
    const boxRef = useRef(null)
    const closeRef = useRef(null)

    const [tl] = useState(new TimelineLite());

    useEffect(() => {
        circularRef.current.addEventListener('click', () => {
            wrapRef.current.classList.add('msg-open')
            gsap.to(wrapRef.current, {
                boxShadow:'0 0 20px rgba(221, 221, 221, 0.45)',
                delay:.3
            });
            tl.to(wrapRef.current, {
                height: '400px',
                width: '300px',
                duration: 0
            });

            tl.to(circularRef.current, {
                duration: .7,
                height: 600,
                width: 600,
                bottom: '-90px',
                right: '-90px'
            });


            tl.to(boxRef.current, {
                duration: .5,
                x: 0,
                opacity: 1
            }, '-=.6');

            gsap.to(circularImageRef.current, {
                duration: 0,
                opacity: 0
            });

        });

        closeRef.current.addEventListener('click', () => {
            wrapRef.current.classList.remove('msg-open')
            gsap.to(wrapRef.current, {
                boxShadow:'none',
                duration:0
            });
            tl.to(boxRef.current, {
                duration: .3,
                x: '100%',
                opacity: 0
            });

            tl.to(circularRef.current, {
                duration: .6,
                height: 50,
                width: 50,
                bottom: '30px',
                right: '30px'
            }, '-=.2');

            tl.to(circularImageRef.current, {
                duration: .2,
                opacity: 1,
            })

            tl.to(wrapRef.current, {
                height: '100px',
                width: '100px',
                duration: 0
            });

        });


    }, [])


    // form submit
    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'popup-form'

        };
        props.getFormData(mainParam);

    }, []);

    // let {onSubmit={handleSubmit}, submitForm, formSubmitErr, popup} = props;
    let {submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        name: '',
        email: '',
        message: ''
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };


    let handleInit = () => {
        setState({
            name: '',
            email: '',
            message: ''
        })
    }


    let handleSubmit = (e) => {

        e.preventDefault()
        let formField = {
            form_id: 'popup-form'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }


        formField["name"] = state.name
        formField["email"] = state.email
        formField["message"] = state.message

        let bodyFormData = new FormData()

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };


    return (
        <StyledMessageBox ref={wrapRef} bgColor={bgColor} hoverColor={hoverColor} msgImg={msgImg}>

            {!popup ? loading ? <Loading/> : '' : null}

            <Message/>

            <div className="circle-box" ref={circularRef}>
                <div className='circle-box__inner' ref={circularImageRef}>
                    {msgImg ? <img src={msgImg} alt=""/> : <BsFillEnvelopeFill/>}
                </div>

            </div>
            <div className="form-wrap" ref={boxRef}>
                <Form onSubmit={handleSubmit}>
                    <div className="close-msg" ref={closeRef}>
                        <BsX/>
                    </div>

                    <h4>Send us a message</h4>
                    <Form.Group className="form-group">
                        <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                      value={state.name} name='name' onChange={handleChange} type="text" placeholder="Your name"/>
                    </Form.Group>

                    <Form.Group className="form-group">
                        <Form.Control className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                      value={state.email} name='email' onChange={handleChange} type="email" placeholder="Your email"/>
                    </Form.Group>

                    <Form.Group className="form-group">
                        <Form.Control value={state.message} name='message' onChange={handleChange} as="textarea" rows={1} placeholder='Your message'/>
                    </Form.Group>

                    <button onSubmit={handleSubmit} type='submit'>Send</button>

                </Form>
            </div>
        </StyledMessageBox>
    )
}
const StyledMessageBox = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 9;
  height: 100px;
  width: 100px;
  //background-color: #fff;
  overflow: hidden;
  background-color: transparent;

  .circle-box {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: ${props => props.bgColor || 'green'};
    position: absolute;
    right: 30px;
    bottom: 30px;
    cursor: pointer;

    &__inner {
      position: relative;
      height: 100%;
      width: 100%;

      &:before {
        content: "";
        position: absolute;
        height: 0%;
        width: 0%;
        background-color: ${props => props.hoverColor || '#DDD'};
        border-radius: 50%;
        transition: all .4s ease;
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      &:hover:before {
        height: 100%;
        width: 100%;
        opacity: 1;
      }
    }

    svg {
      position: absolute;
      right: 17px;
      top: 18px;
      color: #ffffff;
    }

    img {
      position: absolute;
      right: 15px;
      top: 18px;
      height: 15px;
    }
  }

  .form-wrap {
    padding-top: 50px;
    background-color: #fff;
    transform: translateX(100%);
    opacity: 0;
    height: 100%;

    .close-msg {
      position: absolute;
      right: 20px;
      top: 15px;
      cursor: pointer;

      svg {
        font-size: 30px;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      padding-left: 20px;
      margin-bottom: 20px;
    }

    label {
      padding-left: 20px;
    }

    .form-control {
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #DDD;
      height: 50px;

      &:focus {
        border-color: #A65E58;
      }
    }

    textarea {
      height: 80px !important;
    }

    button {
      border: 0;
      box-shadow: none;
      background-color: #fff;
      font-weight: 500;
      padding-left: 20px;
      margin-top: 20px;
      transition: all .5s ease;

      &:hover {
        letter-spacing: 1px;
        color: #A65E58;
      }
    }
  }

  //&.msg-open {
  //  box-shadow: 0 0 20px rgba(221, 221, 221, 0.45);
  //}

  @media(max-width: 767px){
    display: none;
  }
  
`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);

