import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from "react-bootstrap";
import htmlParser from 'react-html-parser';


// img
import {baseUrl} from "../../../components/BaseUrl";
import VisibilitySensor from "react-visibility-sensor";
import spImg from '../../../assets/images/dynamic/project.jpg';
import specImg from '../../../assets/images/spec-bg.png';


const Specification = ({data}) => {

    let bannerImage = data ? data.images ? data.images[0].path + data.images[0].img_name :[]:[];

    return (
        <StyledSpecification id='specifications'>
            <Container fluid>
                <Row>
                    <Col md={6} className="specification-left p-0">
                        <div className="specification-left__img">
                            <img src={bannerImage && baseUrl + bannerImage} alt=""/>
                        </div>
                    </Col>

                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                        {({isVisible}) =>
                            <Col md={6} className={`${isVisible ? 'anim-active' : ''} fade-up specification-right`}>
                                <h4>Specifications</h4>

                                {/*<table className="table">*/}
                                {/*    <tbody>*/}
                                {/*    <tr>*/}
                                {/*        <td><span>:</span>Orientation</td>*/}
                                {/*        <td>South</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td><span>:</span>Front Road</td>*/}
                                {/*        <td>100 feet</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td><span>:</span>Apartment Size</td>*/}
                                {/*        <td>4315 sft</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td><span>:</span> Living Floors</td>*/}
                                {/*        <td>9</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td><span>:</span>Number of Parking</td>*/}
                                {/*        <td>27</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td><span>:</span>Basement</td>*/}
                                {/*        <td>2</td>*/}
                                {/*    </tr>*/}
                                {/*    </tbody>*/}
                                {/*</table>*/}

                                {data && htmlParser(data.data.description)}
                            </Col>
                        }
                    </VisibilitySensor>
                </Row>
            </Container>
        </StyledSpecification>
    )
};

const StyledSpecification = styled.section`
  .specification-left {
    &__img {
      padding-top: 102.49%;
      position: relative;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

    }
  }

  .specification-right {
    background-color: rgba(235, 232, 232, 0.10);
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 120px;
    padding-bottom: 95px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url(${specImg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      top: 0;
      left: 0;
    }

    h4 {
      color: #5d4e4d;
      font-size: 14px;
      padding-bottom: 15px;
      border-bottom: 1px solid #5D4E4D;
      min-width: 200px;
      margin: 0 0 63px 0;
      font-weight: bold;
    }

    table {
      tr {
        border: none;

        td {
          color: #1b1d1d;
          font-size: 16px;
          line-height: 21px;
          padding-top: 20px;
          padding-bottom: 20px;
          border: none;
          position: relative;
          
          span{
            position: absolute;
            right: 0;
          }

          &:nth-of-type(1) {
            padding-left: 0;
            font-weight: bold;
            line-height: 20px;
            color: #5d4e4d;
            width: 40%;
          }
          &:nth-of-type(2) {
            padding-left: 30px;
          }
        }

        &:nth-of-type(1) {
          td {
            border-top: 0;
          }
        }

        &:nth-last-of-type(1) {
          td {
            border-bottom: 1px solid rgba(206, 206, 206, 0.30);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {

    .specification-right {
      padding-top: 62px;
      padding-bottom: 50px;
      padding-left: 25px;
      padding-right: 25px;
      h4{
        margin-bottom: 45px;
      }
    }
  }
  @media (max-width: 767px) {
    .specification-right {
      padding-left: 15px;
      padding-right: 15px;

      table tr td {
        font-size: 14px;
        line-height: 21px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }

`;
export default Specification;