import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {DIRECTORS, TEAM} from "../../constants/team";

function* fetchTeam(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: TEAM.FETCH_TEAM.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: TEAM.FETCH_TEAM.FAILURE
        })
    }
}


function* fetchDirector(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_CHILD_BY_PAGE_ID,
            actions.params
        )

        yield put({
            type: DIRECTORS.FETCH_DIRECTORS.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: DIRECTORS.FETCH_DIRECTORS.FAILURE
        })
    }
}


export default function* serviceSaga() {
    yield takeLatest(TEAM.FETCH_TEAM.MAIN, fetchTeam);
    yield takeLatest(DIRECTORS.FETCH_DIRECTORS.MAIN, fetchDirector);
}
