import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import director from '../../../assets/images/dynamic/chairman.jpg'
import {baseUrl} from "../../../components/BaseUrl";
import htmlParser from "react-html-parser";


const Directors = ({data}) => {

    return (
        <StyledDirector className='directors'>

            {data && data.map(post=>(
                <div key={post.data.id} className="director-single pt-150 pb-150">
                    <Container>
                        <Row>
                            <Col sm={6} className='director-single__img-wrap'>
                                <div className="director-single__img-wrap__img">
                                    <img src={post.images && baseUrl + post.images[0].path + post.images[0].img_name} alt={director}/>
                                </div>
                            </Col>

                            <Col sm={6} className='director-single__text-wrap'>
                                <h4>{post.data.title}</h4>
                                <h5>{post.data.extra_deacription_one} </h5>
                                {htmlParser(post.data.description)}
                            </Col>

                        </Row>
                    </Container>
                </div>
            ))}


        </StyledDirector>
    );
};

const StyledDirector = styled.section`
  .director-single {
    &__img-wrap {
      &__img {
        position: relative;
        padding-top: calc(520 / 600 * 100%);
        min-height: 100%;

        img {
          object-fit: cover;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }
    }

    &__text-wrap {
      padding-left: 50px;

      h4 {
        font-size: 50px;
        line-height: 50px;
        font-weight: bold;
        margin: 0 0 14px 0;
        text-transform: capitalize;
        color: #ffffff;
      }

      h5 {
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 63px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: #FFF;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }
    }

    &:nth-of-type(odd) {
      background-color: #5D4E4D;
    }

    &:nth-of-type(even) {
      background-color: #F2E8DC;

      .row {
        flex-direction: row-reverse;
      }

      .director-single__text-wrap {
        padding-left: 15px;
        padding-right: 50px;

        h4, h5, p {
          color: #1a1818;
        }

      }
    }

    @media (max-width: 991px) {
      .director-single__text-wrap {
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
    }

    @media (max-width: 768px) {
      .director-single__img-wrap {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .director-single__text-wrap {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
      }
    }

    @media (max-width: 767px) {
      .director-single__text-wrap{
        h4{
          font-size: 30px;
          line-height: 35px;
          margin-bottom: 10px;
        }
        h5{
          margin-bottom: 30px;
        }
      } 
    }


  }
`;
export default Directors;
