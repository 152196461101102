import {PARTNERS} from "../../constants/partners";

let initialState = {
    partners: [],
    partnersLoading: false,
    partnersErr: '',
};

export default function partnersReducer(state = initialState, action) {
    switch(action.type) {
        case PARTNERS.FETCH_PARTNERS.MAIN:
            return {
                ...state,
                partners: [],
                partnersLoading: true,
                partnersErr: ''
            };

        case PARTNERS.FETCH_PARTNERS.SUCCESS:
            return {
                ...state,
                partners: action.result,
                partnersLoading: false,
                partnersErr: ''
            };

        case PARTNERS.FETCH_PARTNERS.FAILURE:
            return {
                ...state,
                partners: [],
                partnersLoading: false,
                partnersErr: 'Error from data fetching'
            };

        default:
            return state
    }
}
