import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";

// img
import overview from '../../../assets/images/overview.svg';
import specification from '../../../assets/images/specification.svg';
import gallery from '../../../assets/images/gallery.svg';
import location from '../../../assets/images/location.svg';
import $ from "jquery";
import {hover} from "../../../globalStyleVars";
import VisibilitySensor from "react-visibility-sensor";
import banner from '../../../assets/images/dynamic/banner.jpg';

import InnerBanner from "../../../components/InnerBanner";

const DetailBanner = ({post, img, SubTitle,Title}) => {

    useEffect(() => {

    })


    // let postTitle =

    return (
        <StyledBanner className="inner-banner">
            <InnerBanner
                text={Title}
                subTitle={SubTitle}
                image={img}

            />
        </StyledBanner>
    )
};

const StyledBanner = styled.section`
  @media(max-width: 767px){
    overflow: visible;
    //padding-top:calc(591 / 414 *100%);
    .container{
      overflow: visible;
    }

  }
`;


export default DetailBanner;
