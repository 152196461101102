import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {fetchLandowners} from "../../redux/actions/landowners";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Text from '../../components/TextSection';
import BuyersForm from './components/BuyersContactForm';
import htmlParser from "react-html-parser";
import BannerImg from "../../assets/images/dynamic/aboutBg.jpg";
import ContactText from './components/Contact-text';
import Address from './components/Address';
import Footer from "../../components/Footer";
import {fetchBuyers} from "../../redux/actions/buyers";
import {Loader} from "../../components/Loader";


class Buyers extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'buyer'
        };
        this.props.getBuyers(mainParam);

    }

    render() {

        let {buyers} = this.props;

        let pageData = buyers && buyers.page_data;
        let bannerImage = buyers && buyers.images && buyers.images.list[0];


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loader/>}
                <InnerBanner
                    text={pageData && pageData.title}
                    subTitle={'Contact'}
                    image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}
                />

                <ContactText data={pageData}/>
                {/*<Address top={'-200px'}/>*/}
                <BuyersForm/>

            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        buyers: state.buyersReducer.buyers,
        loading: state.buyersReducer.buyersLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBuyers: params => dispatch(fetchBuyers(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Buyers);
