import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {baseUrl} from "../../../components/BaseUrl";
import Slick from "react-slick";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";

const Integrity = ({post}) => {

    const [sliderTotalNumber, setSliderTotalNumber] = useState(4)
    const [sliderCurrentNumber, setSliderCurrentNumber] = useState(1)

    let Posts = post ? post.posts ? post.posts.list : [] : [];
    const sliderRef = useRef()
    const sliderItem = useRef()

    useEffect(() => {
        setTimeout(() => {
            let getLength = document.querySelectorAll('.slider-single').length;
            setSliderTotalNumber(getLength)
        }, 1000)
    }, [post]);


    function RightArrow(props) {
        const {className, style, onClick} = props;
        return (
            <li className="go-right" onClick={onClick}><BsChevronRight/></li>
        );
    }

    function LeftArrow(props) {
        const {className, style, onClick} = props;
        return (
            <li className="go-left" onClick={onClick}><BsChevronLeft/></li>
        );
    }

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 7500,
        autoplay: true,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>,
        afterChange: () => {
            let getActive = document.querySelector('.slick-dots .slick-active button').innerHTML;
            setSliderCurrentNumber(getActive)
        }
    };


    return (
        <StyledIntegrity className='our-values'>
            <Container>

                <div className="slide-number">
                    <p>{sliderCurrentNumber}/{sliderTotalNumber}</p>
                </div>

                {Posts.length &&
                    <Slick ref={sliderRef} {...settings}>
                        {Posts ? Posts.map(data => (
                            <div key={data.data.id} ref={sliderItem} className='slider-single'>
                                <Row>
                                    <Col sm='3' className='left-content'>
                                        <h3>{data.data.title}</h3>
                                        {/*<TextSlideChildrenAnimation>*/}
                                        <p>{data.data.description}</p>
                                        {/*</TextSlideChildrenAnimation>*/}
                                    </Col>

                                    <Col sm='9' className="right-content">
                                        <div className="right-content__inner">
                                            <img
                                                src={data.images ? baseUrl + data.images[0].path + data.images[0].img_name : ''}
                                                alt=""/>
                                            <h3>{data.data.extra_deacription_one}</h3>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        )) : ''}

                    </Slick>
                }

            </Container>
        </StyledIntegrity>
    )
};
const StyledIntegrity = styled.section`
  padding-top: 80px;
  background-color: #A65E58;
  padding-bottom: 200px;
  position: relative;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.50);

  .slide-number {
    position: absolute;
    top: 131px;

    p {
      font-size: 50px;
      font-weight: bold;
      color: #ffffff;
      margin: 0;
    }
  }

  .left-content {
    h3 {
      font-size: 16px;
      padding-bottom: 20px;
      min-width: 80%;
      display: inline-block;
      color: #ffffff;
      border-bottom: 1px solid #FFFFFF;
      margin: 0 0 35px 0;
    }

    p {
      font-size: 16px;
      color: #ffffff;
      line-height: 20px;
      margin: 75px 0 0 0;
    }
  }

  .right-content {
    padding-left: 130px;

    &__inner {
      padding-top: 66.62%;
      position: relative;

      h3 {
        font-size: 100px;
        line-height: 90px;
        font-weight: bold;
        color: #ffffff;
        position: absolute;
        bottom: 100px;
        left: -80px;
        margin: 0;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }
  }

  .go-right {
    height: 50px;
    width: 50px;
    background-color: #CFCDCC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    left: 70px;
    z-index: 2;

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      height: 0;
      width: 0;
      background-color: #5D4E4D;
      position: absolute;
      border-radius: 50%;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }

  .go-left {
    height: 50px;
    width: 50px;
    background-color: #CFCDCC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    z-index: 2;

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      height: 0;
      width: 0;
      background-color: #5D4E4D;
      position: absolute;
      border-radius: 50%;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }

  .slick-dots {
    opacity: 0 !important;
  }

  @media (max-width: 991px) {
    .row {
      flex-direction: row-reverse;
    }

    .right-content {
      padding-left: 15px;

      h3 {
        left: 15px;
        font-size: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 70px;
    padding-top: 0px;
    .container {
      padding: 0;
    }

    .slick-slider {
      padding-bottom: 70px;
    }

    .row {
      flex-direction: column-reverse;
    }

    .left-content {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 30px;
      margin-bottom: 30px;

      p {
        margin-top: 30px;
      }

      h3 {
        display: none;
      }
    }

    .right-content {
      flex: 0 0 100%;
      max-width: 100%;

      h3 {
        top: -23px;
        font-size: 40px;
        line-height: 40px;
      }
    }

    .slick-track {
      padding-top: 30px;
    }

    .go-left {
      left: 15px;
    }

    .go-right {
      left: 80px;
    }

    .slide-number {
      position: absolute;
      top: 30%;
      z-index: 4;
      left: 40px;
    }

  }

  @media (max-width: 767px) {
    .slide-number {
      position: absolute;
      top: 30%;
      z-index: 4;
      left: 15px;
    }


  }

`;

export default Integrity;










