import React, {Component, useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
// import {fetchAboutMain, fetchAboutHistory} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import NewsList from './components/newsList'
import {fetchNews} from "../../redux/actions/news";

import banner from '../../assets/images/dynamic/banner.jpg';
import {Loader} from "../../components/Loader";


const News = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!news) {
            let mainParam = {
                [ApiParams.page_type]: 'news'
            };
            props.getNews(mainParam);
        }


    }, [])


    const {news} = props;

    let newsList = news ? news.posts ? news.posts.list : [] : [];

    let bannerImage = news ? news.images ? news.images.list[0] : [] : [];
    let pageData = news ? news.page_data : [];


    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            {props.loading && <Loader/>}
            <InnerBanner
                text={pageData && pageData.short_desc}
                subTitle={pageData && pageData.sub_title}
                image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}
            />
            <NewsList post={newsList}/>
        </motion.div>
    )

}


function mapStateToProps(state) {
    return {
        news: state.newsReducer.news,
        loading: state.newsReducer.newsLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNews: params => dispatch(fetchNews(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
