import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {SERVICESTWO} from "../../constants/services/servicesTwo";

function* fetchServicesPageData(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    )

    yield put({
      type: SERVICESTWO.FETCH_SERVICESTWO_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: SERVICESTWO.FETCH_SERVICESTWO_PAGE.FAILURE
    })
  }
}


export default function* serviceSaga() {
  yield takeLatest(SERVICESTWO.FETCH_SERVICESTWO_PAGE.MAIN, fetchServicesPageData);
}
