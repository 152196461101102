import {DIRECTORS, TEAM} from "../../constants/team";

let initialState = {
    team: [],
    teamLoading: false,
    teamErr: '',
    directors: [],
    directorsLoading: false,
    directorsErr: '',
}

export default function teamReducer(state = initialState, action) {
    switch (action.type) {
        case TEAM.FETCH_TEAM.MAIN:
            return {
                ...state,
                team: [],
                teamLoading: true,
                teamErr: ''
            }

        case TEAM.FETCH_TEAM.SUCCESS:
            return {
                ...state,
                team: action.result,
                teamLoading: false,
                teamErr: ''
            }

        case TEAM.FETCH_TEAM.FAILURE:
            return {
                ...state,
                team: [],
                teamLoading: false,
                teamErr: 'Error from service page fetching'
            }


        case DIRECTORS.FETCH_DIRECTORS.MAIN:
            return {
                ...state,
                directors: [],
                directorsLoading: true,
                directorsErr: ''
            }

        case DIRECTORS.FETCH_DIRECTORS.SUCCESS:
            return {
                ...state,
                directors: action.result,
                directorsLoading: false,
                directorsErr: ''
            }

        case DIRECTORS.FETCH_DIRECTORS.FAILURE:
            return {
                ...state,
                directors: [],
                directorsLoading: false,
                directorsErr: 'Error from service page fetching'
            }


        default:
            return state
    }





}


