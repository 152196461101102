import { HOME } from '../../constants/home'

const initialState = {
  homeData: [],
  homeDataLoading: false,
  homeDataErr: '',
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME.FETCH_HOME.MAIN:
      return {
        ...state,
        homeData: [],
        homeDataLoading: true,
        homeDataErr: '',
      };
    case HOME.FETCH_HOME.SUCCESS:
      return {
        ...state,
        homeData: action.result,
        homeDataLoading: false,
        homeDataErr: ''
      };
    case HOME.FETCH_HOME.FAILURE:
      return {
        ...state,
        homeData: [],
        homeDataLoading: false,
        homeDataErr: 'Error in banner slider'
      };
    default:
      return state
  }
}
