import {NEWS} from "../../constants/news";

let initialState = {
  news: '',
  newsLoading: false,
  newsErr: '',
  newsDetails: [],
  newsDetailsLoading: false,
  newsDetailsErr: '',
};

export default function newsReducer(state = initialState, action) {
  switch(action.type) {
    case NEWS.FETCH_NEWS.MAIN:
      return {
        ...state,
        news: '',
        newsLoading: true,
        newsErr: ''
      };

    case NEWS.FETCH_NEWS.SUCCESS:
      return {
        ...state,
        news: action.result,
        newsLoading: false,
        newsErr: ''
      };

    case NEWS.FETCH_NEWS.FAILURE:
      return {
        ...state,
        news: '',
        newsLoading: false,
        newsErr: 'Error from news page fetching'
      };

    case NEWS.FETCH_NEWS_DETAILS.MAIN:
      return {
        ...state,
        newsDetails: [],
        newsDetailsLoading: true,
        newsDetailsErr: ''
      };

    case NEWS.FETCH_NEWS_DETAILS.SUCCESS:
      return {
        ...state,
        newsDetails: action.result,
        newsDetailsLoading: false,
        newsDetailsErr: ''
      };

    case NEWS.FETCH_NEWS_DETAILS.FAILURE:
      return {
        ...state,
        newsDetails: [],
        newsDetailsLoading: false,
        newsDetailsErr: 'Error from news page fetching'
      };

    default:
      return state
  }
}
