import {call, put, takeLatest} from 'redux-saga/effects'
import {FOOTER} from '../../constants/footer'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import Logger from "../../../helpers/logger";



function* setFooter(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    )

    yield put({
      type: FOOTER.FETCH_FOOTER.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: FOOTER.FETCH_FOOTER.FAILURE
    })
  }
}


export default function* footerSagas() {
  yield takeLatest(FOOTER.FETCH_FOOTER.MAIN, setFooter)
}


