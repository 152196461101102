import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import SubTitle from './SubTitle';
import {Container, Row, Col} from "react-bootstrap";
import Button from './Button';
import VisibilitySensor from "react-visibility-sensor";

const TextSection = ({bg, subTitleText, subTitleMargin, TitleText, DescText, Links,justify}) => {
    return (
        <>
            <StyledText bg={bg} justify={justify} className="pt-120 pb-120 text-section">
                <Container>
                    <Row>
                        <Col sm={{span: 10, offset: 1}} className='text-center'>
                            {subTitleText ?
                                <SubTitle margin={subTitleMargin || '0 0 20px 0'} text={subTitleText}/> : ''}


                            {TitleText ?
                                <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                    {({isVisible}) =>
                                        <h2 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{TitleText.split("").map(function(char, index){
                                            return <span aria-hidden="true" key={index}>{char}</span>;
                                        })}</h2>
                                    }
                                </VisibilitySensor>
                                : ''}

                            {DescText ?
                                <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                    {({isVisible}) =>
                                <div className={`${isVisible ? 'anim-active' : ''} fade-up desc`}>{DescText}</div>
                                    }
                                </VisibilitySensor>
                                : ''}
                            {Links ? <Button color={'#000000'} border={'1px solid #1A1818'} link={Links}
                                             text={'explore'}/> : ''}
                        </Col>
                    </Row>

                </Container>
            </StyledText>
        </>
    )
};

const StyledText = styled.section`
background-color: ${props => props.bg};
  h2{
    font-size: 50px;
    font-weight: bold;
    line-height: 60px;
    color: #cbcbcb;
    text-transform: uppercase;
    overflow: hidden;
    margin: 0;
  }
  .desc{
  margin-top: 10px;
    p{
    font-size: 16px;
    line-height: 24px;
    color: #1B1D1D;
    margin-bottom: 20px;
    text-align: ${props=>props.justify || ''};
    &:nth-last-of-type(1){
      margin-bottom: 0;
    }
  }
  }
  
  @media(max-width:767px){
    h2{
      font-size: 30px !important;
      font-weight: bold !important;
      line-height: 33px !important;
    }
  }

`;
export default TextSection;