import React, {Component, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Nav, Row, Tab} from 'react-bootstrap';
import Title from '../../../components/Title'
import Link from '../../../components/Link';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
// img
import {baseUrl} from "../../../components/BaseUrl";


const OurServices = ({data}) => {

    const containerRef = useRef()

    useEffect(() => {
        const getPadding = containerRef.current.offsetLeft + 15;
        document.querySelector('.tab-menus').style.right = getPadding + 'px';
        setTimeout(() => {
            let selectLi = document.querySelector('.tab-menus li:nth-child(1)');
            if (selectLi) {
                selectLi.classList.add('active');
            }
        }, 1000)

        let getTabList = document.querySelectorAll('.tab-menus li');
        for (let i of getTabList) {
            i.addEventListener('click', () => {
                document.querySelector('.tab-menus li.active').classList.remove('active')
                i.classList.add('active');
            })
        }
    }, [data])


    let servicePost = data ? data.posts ? data.posts.list : [] : [];


    return (
        <>
            <StyledServices className='services'>
                <Container ref={containerRef}>
                    <Title text={'Services'}/>
                </Container>

                <Tab.Container id="left-tabs-example" defaultActiveKey="0">

                    <Tab.Content>

                        {servicePost ? servicePost.map((data, index) => (
                            <Tab.Pane key={data.data.id} eventKey={index}>
                                <Container className='tab-container'>
                                    <Row>
                                        <Col sm={5} className='tab-image'>
                                            <div className='tab-img'>
                                                <img
                                                    src={data.images ? baseUrl + data.images[0].path + data.images[0].img_name : ''}
                                                    alt=""/>
                                            </div>
                                        </Col>

                                        <Col sm={5} className='tab-texts'>
                                            {/*<TextSlideChildrenAnimation>*/}
                                            <h3>{data.data.title} </h3>

                                            <h4>{data.data.extra_deacription_one}</h4>
                                            {/*</TextSlideChildrenAnimation>*/}
                                            {/*<TextSlideChildrenAnimation>*/}
                                            <p>{data.data.extra_deacription_two}</p>
                                            {/*</TextSlideChildrenAnimation>*/}
                                            <Link text={'learn more'}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                        )) : 'Loading...'}


                        <div className="tab-menus">
                            <ul>

                                {/*<Nav.Link eventKey="second"><img src={serviceImg} alt=""/></Nav.Link>*/}

                                {servicePost ? servicePost.map((data, index) => (
                                    <li key={index}>
                                        <Nav.Link eventKey={index}>
                                            <img
                                                src={data.images ? baseUrl + data.images[0].path + data.images[0].img_name : ''}
                                                alt=""/>
                                        </Nav.Link>
                                    </li>
                                )) : ''}


                            </ul>
                        </div>
                    </Tab.Content>

                </Tab.Container>


            </StyledServices>


        </>


    )

}


const StyledServices = styled.section`
  background-color: #F4F4F4;
  position: relative;
  //padding-bottom: 40px;

  .title {
    z-index: 5;
    top: -20px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    background-color: #ebe7e7;
  }

  .tab-container {
    .tab-img {
      padding-top: 122.81%;
      position: relative;
      z-index: 2;
      min-height: 100%;
      overflow: hidden;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        background-color: #A65E58;
        height: 20px;
        content: '';
        z-index: 2;
        transition: width 0.6s cubic-bezier(0.87, 0, 0.13, 1);
      }

      img {
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        position: absolute;
        transform: scale(1.01);
        transition: transform 1.4s ease;
        transform-origin: left top;
      }

      &:hover {
        &:after {
          width: 100%;
        }

        img {
          transform: scale(1.04);

        }
      }
    }

    .tab-texts {
      padding-left: 130px;
      padding-top: 120px;
      padding-bottom: 120px;
      position: relative;
      z-index: 2;

      h3 {
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        color: #5D4E4D;
        margin: 0 0 14px 0;
        text-transform: uppercase;

      }

      h4 {
        color: #cfcdcc;
        font-size: 20px;
        line-height: 22px;
        margin: 0 0 32px 0;
        font-weight: bold;
      }

      p {
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        color: #5d4e4d;
      }

      .link {
        margin-top: 50px;
      }
    }
  }

  .tab-menus {
    position: absolute;
    right: 50px;
    top: 120px;
    z-index: 2;

    ul {
      li {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        margin-bottom: 35px;
        position: relative;
        box-sizing: content-box;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        border: 3px solid #CFCDCC;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        a {
          display: block;
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 0;
        }

        &.active {
          border-color: #FF9900;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &:after {
      height: 100%;
    }

    overflow: visible !important;
    .container, .container-sm {
      overflow: visible !important;
    }

    .tab-container {
      .tab-image {
        display: none;
      }
    }

    .tab-texts {
      flex: 0 0 100%;
      max-width: 100%;
      padding-top: 90px !important;
      padding-bottom: 90px !important;
    }

    .tab-menus {
      left: 30px;
      top: 90px;
    }
  }

  @media (max-width: 767px) {
    .tab-menus {
      left: 15px;
    }

  }


`;

export default OurServices;