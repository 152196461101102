import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Detail from './components/detailDescription';
import {fetchNews, fetchNewsDetail} from "../../redux/actions/news";
import {withRouter} from 'react-router-dom';

import banner from '../../assets/images/dynamic/aboutBg.jpg'
import {Loader} from "../../components/Loader";

class NewsDetails extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        if (!this.props.news) {
            let mainParam = {
                [ApiParams.page_type]: 'news'
            };
            this.props.getNews(mainParam);
        }


    }

    render() {
        const {news} = this.props;
        let {slug} = this.props.match.params;

        // console.log('loading-------', this.props.loading)

        let findPost = news ? news.posts ? news.posts.list ? news.posts.list.find(f => f.data.slug === slug) : '' : '' : '';
        let bannerImage = findPost ? findPost.images ? findPost.images.find(i => i.is_banner === 'on') : '' : '';


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loader/>}

                <InnerBanner
                    text={''}
                    image={bannerImage ? baseUrl + bannerImage.path + bannerImage.img_name : banner}
                />
                <Detail post={findPost}/>
            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        news: state.newsReducer.news,
        loading: state.newsReducer.newsLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNews: params => dispatch(fetchNews(params)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsDetails));