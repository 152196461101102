import React, {Component} from 'react';
import styled from 'styled-components';
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";
import Title from '../../../components/Title';
import Logo from '../../../assets/images/logo.svg';
import Logo2 from '../../../assets/images/logo2.svg';
import {baseUrl} from "../../../components/BaseUrl";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";


class AboutUs extends Component {

    componentDidMount() {

    }

    render() {
        let {data} = this.props;
        let pageData = data ? data.page_data : '';
        let BannerImage = data ? data.images ? data.images.list[0] : [] : [];
        let aboutPosts = data ? data.posts ? data.posts.list : [] : [];

        return (
            <>
                <StyledAboutBanner>
                    {/*<MaskingAnimation>*/}
                    <img src={BannerImage ? baseUrl + BannerImage.path + BannerImage.img_name : ''} alt=""/>
                    {/*</MaskingAnimation>*/}
                    <Container>
                        <p>About Us</p>
                        {/*<Title text='Nassa Holdings Limited' />*/}

                    </Container>
                </StyledAboutBanner>

                <StyledAboutBottom>
                    <Container>
                        {/*<TextSlideChildrenAnimation>*/}
                            <Title text={pageData ? pageData.sub_title : ''}/>
                        {/*</TextSlideChildrenAnimation>*/}
                        <Row>
                            <Col sm={3}>
                                <img src={Logo} alt=""/>
                                <img src={Logo2} alt=""/>
                            </Col>

                            <Col sm={{span: 7, offset: 1}}>

                                {/*<TextSlideChildrenAnimation>*/}
                                    {ReactHtmlParser(pageData.description)}
                                {/*</TextSlideChildrenAnimation>*/}
                                <div className="about-count">
                                    {aboutPosts ? aboutPosts.map(data => (
                                        <div key={data.data.id} className="about-count__single">
                                            <h4>
                                                <CountUp start={0} end={parseInt(data.data.extra_deacription_one)}>
                                                    {({countUpRef, start}) => (
                                                        <VisibilitySensor onChange={start}
                                                                          partialVisibility={{top: 0, bottom: 20}}
                                                                          delayedCall>
                                                            <span ref={countUpRef}>+</span>
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                                +</h4>
                                            <p>{data.data.title}</p>
                                        </div>
                                    )) : 'loading...'}

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </StyledAboutBottom>
            </>


        )
    }
}

const StyledAboutBanner = styled.section`
  //height: 550px;
  padding-top: calc(660 / 1366 * 100%);
  position: relative;

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .container {
    position: relative;
    height: 100%;
    overflow-y: visible;
  }

  p {
    position: absolute;
    bottom: 80px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-transform: capitalize;
    padding-bottom: 20px;
    border-bottom: 1px solid #F7F3F0;
    color: #ffffff;
    min-width: 200px;
  }

  @media (max-width: 767px) {
    p {
      bottom: 30px !important;
    }

    height: 400px;
  }

`;

const StyledAboutBottom = styled.div`
  background-color: #5d4e4d;
  padding-top: 200px;
  padding-bottom: 200px;
  position: relative;

  img {

    &:nth-of-type(1) {
      margin-bottom: 35px;
    }

    &:nth-of-type(2) {
      height: 40px;
    }

  }

  p {
    font-size: 20px;
    line-height: 22px;
    margin: 0;
    color: #ffffff;
    font-weight: bold;

    &:nth-of-type(1) {
      margin-bottom: 40px;
    }

    &:nth-last-of-type(1) {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .about-count {
    display: flex;
    margin-top: 85px;

    &__single {
      margin-right: 70px;

      h4 {
        font-size: 30px;
        color: #ffffff;
        margin-bottom: 20px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        color: #CFCDCC;
        line-height: 16px;
        margin: 0;
      }

      &:nth-last-of-type(1) {
        margin-right: 0;
      }
    }


  }

  .title {
    top: -26px;
  }

  @media (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
    .title {
      top: -20px;
      font-size: 35px;
    }

    .col-sm-3 {
      flex: 0 0 100%;
      max-width: 100%;
      //margin-bottom: 50px;
      img {
        display: block;
      }
    }

    .col-sm-7 {
      margin-left: 0;
      margin-top: 60px;
      flex: 0 0 100%;
      max-width: 100%;

      p {
        font-size: 18px;
      }
    }

    .about-count {
      flex-wrap: wrap;

      &__single {
        width: 50%;
        margin: 0;

        &:nth-last-of-type(1) {
          margin-top: 40px;
        }
      }
    }

  }
`;

export default AboutUs




