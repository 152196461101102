import React, {useEffect, useLayoutEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import JobDesc from './components/JobDescription';
import Contact from './components/ContactForm';

// img

const Apply = () => {
    useLayoutEffect(() => {
        // window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <InnerBanner image={'image'} subTitle={'career'} text={'Executive - Sales & Marketing'}/>
            <JobDesc/>
            <Contact/>
        </div>
    )
};

export default Apply;