import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import htmlParser from "react-html-parser";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";

const WhoWeAre = ({mission, count}) => {

    // mission vision
    let pageData = mission ? mission.page_data : '';
    let posts = mission ? mission.posts ? mission.posts.list : [] : [];

    // countdown
    let counts = count ? count.posts ? count.posts.list : [] : [];

    useEffect(() => {

    }, []);


    let sliderParams = {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        breakpoints: {
            768: {
                autoplay: false,
                spaceBetween: 0,
                slidesPerView: 2,
            }

        }
    };


    return (
        <StyledWhoWeAre id='who-we-are'>
            <Container>
                <Row className='about-text'>
                    <Col md={9}>
                        {/*<TextSlideChildrenAnimation>*/}
                            <h2>{pageData ? pageData.sub_title : ""}</h2>
                        {/*</TextSlideChildrenAnimation>*/}

                        {/*<TextSlideChildrenAnimation>*/}
                            <p>{htmlParser(pageData ? pageData.short_desc : "")}</p>
                        {/*</TextSlideChildrenAnimation>*/}

                    </Col>
                </Row>

                <Row className='missing-vision'>
                    <Col sm={{span: 8, offset: 4}}>
                        <Row>

                            {posts ? posts.map(data => (
                                <Col key={data.data.id} sm={6}>
                                    <h4>{data.data.title}</h4>
                                    {/*<TextSlideChildrenAnimation>*/}
                                        <p>{data.data.description}</p>
                                    {/*</TextSlideChildrenAnimation>*/}

                                </Col>
                            )) : ''}


                            {/* <Col sm={6}>
                                <h4>Our Mission</h4>
                                <p>Our projects speak for themselves! We take pride in our ability to provide
                                    managerial,
                                    engineering and skilled labours on-demand.</p>
                            </Col> */}
                        </Row>

                    </Col>
                </Row>

                <Row className='count-down'>
                    <Swiper  {...sliderParams}>

                        {counts ? counts.map(data => (
                            <Col key={data.data.id} sm={4}>
                                <div className="count-down__inner">
                                    <h4>{data.data.title}</h4>
                                    <p>{data.data.description}</p>
                                </div>

                            </Col>
                        )) : ''}


                    </Swiper>
                </Row>

            </Container>
        </StyledWhoWeAre>
    )
};
const StyledWhoWeAre = styled.section`
  background-color: #EBE7E7;
  padding-top: 122px;
  padding-bottom: 50px;
  position: relative;

  .swiper-container {
    width: 100%;
  }

  &:after {
    position: absolute;
    height: 160px;
    background-color: #A65E58;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
  }

  .about-text {
    h2 {
      color: #5d4e4d;
      margin: 0 0 40px 0;
      line-height: 34px;
      font-weight: bold;
      //font-size: 45px;
    }

    p {
      font-size: 20px;
      font-weight: bold;
      line-height: 22px;
      color: #5d4e4d;
      margin: 0;
    }
  }

  .missing-vision {
    margin-top: 100px;

    h4 {
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 50px 0;
      color: #5D4E4D;
      text-transform: capitalize;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      color: #5D4E4D;
    }
  }

  .count-down {
    margin-top: 100px;
    position: relative;
    z-index: 3;

    &__inner {
      padding-top: 68.65%;
      position: relative;
      background-color: #FFFFFF;

      h4 {
        margin: 0;
        position: absolute;
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        color: #5d4e4d;
        top: 42px;
        right: 30px;
        left: 40px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: #5d4e4d;
        margin: 0;
        position: absolute;
        bottom: 46px;
        left: 40px;
        right: 80px;
      }
    }

    .col-sm-4:nth-of-type(1) {
      .count-down__inner {
        background-color: #5D4E4D;

        h4 {
          color: #ffffff;
        }

        p {
          color: #ffffff
        }

      }
    }
  }

  @media (max-width: 1199px) {
    .count-down__inner {
      h4 {
        top: 22px;
        left: 22px;
        font-size: 22px;
        line-height: 26px;
      }

      p {
        left: 22px;
        bottom: 22px;
        right: 22px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 77px;
    .about-text {
      h4 {
        font-size: 25px;
        margin-bottom: 40px;
      }
    }

    .missing-vision {
      margin-top: 40px;

      h4 {
        margin-bottom: 20px;
      }

      .col-sm-6 {
        margin-bottom: 42px;
      }
    }

    .count-down {
      margin-top: 30px;
    }
  }

  @media (max-width: 600px) {
    .swiper-container {
      padding-right: 80px;
    }

    .count-down__inner {
      padding-top: 72.16%;

      h4 {
        font-size: 30px;
        top: 52px;
      }

      p {
        bottom: 30px;
      }
    }
  }
`;
export default WhoWeAre;
