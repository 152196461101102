import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import arrowImg from '../assets/images/btn-arrow-white.svg'
import {Link} from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

const Links = ({text, link, img,hoverColor, color, margin,background, hoverBg,letterSpace, fontSize, textTransform, fontWeight}) => {

    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledLink className={`${isVisible ? 'anim-active' : ''} fade-up link`}
                            margin={margin} hoverColor={hoverColor} letterSpace={letterSpace} fontWeight={fontWeight}
                            textTransform={textTransform} hoverBg={hoverBg} background={background} fontSize={fontSize} color={color}>

                    <Link to={link || '/'}><span>{text}</span></Link>
                </StyledLink>
            }
        </VisibilitySensor>
    )
};

const StyledLink = styled.div`
    margin:${props => props.margin || '0'}px;
    a{
        margin: 0;
        display: inline-flex;
        color: ${props => props.color || '#FFF'};
        font-size:${props => props.fontSize || 13}px;
        font-weight:${props => props.fontWeight || 400};
        letter-spacing:${props => props.letterSpace || '0'}px;
        text-transform:${props => props.textTransform || 'capitalize'};
        line-height: 24px;
        transition:all .3s ease;
        align-items: center;
        width: 130px;
        height: 50px;
        position: relative;
        border-radius:25px;
        background-color:${props=> props.background ||  '#5D4E4D'};
        overflow: hidden;
        &:after{
         height: 0;
         width: 0;
         background-color:${props=> props.hoverBg || '#A65E58'} ;
         left: 0;
         right: 0;  
         top: 0; 
         //color:${props=> props.hoverColor || '#FFF'} ;
         content: '';
         position: absolute;
         bottom: 0;
         transition:all .5s ease;
         margin: auto;
          border-radius:25px;
        }
        span {
            height: 100%;
            width: 100%;
            z-index: 2;
            position: relative;
            transition: all .3s ease !important;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color:${props=> props.color || `#FFF`};
        }
      &:hover{
          span{
            color:${props=> props.hoverColor || '#FFF'} ;
          }
        
           &:after{
             height: 100%;
             width: 100%;
           }
       }  
  }
`;

export default Links;