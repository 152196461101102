import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from 'react-bootstrap';
import htmlParser from "react-html-parser";
import {Link} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ButtonSubmit from '../../../components/ButtonSubmit';
import {BsChevronDown, BsX} from "react-icons/bs";
import fileImage from '../../../assets/images/file.svg'
import {emailValidation, emptyValidation, emptyNumber} from '../../../config/validator';
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux'
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import Message from '../../../components/PopupMessage';
import {Loading} from '../../../components/Loading';

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Jobs = (props) => {

    let {pages, slug} = props
    let [pageSlug, setPageSlug] = useState(slug);
    let [postId, setPostId] = useState(null)

    useEffect(() => {

        if (pages.length > 0) {
            let applyBtn = document.querySelectorAll('.accordion-body button');
            for (let b of applyBtn) {
                b.addEventListener('click', () => {
                    document.querySelector('.career-detail').classList.add('slide-down');
                    // document.querySelector('.career-detail').classList.add('.slide-down');
                    window.scrollTo(0, 0)

                    document.querySelector('.job-lists').style.cssText = `
                    filter:blur(3px)
                   `
                    document.querySelector('.contact-text').style.cssText = `
                    filter:blur(3px)
                   `
                })


            }
        }
        document.querySelector('.close-detail').addEventListener('click', () => {
            document.querySelector('.career-detail').classList.remove('slide-down');
            document.querySelector('.job-lists').style.cssText = `
                    filter:blur(0px)
                   `
            document.querySelector('.contact-text').style.cssText = `
                    filter:blur(0px)
                   `
        })
    }, [pages])


    useEffect(() => {
        if (typeof (slug) === 'undefined') {
            setTimeout(() => {
                document.querySelector('.job-lists__sidebar li').classList.add('active')
            }, 1000)
        }

    }, [slug])




    let {job} = props;
    let jobPost = job ? job.posts ? job.posts.list[0] : [] : [];

    let jobTitle = jobPost ? jobPost.data ? jobPost.data.title : '' : '';

    let handlePostId = (e)=>{
        setPostId(e)
    }

    let getFirstCat = pages ? pages[0] : '';
    let filterPostBySlug = pages ? pages.find(f => f.page_data ? f.page_data.slug === slug : '') : [];
    let GetFinalPost = filterPostBySlug ? filterPostBySlug : getFirstCat;

    let getJobTitle = GetFinalPost && GetFinalPost.posts && GetFinalPost.posts.list.find(f=> f.data.id === postId);




    //--------- form submit
    useEffect(() => {
        window.scrollTo(0, 0);
        let param = {
            [ApiParams.form_slug]: 'career-form'
        };
        props.getFormData(param)
    }, []);

    let {formFields, submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        position:jobTitle,
        name: '',
        contact_no: '',
        email: '',
        cv: '',
        message: '',
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };

    let handleState = e => {
        if(e.target.name !== 'cv') {
            setState({ ...state, [e.target.name]: e.target.value})
        } else {
            setState({...state, [e.target.name]: e.target.files[0]})
        }
    }




    let handleSelect = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    let handleInit = () => {
        setState({
            position:'',
            name: '',
            contact_no: '',
            email: '',
            cv: '',
            message: '',
        })
    }



    let handleSubmit = (e) => {
        e.preventDefault()

        e.preventDefault()
        let formField = {
            form_id: 'career-form'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }


        formField["name"] = state.name;
        formField["contact_no"] = state.contact_no;
        formField["email"] = state.email;
        formField["message"] = state.message;
        formField["position"] = getJobTitle && getJobTitle.data && getJobTitle.data.title;
        formField["cv"] = state.cv;


        let bodyFormData = new FormData()

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.contact_no);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };


    return (
        <>
            <StyledJob className="job-lists">
                <Container>
                    <Row>
                        <Col sm={4} className='job-lists__sidebar'>
                            <ul>
                                {pages ? pages.map(data => (
                                    <li key={data.page_data.id}
                                        className={slug ? slug === data.page_data.slug ? 'active' : '' : ''}><Link
                                        to={`/career/${data.page_data ? data.page_data.slug : ''}`}>{data.page_data ? data.page_data.title : ''}</Link>
                                    </li>
                                )) : ''}

                            </ul>
                        </Col>

                        <Col sm={8} className='job-lists__content'>
                            <Accordion defaultActiveKey="0">
                                {GetFinalPost ? GetFinalPost.posts ? GetFinalPost.posts.list.map((data, index) => (
                                    <Accordion.Item key={index} eventKey={`${index}`}>
                                        <Accordion.Header>
                                            {data.data.title}
                                            <span><BsChevronDown/></span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {htmlParser(data.data.description)}

                                            <div onClick={()=> handlePostId(data.data.id)}>
                                                <ButtonSubmit margin={'0'} text={'Apply'}/>
                                            </div>


                                        </Accordion.Body>
                                    </Accordion.Item>
                                )) : '' : ''}


                            </Accordion>
                        </Col>

                    </Row>


                </Container>
            </StyledJob>


            <StyledCareerDetail>

                {!popup ? loading ? <Loading/> : '' : null}
                <Message/>

                <div className="career-detail">
                    <div className="close-detail">
                        <BsX/>
                    </div>
                    <Container>
                        <Row>
                            <Col sm={10}>
                                <h4>{pageSlug || 'career'}</h4>
                                <h2>{getJobTitle && getJobTitle.data.title}</h2>

                                <Form onSubmit={handleSubmit}>


                                    {/*<input name="position"*/}
                                    {/*       value={getJobTitle && getJobTitle.data.title} hidden={true}*/}
                                    {/*       onChange={handleChange} type="text"/>*/}


                                    <Form.Group controlId="formBasicEmail" className='form-group'>
                                        <Form.Label>Name *</Form.Label>
                                        <Form.Control  name="name"
                                                       className={empty ? 'has-error' : ''} onBlur={handleEmptyName} onChange={handleChange}
                                                       value={state.name} type="text" placeholder="Enter your full name here"/>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail" className='form-group'>
                                        <Form.Label>Contact Number *</Form.Label>
                                        <Form.Control  name="contact_no"
                                                       className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                                       value={state.contact_no} onChange={handleChange} type="number" placeholder="Enter your contact number here *"/>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail" className='form-group'>
                                        <Form.Label>Email *</Form.Label>
                                        <Form.Control name="email"
                                                      className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                                      value={state.email} onChange={handleChange} type="email" placeholder="Enter your email address here"/>
                                    </Form.Group>

                                    <div className="inline-button">
                                        <div className="file-uploader">
                                            <Form.Control name="cv"
                                                          onChange={handleState} type="file"/>
                                        </div>

                                        <div onClick={handleSubmit}>
                                            <ButtonSubmit width={'164'} margin={'0 0 0 30px'} text={'Send'}/>
                                        </div>

                                    </div>

                                </Form>

                            </Col>
                        </Row>
                    </Container>

                </div>
            </StyledCareerDetail>
        </>

    )
}


const StyledJob = styled.section`

  padding-top: 87px;
  padding-bottom: 87px;
  background-color: #EBE7E7;
  position: relative;


  .accordion-item {
    margin-bottom: 30px;
  }


  .job-lists__sidebar {
    ul {
      li {
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        color: #5D4E4D;
        transition: color .3s ease;
        width: fit-content;
        margin-bottom: 11px;
        cursor: pointer;

        &.active {
          color: #a65e58;
          position: relative;
          pointer-events: none;

          &:after {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            background-color: #a65e58;
            right: -20px;
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 50%;

          }

          a {
            color: #a65e58;
            position: relative;

          }
        }

        &:hover {
          color: #a65e58;
        }
      }
    }
  }

  .job-lists__content {

    .dc-btn {
      margin-top: 60px;
      margin-bottom: 80px;
    }

    .accordion-header {
      position: relative;
      margin: 0;

      button {
        border: none;
        background-color: transparent;
        padding-left: 0;
        color: #a65e58;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        text-transform: capitalize;
        padding-bottom: 23px;
        border-bottom: 1px solid #a65e58;
        width: 100%;
        text-align: left;
        //margin-bottom: 50px;
        transition: all .4s ease;

        &.collapsed {
          color: #1A1818;
          border-color: #1A1818;

          span {
            background-color: #5D4E4D;

            svg {
              transform: rotate(0deg);
            }

            &:after {
              background-color: #a65e58 !important;
            }
          }
        }
      }

      span {
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: #ffffff;
        background-color: #a65e58;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 16px;

        &:after {
          height: 0;
          width: 0;
          background-color: #a65e58;
          border-radius: 50%;
          opacity: 0;
          transition: all .4s ease;
          content: '';
          position: absolute;
        }

        svg {
          font-size: 13px;
          z-index: 2;
          transform: rotate(180deg);
        }

      }

      &:hover {
        span {
          &:after {
            height: 100%;
            width: 100%;
            opacity: 1;
          }
        }

        button {
          color: #a65e58;;
          border-color: #a65e58;
        }
      }
    }

    .job-description-group {
      margin-top: 60px;
      margin-bottom: 10px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        color: #5D4E4D;
        margin: 0 0 31px 0;
        text-transform: capitalize;
      }

      ul {
        padding-left: 20px;

        li {
          color: #5D4E4D;
          margin-bottom: 15px;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #a65e58;
            left: -20px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }

  form {
    padding-right: 150px;
  }

  @media (max-width: 767px) {
    .job-lists__sidebar {
      margin-bottom: 30px;
    }

    padding-bottom: 30px;
  }


`;

const StyledCareerDetail = styled.section`
  .career-detail {
    padding-top: 120px;
    position: absolute;
    min-height: 80vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: #F7F3F0;
    z-index: 99999;
    padding-bottom: 50px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    transition: all .6s ease;

    .close-detail {
      position: absolute;
      right: 50px;
      top: 50px;
      height: 50px;
      width: 50px;
      background-color: #5D4E4D;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        color: #ffffff;
        font-size: 30px;
        z-index: 2;

      }

      &:after {
        content: '';
        position: absolute;
        height: 0%;
        width: 0%;
        border-radius: 50%;
        background-color: #A65E58;
        opacity: 0;
        transition: all .4s ease;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      &:hover:after {
        height: 100%;
        width: 100%;
        opacity: 1;
      }
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #5D4E4D;
      margin-bottom: 24px;
      color: rgba(93, 78, 77, 0.80);
      width: 250px;
      text-transform: capitalize;
    }

    h2 {
      font-size: 50px;
      font-weight: bold;
      color: #a65e58;
      margin: 0;
      margin-bottom: 85px;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      color: #5D4E4D;
      margin: 0;
      width: 70%;
    }

    form {
      width: 75%;
    }

    .form-group {
      margin-bottom: 50px;
    }

    .form-control {
      height: 50px;
      line-height: 53px;
      color: #1a1818;
      font-size: 14px;
      font-weight: 300;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #5D4E4D;
      background-color: transparent;
      padding-left: 60px;

      &::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #CFCDCC;
      }
    }

    &.slide-down {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }

  label {
    color: #5d4e4d;
    font-size: 20px;
    font-weight: bold;
  }

  .file-uploader {
    margin-bottom: 50px;
    width: 164px;

    input {
      position: relative;

      &:after {
        content: 'Attach CV';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #A65E58;
        border-radius: 26px;
        background-image: url(${fileImage});
        font-size: 13px;
        color: #ffffff;
        background-repeat: no-repeat;
        background-position: 25px;
        background-size: 20px;
        padding-left: 70px;
        z-index: 2;
        cursor: pointer;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        background-color: #F7F3F0;
        width: 100%;
        height: 100%;
      }
    }

    .form-control {
      border-bottom: 0;
      padding-left: 0;
    }
  }

  .inline-button {
    display: flex;

    .db-btn {
    }

  }

  @media (max-width: 767px) {


    .job-lists__sidebar {
      margin-bottom: 20px;
    }

    .career-detail {
      h2 {
        font-size: 24px;
      }

      h4 {
        width: 100%;
      }

      form {
        width: 100%;
      }

      .close-detail {
        right: 20px;
      }
    }

    .inline-button {
      flex-wrap: wrap;

      .dc-btn {
        width: 100%;
        margin-left: 0;
      }
    }
  };
  
`;




function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);

