export const NEWS = {
  FETCH_NEWS: {
    MAIN: 'FETCH_NEWS',
    SUCCESS: 'FETCH_NEWS_SUCCESS',
    FAILURE: 'FETCH_NEWS_FAILURE'
  },
  FETCH_NEWS_DETAILS: {
    MAIN: 'FETCH_NEWS_DETAILS',
    SUCCESS: 'FETCH_NEWS_DETAILS_SUCCESS',
    FAILURE: 'FETCH_NEWS_DETAILS_FAILURE'
  }

};
