import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {fetchTeam} from "../../../redux/actions/team";
import {connect} from "react-redux";
import {baseUrl} from "../../../components/BaseUrl";
import {ApiParams} from "../../../constants/apiParams";


// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gradient from '../../../assets/images/progress-overlay.png';
import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";
import {hover} from "../../../globalStyleVars";
import {Loader} from "../../../components/Loader";


const Team = ({post}) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let liSelector = document.querySelectorAll('.team-filter li');

        for (let i of liSelector) {
            i.addEventListener('click', () => {
                document.querySelector('.team-filter li.active').classList.remove('active');
                i.classList.add('active');
                setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                }, 400)
            })
        }

    }, [])

    const [showTeam, setShowTeam] = useState('')

    let corporatePost = post ? post.find(f => f.data.slug === 'corporate') : []
    let managementsPost = post ? post.find(f => f.data.slug === 'managements') : []

    let corporateImg = corporatePost ? corporatePost.images : []
    let managementsImg = managementsPost ? managementsPost.images : []

    // let allPost = [...corporateImg, ...managementsImg]
    let allPost = managementsImg

    const handleFilterClick = (data) => {
        setShowTeam(data);
    }

    let filterTeam = post.length ? showTeam !== '' ? post.find(f => f.data.slug === showTeam).images : allPost : [];

    return (

        <StyledTeam>
            {loading && <Loader/>}
            <Container>
                <Row className="team-filter">
                    <Col>
                        <ul>
                            <li className='active' onClick={() => handleFilterClick('')}><span>All</span></li>
                            {/*<li onClick={()=>handleFilterClick('corporate')}><span>Corporate</span></li>*/}
                            <li onClick={() => handleFilterClick('managements')}><span>Managements</span></li>
                        </ul>
                    </Col>
                </Row>

                <Row className='team-wrap'>

                    {filterTeam ? filterTeam.map((data, index) => (
                        <Col key={index} sm={3}>
                            <div className="team-wrap__single">
                                <div className="team-wrap__single__img">
                                    <img src={baseUrl + data.path + data.img_name} alt=""/>
                                </div>
                                <div className="team-wrap__single__content">
                                    <h4>{data.short_title}</h4>
                                    <p>{data.short_desc}</p>
                                </div>
                            </div>
                        </Col>
                    )) : 'loading...'}


                </Row>

            </Container>
        </StyledTeam>
    )
}


const StyledTeam = styled.section`
  padding-top: 150px;
  padding-bottom: 100px;
  background-color: #EBE7E7;

  .team-filter {

    ul {
      display: flex;
      border-bottom: 1px solid #5D4E4D;

      li {
        display: inline-flex;
        height: 77px;
        align-items: center;
        justify-content: center;
        padding: 0 55px;
        border-right: 1px solid #5D4E4D;
        cursor: pointer;
        transition: all .3s ease;
        position: relative;

        span {
          z-index: 2;
        }

        &:after {
          content: '';
          transition: height .4s cubic-bezier(.25, .74, .22, .99);
          height: 0%;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: ${hover};
          position: absolute;
        }

        &:nth-last-child(1) {
          border-right: 0;
        }

        &:hover {
          color: #FFF;

          &:after {
            height: 100%;
          }
        }

        &.active {
          color: #FFF;

          &:after {
            height: 100%;
          }
        }
      }
    }
  }

  .team-wrap {
    margin-top: 80px;

    &__single {
      margin-bottom: 60px;

      &__img {
        padding-top: 121.85%;
        position: relative;

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }

      &__content {
        h4 {
          font-size: 20px;
          font-weight: bold;
          line-height: 22px;
          margin: 20px 0 0 0;
          color: #5D4E4D;
          text-transform: capitalize;
        }

        p {
          margin: 10px 0 0 0;
          color: #5D4E4D;
          font-size: 16px;
          line-height: 22px;
        }

      }
    }
  }

  @media (max-width: 768px) {
    .team-filter ul li {
      height: 50px;
    }
  }

  @media (max-width: 767px) {
    .team-wrap__single {
      margin-bottom: 30px;
    }

    .team-filter ul li {
      height: 40px;
      padding: 0 25px;
      font-size: 12px;
    }
  }

  @media (max-width: 575px) {
    padding-top: 70px;
    padding-bottom: 30px;
    .team-wrap {
      margin-top: 30px;

      .col-sm-3 {
        flex: 0 0 50%;
        max-width: 50%;

        &:nth-child(odd) {
          padding-right: 8px;
        }

        &:nth-child(even) {
          padding-left: 8px;
        }
      }

      .team-wrap__single__content {
        h4 {
          margin-top: 10px;
        }

        p {
          margin-top: 8px;
        }
      }
    }
  }



`;

export default Team;

