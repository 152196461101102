import { combineReducers } from 'redux'
import homeReducer from './home'
import divisionReducer from "./division";
import footerReducer from "./footer";
import aboutReducer from "./about";
import serviceReducer from "./services";
import serviceTwoReducer from "./services/servicesTwo";
import careerReducer from "./career";
import mediaReducer from "./news";
import formReducer from './form'
import contactReducer from './contact'
import menuReducer from "./menu";
import projectsReducer from "./projects";
import landownersReducer from "./landowners";
import buyersReducer from "./buyers";
import newsReducer from "./news";
import partnersReducer from "./partners";
import teamReducer from "./team";


export default combineReducers({
  homeReducer,
  divisionReducer,
  footerReducer,
  aboutReducer,
  serviceReducer,
  careerReducer,
  mediaReducer,
  formReducer,
  contactReducer,
  menuReducer,
  projectsReducer,
  serviceTwoReducer,
  landownersReducer,
  buyersReducer,
  newsReducer,
  partnersReducer,
  teamReducer
})
