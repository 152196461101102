import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";
import Title from '../../../components/Title'
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import plusWhite from '../../../assets/images/plus-white.svg';

import {LightgalleryItem, LightgalleryProvider,} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

const DetailGallery = ({data}) => {

    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img src={image} alt='' style={{width: "100%"}}/>
        </LightgalleryItem>
    );

    let gallery = data ? data.images : []

    const containerSetRef = useRef(null);
    const containerRef = useRef(null);

    let sliderParams = {
        slidesPerView: 1,
        spaceBetween: 25,
        // centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        autoplay: {
            delay: 4500,
            disableOnInteraction: false,
            autoplay: false,

        },
        speed: 1000,
        breakpoints: {
            // when window width is >= 320px
            768: {
                autoplay: false,
                spaceBetween: 30,
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },

        }
    };


    useEffect(() => {
        let getOffset = containerRef.current.offsetLeft + 15;
        if (window.screen.width > 768) {
            containerSetRef.current.style.paddingLeft = getOffset + 'px';
        }

    }, [containerRef])

    useEffect(() => {
        let getClick = document.querySelectorAll('.gallery-slider__single .circle');

        for (let i of getClick) {
            i.addEventListener('click', () => {
                i.nextElementSibling.firstElementChild.firstElementChild.click()
            })
        }

        if (window.screen.width < 769) {
            setTimeout(() => {
                if (document.querySelector('.swiper-pagination')) {
                    document.querySelector('.swiper-pagination').style.width = window.screen.width + 'px'
                }

            }, 600)

        }

    }, [gallery])


    return (
        <StyledGallery id='gallery'>
            <Container ref={containerRef}>
                <Row className='gallery-title'>
                    <Col>
                        <h4>Gallery</h4>
                    </Col>
                </Row>
            </Container>
            <Container fluid ref={containerSetRef}>

                <div className="gallery-slider">

                    {gallery.length &&

                    <LightgalleryProvider
                        lightgallerySettings={
                            {
                                download: false,
                                thumbnail: false,
                                fullScreen: false,
                                share: false
                            }
                        }
                    >
                        <Swiper  {...sliderParams}>
                            {gallery && gallery.map(data => (
                                <div key={data.id} className="gallery-slider__single">
                                    <div className='circle'><img src={plusWhite} alt=""/></div>
                                    <div className="gallery-slider__single__img">
                                        {/*<img src={baseUrl + data.path + data.img_name} alt=""/>*/}

                                        <PhotoItem image={baseUrl + data.path + data.img_name}
                                                   group="group1"/>
                                    </div>
                                    <div className="gallery-slider__single__content">
                                        <Title fontSize={'50'} lineHeight={'50'} color={'#FFF'}
                                               fontWeight={'bold'} text={data.short_desc}/>
                                        {data.short_desc &&
                                        <h4>{data.short_desc}</h4>
                                        }

                                    </div>
                                </div>

                            ))}

                        </Swiper>

                    </LightgalleryProvider>
                    }

                </div>
            </Container>
        </StyledGallery>
    )
}

const StyledGallery = styled.section`
  padding-top: 112px;
  padding-bottom: 50px;
  position: relative;
  min-width: 100vh;
  background-color: #CFCDCC;


  .title {
    top: -43px;
    line-height: 80px;
    left: 170px;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    height: 270px;
    background-color: #5D4E4D;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .gallery-title {
    position: relative;
    z-index: 2;
    //padding-left: 175px;

    h4 {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 15px;
      border-bottom: 1px solid #FFF;
      color: #ffffff;
      min-width: 200px;
      margin: 0;

      display: inline-block;
    }
  }


  .gallery-slider {
    margin-top: 40px;

    &__single {
      position: relative;

      .circle {
        height: 50px;
        width: 50px;
        background-color: #A65E58;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin: auto;
        transition: opacity 0.6s cubic-bezier(0.76, 0, 0.24, 1);
        left: 70px;
        top: -25px;
        opacity: 1;
        z-index: 5;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          transition: all .5s cubic-bezier(0.76, 0, 0.24, 1);;
          border-radius: 50%;
          background-color: #5D4E4D;
          height: 0;
          width: 0;
          margin: auto;
        }

        img {
          z-index: 1;
          position: relative;
        }

        &:hover {
          &:after {
            height: 50px;
            width: 50px;
          }
        }
      }

      &__img {
        padding-top: 40%;
        position: relative;
        z-index: 2;
        transition: all .6s ease;

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
          cursor: pointer;
        }
      }

      &__content {
        //position: absolute;
        //height: 100%;
        //width: 100%;
        //top: 0;
        //z-index: 4;

        h4 {
          position: absolute;
          bottom: 75px;
          left: 170px;
          font-size: 14px;
          padding-bottom: 15px;
          margin: 0;
          border-bottom: 1px solid #FFF;
          min-width: 200px;
          color: #ffffff;
          font-weight: bold;
          display: none;
          z-index: 2;
        }
      }
    }

    .swiper-container {
      padding-right: 30%;
      padding-top: 50px;
      padding-bottom: 50px;

      .swiper-slide-active {
        .gallery-slider__single__img {
          padding-top: 58.44%;
        }

        .title {
          position: absolute;
          width: 50%;
        }

        .gallery-slider__single__content h4 {
          display: inline-block;
        }
      }

      .swiper-pagination-bullets {
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .swiper-pagination-bullet {
          background-color: #FFF;
          margin: 0 6px;
          opacity: 1;
        }
      }

      .swiper-pagination-bullet-active {
        height: 14px;
        width: 14px;
      }

    }

  }


  @media (max-width: 768px) {
    padding-bottom: 65px;
    .container-fluid {
      padding: 0;
    }

    .swiper-container {
      padding-right: 0 !important;
    }

    .swiper-slide {
      .gallery-slider__single__img {
        padding-top: 58.44% !important;
      }
    }

  }

  @media (max-width: 767px) {
    padding-top: 70px;

    .gallery-slider {
      margin-top: 20px;

      &__single {
        .circle {
          display: none;
        }

        .title {
          left: 15px;
        }

        .gallery-slider__single__content h4 {
          left: 15px;
        }
      }
    }


  }


`;
export default DetailGallery