import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {PROJECTS} from "../../constants/projects";


function* fetchProjectsMain() {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_ALL_PROJECTS,
            ''
        );

        yield put({
            type: PROJECTS.FETCH_PROJECTS_PAGE.SUCCESS,
            result:  result.data
        })

    } catch(err) {
        console.log(err);
        Logger(err)
        yield put({
            type: PROJECTS.FETCH_PROJECTS_PAGE.FAILURE
        })
    }
}

function* fetchProjectsDetail(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PROJECT_DETAIL,
            actions.params
        );

        yield put({
            type: PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.SUCCESS,
            result:  result.data
        })

    } catch(err) {
        console.log(err);
        Logger(err)
        yield put({
            type: PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.FAILURE
        })
    }
}

export default function* projectSagas() {
    yield takeLatest(PROJECTS.FETCH_PROJECTS_PAGE.MAIN, fetchProjectsMain);
    yield takeLatest(PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.MAIN, fetchProjectsDetail);

}