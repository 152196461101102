import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {LANDOWNERS} from "../../constants/landowners";
import {ApiServices} from "../../../network/ApiServices";

function* fetchLandowners(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        );

        yield put({
            type: LANDOWNERS.FETCH_LANDOWNERS.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: LANDOWNERS.FETCH_LANDOWNERS.FAILURE
        })
    }
}


export default function* landownersSaga() {
    yield takeLatest(LANDOWNERS.FETCH_LANDOWNERS.MAIN, fetchLandowners)
}
