import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";

const CareerText = ({pageData}) => {


    return (
        <StyleCareerText className="contact-text">
            <Container>
                <Row>
                    <Col sm={8}>
                        {/*<TextSlideChildrenAnimation>*/}
                            <h4>{pageData ? pageData.short_desc : ''}</h4>
                        {/*</TextSlideChildrenAnimation>*/}
                    </Col>

                </Row>

                <Row>
                    <Col sm={{span: 8, offset: 4}}>
                        <Row>
                            <Col sm={6}>
                                {/*<TextSlideChildrenAnimation>*/}
                                <p>{pageData ? pageData.description : ''}</p>
                                {/*</TextSlideChildrenAnimation>*/}
                            </Col>
                            <Col sm={6}>
                                {/*<TextSlideChildrenAnimation>*/}
                                <p>{pageData ? pageData.extra_description : ''}</p>
                                {/*</TextSlideChildrenAnimation>*/}
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </StyleCareerText>
    )
}
const StyleCareerText = styled.section`
  background-color: #EBE7E7;
  padding-top: 150px;

  h4 {
    color: #5D4E4D;
    margin: 0 0 60px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #5d4e4d;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  
  @media(max-width: 767px){
    padding-top: 70px;
  }


`;
export default CareerText;
