import React,{useState} from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';

import arrowImg from '../assets/images/btn-arrow-green.svg'
import arrowImgHover from '../assets/images/btn-arrow-white.svg'
window.$ = window.jQuery = require('jquery')
const ButtonSubmit = ({ text,link,img,hoverImg,fontSize,fontWeight,color,letterSpacing,lineHeight,margin,background,border,width})=>{
    let [btnArrow, setBtnArrow] = useState(arrowImg)

    let handleArrowImgChange = (img) =>  {
        setBtnArrow(img)
    }


    return(
        <StyledBtnSubmit
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            background={background}
            lineHeight={lineHeight}
            letterSpacing={letterSpacing}
            margin={margin}
            border={border}
            img={img}
            width={width}
            text={text}
            hoverImg={hoverImg}
            className="dc-btn">
            <button type='submit'><span>{text}</span></button>
        </StyledBtnSubmit>
    )
};

const StyledBtnSubmit = styled.div`
  &.dc-btn{
    margin:${props => props.margin || '0'};
    width: ${props => props.width || '130'}px;
    height: 50px;
    border-radius:25px;
      button{
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: center;
        font-size: ${props => props.fontSize || 13}px;
        font-weight:${props => props.fontWeight || 400};
        margin:0; 
        line-height:${props => props.lineHeight || 21}px;
        color:${props => props.color || `${hover}`};
        text-transform: capitalize;
        background-color:${props=> props.background ||  '#A65E58'};
        letter-spacing:${props => props.letterSpacing || `0`};    
        position: relative;
        border-radius:25px;
        border:none;
        &:after{
          height: 0;
          width: 0;
          background-color:${props=> props.hoverBg || '#5D4E4D'} ;
          left: 0;
          right: 0;
          top: 0;
            //color:${props=> props.hoverColor || '#FFF'} ;
          content: '';
          position: absolute;
          bottom: 0;
          transition:all .5s ease;
          margin: auto;
          border-radius:25px;
        }
         span {
           z-index: 2;
           color: #ffffff;
          }
          
          &:hover{
             span {
               color: #ffffff !important;
              img{
                transform: translateX(5px);
              }
            }
            &:after {
              height: 100%;
              width: 100%;
            }
          }
      }
  }    
`;





export default ButtonSubmit;
