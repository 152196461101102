import React, {Component, useRef, useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import imgs from '../../../assets/images/dynamic/s1.jpg';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import {BsChevronLeft, BsChevronRight, BsPlus} from "react-icons/bs";

import image1 from '../../../assets/images/dynamic/sd1.svg';
import image2 from '../../../assets/images/dynamic/sd2.svg';
import close from '../../../assets/images/plus.svg';
import {baseUrl} from "../../../components/BaseUrl";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
// import { Autoplay } from 'swiper';


const ServiceSlider = ({post, pageData}) => {
    // SwiperCore.use([Autoplay]);
    const containerRef = useRef(null);
    const imgRef = useRef(null);
    const nextRef = useRef();
    const prevRef = useRef();

    const [slug, setSlug] = useState('')


    useEffect(() => {
        let getLeft = containerRef.current.offsetLeft;


        if (post.length) {
            let getHoverIcon = document.querySelectorAll('.hover-icon');
            let getImageVar = imgRef.current.offsetHeight;
            for (let i of getHoverIcon) {
                i.style.bottom = getImageVar - 25 + 'px';
            }
        }


        let getServiceTopLeft = document.querySelector('.services-top .col-sm-8').offsetLeft;
        let getServiceSliderLeft = document.querySelector('.service-slider .col-sm-8').offsetLeft;
        let getWindowHeight = document.querySelector('#root').clientHeight;
        document.querySelector('.service-detail').style.height = getWindowHeight + 'px'
        if (window.screen.width > 768) {
            document.querySelector('.slider-nav').style.paddingLeft = getLeft + 'px';
            document.querySelector('.project-slider').style.paddingLeft = getServiceTopLeft - getServiceSliderLeft + 15 + 'px';

        }
        let getServiceItem = document.querySelectorAll('.project-slider__single');
        for (let s of getServiceItem) {
            s.addEventListener('click', () => {
                document.querySelector('.service-detail').classList.add('slide')
                window.scrollTo(0, 0);
            })
        }
        document.querySelector('.service-detail__close').addEventListener('click', () => {
            document.querySelector('.service-detail').classList.remove('slide')
        })

        nextRef.current.addEventListener('click', () => {
            document.querySelector('.swiper-button-prev').click()
        });

        prevRef.current.addEventListener('click', () => {
            document.querySelector('.swiper-button-next').click()
        });

    }, [post])


   const handleSlug = (e) =>{
       setSlug(e)
   }

   let filterDetail = post ? post.find(f=> f.data.slug === `${slug}`) : '';

    let sliderParams = {
        slidesPerView: 1,
        spaceBetween: 25,
        // centeredSlides: true,
        autoplay: {
            delay: 7000,
            disableOnInteraction: false,
            // autoplay: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        // loop: true,
        speed: 1000,
        breakpoints: {
            // when window width is >= 320px
            768: {
                // autoplay: true,
                spaceBetween: 35,
                slidesPerView: 2,
            },

        }
    };


    let len = post.length;

    return (
        <StyledService>
            <Container ref={containerRef}>
                <Row className='services-top'>
                    <Col sm={{span: 8, offset: 4}}>
                        <Row>
                            <Col sm={6}>
                                {/*<TextSlideChildrenAnimation>*/}
                                <p>{pageData ? pageData.description : ''}</p>
                                {/*</TextSlideChildrenAnimation>*/}
                            </Col>
                            <Col sm={6}>
                                {/*<TextSlideChildrenAnimation>*/}
                                <p>{pageData ? pageData.short_desc : ''}</p>
                                {/*</TextSlideChildrenAnimation>*/}
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className='service-slider'>

                    <Col sm={4}>
                        <ul className="slider-nav">
                            <li ref={nextRef}><span><BsChevronLeft/></span></li>
                            <li ref={prevRef}><span><BsChevronRight/></span></li>
                        </ul>
                    </Col>
                    <Col sm={8}>
                        <Row>
                            <div className="project-slider">

                                {post.length ?

                                    <Swiper  {...sliderParams}>

                                        {post ? post.map(data => (

                                            <div key={data.data.id} onClick={()=>handleSlug(data.data.slug)} className="project-slider__single">
                                                <div className="project-slider__single__inner">
                                                    <div className="project-slider__single__inner__text">
                                                        <p>{data.data.title}</p>
                                                    </div>
                                                    <div className="project-slider__single__inner__img" ref={imgRef}>
                                                        <div className="project-slider__single__inner__img__inner">
                                                            <img
                                                                src={data.images[0] && baseUrl + data.images[0].path + data.images[0].img_name}
                                                                alt={data.images[0] && data.images[0].img_name}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="project-slider__single__hover">
                                                    <div className="hover-icon">
                                                        <BsPlus/>
                                                    </div>
                                                </div>

                                            </div>
                                        )) : ''}


                                    </Swiper>
                                    : ''}
                            </div>

                        </Row>
                    </Col>
                </Row>
            </Container>


            <div className="service-detail">
                <div className="service-detail__close">
                    <img src={close} alt=""/>
                </div>
                <div className="service-detail__top">
                    <h4>Service </h4>
                    <h2>{filterDetail && filterDetail.data.title }</h2>
                    <h3>{filterDetail && filterDetail.data.extra_deacription_one}</h3>
                    <p>{filterDetail && filterDetail.data.description}</p>
                </div>

            </div>

        </StyledService>
    )
}
const StyledService = styled.section`
  background-color: #EBE7E7;
  padding: 150px 0 150px 0;

  .slider-nav {
    position: absolute;
    bottom: 0;

    li {
      display: inline-block;
      cursor: pointer;
      height: 50px;
      width: 50px;

      &:nth-of-type(1) {
        margin-right: 20px;
      }

      span {
        height: 50px;
        width: 50px;
        background: #CFCDCC;
        //border:1px solid #5D4E4D;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all .3s ease;

        &:after {
          content: '';
          height: 0;
          width: 0;
          background-color: #5D4E4D;
          position: absolute;
          border-radius: 50%;
          transition: all .3s ease;
        }

        svg {
          height: 20px;
          transition: color .4s ease;
          position: relative;
          z-index: 3;
          color: #5D4E4D;
        }

        &:hover {
          border-color: transparent;

          &:after {
            height: 100%;
            width: 100%;
          }

          svg {
            color: #FFF;
          }
        }
      }
    }

  }


  .services-top {
    p {
      margin: 0;
      color: #5d4e4d;
      font-size: 20px;
      line-height: 20px;

    }
  }

  .swiper-container {
    padding-right: 150px;
  }

  .project-slider {
    width: 100%;
    padding-left: 15px;
    margin-top: 100px;

    &__single {
      width: 40%;
      cursor: pointer;

      &__inner {
        padding-top: 146%;
        position: relative;
        background-color: #5D4E4D;

        &__text {
          position: absolute;
          top: 45px;
          left: 30px;
          right: 30px;

          p {
            font-size: 27px;
            font-weight: bold;
            line-height: 30px;
            margin: 0;
            color: #ffffff;
            text-transform: capitalize;
          }
        }

        &__img {
          position: absolute;
          bottom: 0;
          padding-top: 48.67%;
          width: 100%;

          &__inner {
            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }

      &__hover {
        .hover-icon {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background-color: #A65E58;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          &:before {
            content: '';
            height: 0px;
            width: 0px;
            position: absolute;
            background-color: #ffffff;
            transition: all .4s ease;
            border-radius: 50%;
            opacity: 0;
          }

          svg {
            color: #ffffff;
            font-size: 25px;
            z-index: 2;
            transition: color .2s ease;
          }
        }
      }

      &:hover {
        .project-slider__single__hover .hover-icon {
          &:before {
            height: 50px;
            width: 50px;
            opacity: 1;
          }

          svg {
            color: #a65e58;
          }
        }
      }
    }
  }


  .service-detail {
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 9999;
    background-color: #F2E8DC;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 150px 100px 70px 70px;
    box-shadow: 0 0 20px rgba(5, 5, 5, 0.18);
    transition: all .6s ease;
    transform: translateX(100%);
    opacity: 0;

    &__close {
      height: 50px;
      width: 50px;
      position: absolute;
      right: 48px;
      top: 50px;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #5D4E4D;
      border-radius: 50%;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        height: 0px;
        width: 0px;
        border-radius: 50%;
        transition: all .4s ease;
        background-color: #a65e58;
      }

      img {
        height: 20px;
        transform: rotate(45deg);
        z-index: 2;
      }

      &:hover:after {
        opacity: 1;
        height: 50px;
        width: 50px;
      }
    }

    &__top {
      h4 {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 15px;
        display: inline-block;
        min-width: 200px;
        color: rgba(93, 78, 77, 0.80);
        border-bottom: 1px solid #5D4E4D;
        margin: 0;
      }

      h2 {
        color: #a65e58;
        font-size: 50px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 25px 0 15px 0;
      }

      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #5d4e4d;
        margin: 0 0 50px 0;
      }

      p {

      }
    }

    &__single {
      margin-top: 123px;

      &__item {
        padding-bottom: 32px;
        border-bottom: 1px solid #5D4E4D;
        display: flex;
        margin-bottom: 50px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &__img {
          width: 100px;
          margin-right: 20px;

          img {
            object-fit: contain;
          }

        }

        &__content {
          h2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #5d4e4d;
            margin: 0 0 37px 0;
            text-transform: capitalize;
          }

          p {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: #5D4E4D;
            margin-bottom: 15px;

            &:nth-last-child(1) {
              margin: 0;
            }
          }
        }
      }
    }

    &.slide {
      transform: none;
      opacity: 1;
    }

  }

  @media (max-width: 991px) {
    .services-top {
      .col-sm-8 {
        margin-left: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .service-slider {
      .col-sm-4 {
        display: none;
      }

      .col-sm-8 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
    .services-top {
      .col-sm-6 {
        p {
          font-size: 16px;
        }

        &:nth-of-type(1) {
          margin-bottom: 30px;

        }
      }
    }

    .project-slider {
      margin-top: 50px;

      &__single__inner__text {
        left: 15px;
        right: 15px;

        p {
          font-size: 16px;
        }
      }
    }

    .swiper-container {
      padding-right: 80px;
    }

    .service-detail {
      width: calc(100% - 20px);
      padding: 50px 20px 50px 20px;

      &__close {
        top: 20px;
        right: 20px;
        height: 30px;
        width: 30px;

        img {
          height: 15px;
        }
      }

      &__top {
        h2 {
          font-size: 24px;
        }

        h3 {
          margin-bottom: 30px;
        }
      }

      &__single {
        margin-top: 60px;

        h2 {
          margin-bottom: 20px !important;
        }
      }
    }
  }


`;

export default ServiceSlider;

