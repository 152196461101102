import React, {Component, useEffect} from 'react';
import {Container,Row,Col,Form} from "react-bootstrap";
import styled from 'styled-components';
import SubTitle from '../../../components/SubTitle';
import Select from 'react-select';
import ButtonSubmit from '../../../components/ButtonSubmit';
import $ from "jquery";


const options = [
    {value: 'Washing', label: 'Chocolate'},
    {value: 'Knitting', label: 'Strawberry'},
    {value: 'Printing', label: 'Vanilla'}
];



const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor:state.isSelected ? '#41A843' : '#FFF',
        margin:0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor:state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin:0
    }),
    menuList:(provided, state) => ({
        ...provided,
        backgroundColor:state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};


const ContactForm = () =>{

    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.contact-from-left').css('padding-left', getOffset);
            })
        }
    }, []);

    return(
        <StyledContactForm>
            <Container fluid>
                <Row>
                    <Col md={6} className='contact-from-left '>
                        <SubTitle text={'Apply Now'} margin={'0 0 40px 0'} />
                        <Form>
                            <Form.Group>
                                <Form.Control type="text" placeholder="Full name" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control type="number" placeholder="Phone number" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control type="email" placeholder="Email address" />
                            </Form.Group>

                            <Form.Group>
                                <Select styles={customStyles} className='select-here' placeholder='Select Position' options={options} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control placeholder='Cover Letter' as="textarea" rows={1} />
                            </Form.Group>

                            <Form.Group className='inline'>
                                <Select styles={customStyles} className='select-here inline__inner' placeholder='Select area' options={options} />
                                <Form.File className='inline__inner' />
                            </Form.Group>

                            <ButtonSubmit width={'100%'} text={'Submit Application'} fontSize={'16'}  border={'1px solid rgba(26,24,24,0.75)'} color={'rgba(26,24,24,0.75)'} margin={'0'} />

                        </Form>
                    </Col>

                    <Col md={6} className='contact-from-right p-0'>
                        <div className="contact-from-right__inner">
                            <img src={'imgs'} alt=""/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledContactForm>
    )
};
const StyledContactForm = styled.section`
  .contact-from-left{
    padding-right: 85px;
    padding-left: 35px;
    background-color: #F8F8F8;
    padding-top: 95px;
    padding-bottom: 95px;
    .inline{
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      .select-here{
        width: calc(50% - 15px);
      }
      .inline__inner{
        width: calc(50% - 15px);
      }
      
    }
    .form-file input{
      position: relative;
      height: 50px;
      width: 100%;
      &:focus{
        outline:none;
      }
      &:after{
        content: 'Upload your CV';
        height: 100%;
        width: 100%;
        border:1px solid #D5D5D5;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        background-color:#F2F2F2;
        cursor: pointer;
        color: rgba(26,24,24,0.76);
        font-size: 14px;
      }
    }
    .form-group{
      margin-bottom: 30px !important;
    }
    .form-control{
      height: 50px;
      line-height: 53px;
      color: #1a1818;
      font-size: 14px;
      font-weight: 300;
      border-radius: 0;
      border-color:rgba(26,24,24,0.75);
      background-color: transparent;
      padding-left: 20px;
      &::placeholder{
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: rgba(26,24,24,0.75);
      }
    }
      textarea{
      height: 50px;
        min-height: 50px !important;
        max-width:100%;
        padding-top: 15px;
        line-height: 21px !important;
      }
  }
  .contact-from-right{
  &__inner{
    height: 100%;
  }
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  

  
  .css-yk16xz-control{
    background-color: transparent !important;
    border-color:rgba(26,24,24,0.75) !important;
    .css-1uccc91-singleValue{
      color:rgba(26,24,24,0.75) !important;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .css-yk16xz-control .css-1wa3eu0-placeholder{
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba(26,24,24,0.75) !important;
   
  }
  
  @media(max-width:768px){
    .contact-from-left{
      min-width:100%;
      padding-right: 35px;
    }
  }
  
  @media(max-width:767px){
  .row{
    flex-direction: row-reverse;
  }
  .sub-title{
    text-align: center;
  }
    .contact-from-left{
     padding-left: 15px;
     padding-right: 15px;
     padding-top: 50px;
     padding-bottom: 50px;
    }
    .contact-from-left .form-group{
    margin-bottom: 16px !important;
  }
  }
  
`;

export default ContactForm;
