import React, {Component, useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Footer from '../../components/Footer';
import Filter from './components/Filter';
import BannerImage from '../../assets/images/dynamic/projectBanner.jpg';
import ScrollStyle from '../../scrollStyle'
import styled from "styled-components";
const Projects = () =>{
   useEffect(()=>{
       window.scrollTo(0, 0);
   })
    return(
        <StyledProjects>
            <ScrollStyle/>
            <InnerBanner titleColor={'rgba(255,255,255,0.80)'} titleBottom={'79'} image={BannerImage} text={'BUILDING DREAMS'} />
            <Filter />
           
        </StyledProjects>
    )
};

const StyledProjects = styled.section`
  @media(max-width: 768px){
    .inner-banner__content .title{
      bottom: 100px !important;
    }
  }
`;

export default Projects;