import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {fetchServicesPageData} from "../../redux/actions/services/index";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Footer from '../../components/Footer';
import BannerImg from "../../assets/images/dynamic/services.jpg";
import Slider from './components/slider';
import {Loader} from "../../components/Loader";

// img
class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let params = {
            [ApiParams.page_type]: 'services'
        };
        this.props.getService(params)
    }

    render() {
        let {servicePage} = this.props;

        let pageData = servicePage ? servicePage.page_data : '';
        let bannerImage = servicePage ? servicePage.images ? servicePage.images.list[0] : [] : []
        let postList = servicePage ? servicePage.posts ? servicePage.posts.list : [] : [];

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loader/>}
                <InnerBanner
                    text={pageData ? pageData.title : ''}
                    subTitle={pageData ? pageData.sub_title : ''}
                    image={bannerImage ? baseUrl + bannerImage.path + bannerImage.img_name : ''}
                />
                <Slider pageData={pageData} post={postList}/>


            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        servicePage: state.serviceReducer.servicePage,
        loading: state.serviceReducer.servicePageLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getService: params => dispatch(fetchServicesPageData(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
