import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {HOME} from '../../constants/home'

function* fetchHome(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME.SUCCESS,
      result: result.data,
      // message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME.FAILURE, error: "something went wrong"})
  }
}

export default function* homeSagas() {
  yield takeLatest(HOME.FETCH_HOME.MAIN, fetchHome);
}
