export const PROJECTS = {
    FETCH_PROJECTS_PAGE: {
        MAIN: 'FETCH_PROJECTS_PAGE',
        SUCCESS: 'FETCH_PROJECTS_PAGE_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_PAGE_FAILURE'
    },
    FETCH_PROJECTS_DETAIL_PAGE: {
        MAIN: 'FETCH_PROJECTS_DETAIL_PAGE',
        SUCCESS: 'FETCH_PROJECTS_DETAIL_PAGE_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_DETAIL_PAGE_FAILURE'
    }
};
