import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import mapImage from '../../../assets/images/dynamic/map.jpg';
import Link from '../../../components/Link';
import backgroundImg from '../../../assets/images/contact-bg.svg'
import shadow from '../../../assets/images/project-shadow.svg';
import htmlParser from "react-html-parser";

const ContactText = ({data}) => {
    return (
        <StyleContactText className="contact-text">
            <img src={shadow} alt=""/>

            <Container>
                <Row>
                    <Col sm={8}>
                        <h4>{data && data.short_desc}</h4>
                    </Col>

                </Row>

                <Row className='description'>
                    {htmlParser(data && data.description)}
                </Row>
            </Container>
        </StyleContactText>
    )
}
const StyleContactText = styled.section`
  background-color: #EBE7E7;
  padding-top: 124px;
  padding-bottom: 124px;
  position: relative;
  overflow: hidden;

  > img {
    position: absolute;
    width: 80%;
    object-fit: cover;
    top: 0;
    left: -20%;
    opacity: .5;
  }

  h4 {
    color: #5D4E4D;
    margin: 0 0 60px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
  }

  .description {
    p {
      flex: 0 0 33.333%;
      max-width: 33.333%;
      font-size: 16px;
      line-height: 20px;
      color: #5d4e4d;
      padding:0 15px;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
    h4 {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .description p {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 25px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

`;
export default ContactText;
