import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';


const Jd = () => {
    return (
        <StyledJd className='pt-120 pb-120'>
            <Container>
                <Row>
                    <Col md={12}>
                        <Table responsive>
                            <tbody>
                            <tr>
                                <td>Vacancy:</td>
                                <td>Not specific</td>
                            </tr>
                            <tr>
                                <td>Job Context:</td>
                                <td>We are looking for, young, energetic, target oriented and proactive person to employ
                                    as Executive (Sales & marketing) who has the experience in achieving the sales
                                    target in Real Estate sector.
                                </td>
                            </tr>
                            <tr>
                                <td>Job Responsibilities:</td>
                                <td>
                                    To promote sales and Marketing.
                                    Ability to take challenge to achieve the sales target.
                                    Capable to collect latest market information and incorporate the same into a
                                    marketing strategy to achieve the target.
                                    He should be capable to plan corporate sales and its successful implementation.
                                    Capable to visit the project with client for their quires and satisfaction.
                                    To create new sales in various corporate groups, offices in different places.
                                    To make strategies to make new customers.
                                    To do any other jobs assigned by the management.
                                </td>
                            </tr>
                            <tr>
                                <td>Employment Status:</td>
                                <td>
                                    Full-time
                                </td>
                            </tr>
                            <tr>
                                <td>Experience Requirements:</td>
                                <td>
                                    1 to 3 year(s)
                                </td>
                            </tr>
                            <tr>
                                <td>Job Location</td>
                                <td>
                                    Dhaka
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </StyledJd>
    )
};
const StyledJd = styled.section`
  table{
    tr{
      td{
        border:0;
        border-bottom:1px solid rgba(0,0,0,0.20);
        padding: 20px 0;
        font-size: 16px;
        font-weight: 300;
          &:nth-of-type(1){
          font-size: 18px;
           border:none;
           
           font-weight: 500;
           @media(min-width:768px){
            width: 25%;
           }
          }
          
      }
    }
  }
  @media(max-width:767px){
    table tr td{
      font-size: 15px;
        &:nth-of-type(1){
          font-size: 16px;
          padding-right: 20px;
        }
    }
  }
`;
export default Jd;