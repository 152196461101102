import React, {useEffect, useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import styled from 'styled-components';
import ButtonSubmit from '../../../components/ButtonSubmit';
import $ from "jquery";
import Message from "../../../components/PopupMessage";
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import {emailValidation, emptyValidation} from '../../../config/validator';
import {Loading} from "../../../components/Loading";

const options2 = [
    {value: '1000', label: '1000sft '},
    {value: '1200', label: '1200sft '},
    {value: '1400', label: '1400sft '},
    {value: '1600', label: '1600sft '},
    {value: '1800', label: '1800sft '},
    {value: '2000', label: '2000sft '},
    {value: '2000+', label: '2000sft +'}
];
const land = [
    {value: '1', label: 'category one '},
    {value: '2', label: 'category two '},
];

const facing = [
    {value: '1', label: 'facing one '},
    {value: '2', label: 'facing two '},
];
const feature = [
    {value: '1', label: 'feature one '},
    {value: '2', label: 'feature two '},
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#41A843' : '#FFF',
        margin: 0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};
const ContactForm = (props) => {

    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.contact-from-left').css('padding-left', getOffset);
            })
        }

        let mainParam = {
            [ApiParams.form_slug]: 'land-owner'

        };
        props.getFormData(mainParam);

    }, []);

    let {formFields, submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        location: '',
        size: '',
        parking: '',
        handover: '',
        facing: '',
        floor: '',
        bedrooms: '',
        bathrooms: '',
        name: '',
        profession: '',
        phone: '',
        email: '',
        message: '',
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };

    let handleSelect = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    let handleInit = () => {
        setState({
            location: '',
            size: '',
            parking: '',
            handover: '',
            facing: '',
            floor: '',
            bedrooms: '',
            bathrooms: '',
            name: '',
            profession: '',
            phone: '',
            email: '',
            message: '',
        })
    }


    let handleSubmit = (e) => {
        e.preventDefault()

        e.preventDefault()
        let formField = {
            form_id: 'land-owner'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }

        formField["location"] = state.location;
        formField["size"] = state.size;
        formField["parking"] = state.parking;
        formField["handover"] = state.handover;
        formField["facing"] = state.facing;
        formField["floor"] = state.floor;
        formField["bedrooms"] = state.bedrooms;
        formField["bathrooms"] = state.bathrooms;
        formField["name"] = state.name;
        formField["profession"] = state.profession;
        formField["phone"] = state.phone;
        formField["email"] = state.email;
        formField["message"] = state.message;

        let bodyFormData = new FormData();

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        postFormSubmit(bodyFormData);
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.phone);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };

    return (
        <StyledContactForm>
            {!popup ? loading ? <Loading/> : '' : null}
            <Message/>
            <Container>

                <Row>
                    <Col>
                        <h4>The Nassa Holdings Limited has a dedicated team of highly trained professionals to provide
                            excellent customer support service. After all, our prestigious clients deserve only the best
                            services.</h4>
                    </Col>
                </Row>


                <Row>
                    <Form onSubmit={handleSubmit} className='d-flex'>

                        <Col sm={5}>
                            <h4>A. Land Information</h4>

                            <Form.Group className='form-group'>
                                <Form.Label>Locality *</Form.Label>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              value={state.location} onChange={handleChange} name='location' type="text"
                                              placeholder="Enter locality here"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Address *</Form.Label>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              value={state.size} onChange={handleChange} name='size' type="text"
                                              placeholder="Enter full address here"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Size of the land</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.parking} onChange={handleChange} name='parking' type="text"
                                              placeholder="Enter size here"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Width of the road in front</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.handover} onChange={handleChange} name='handover' type="text"
                                              placeholder="Enter rode size here"/>
                            </Form.Group>

                        </Col>

                        <Col sm={{span: 5, offset: 2}}>

                            <h4>B. Landowner's Profile</h4>

                            <Form.Group className='form-group'>
                                <Form.Label>Name of the landowner *</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.name} onChange={handleChange} name='name' type="text"
                                              placeholder="Enter full nam here"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Contact person</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.profession} onChange={handleChange} name='profession' type="text"
                                              placeholder="Enter the minimum no. of bathrooms"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Contact number *</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.phone} onChange={handleChange} name='phone' type="number"
                                              placeholder="Enter your contact number here"/>
                            </Form.Group>

                            <Form.Group className='form-group'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.email} onChange={handleChange} name='email' type="email"
                                              placeholder="Enter your contact number here"/>
                            </Form.Group>


                            <ButtonSubmit onSubmit={handleSubmit}
                                          color={'#FFF'} margin={'75px 0 0 0'} text='Send'/>

                        </Col>


                    </Form>

                </Row>
            </Container>
        </StyledContactForm>
    )
};
const StyledContactForm = styled.section`
  padding-top: 124px;
  padding-bottom: 124px;

  h4 {
    font-size: 20px;
    color: #5D4E4D;
    margin: 0;
    line-height: 22px;
    font-weight: bold;
  }

  form {
    width: 100%;

    h4 {
      margin-top: 120px;
      margin-bottom: 80px;
      padding-bottom: 20px;
      border-bottom: 1px solid #5D4E4D;
      min-width: 300px;
      width: fit-content;
      font-size: 16px;
    }

    p {
      margin-top: 120px;
      margin-bottom: 40px;
      font-size: 16px;
      font-weight: 400;
      color: #5D4E4D;
    }

    .contact-from {
      margin-top: 83px;
    }

    .form-group {
      margin-bottom: 45px;
    }


    .form-control {
      height: 50px;
      line-height: 53px;
      color: #1a1818;
      font-size: 14px;
      font-weight: 300;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #5D4E4D;
      background-color: transparent;
      padding-left: 60px;

      &::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #CFCDCC;
      }
    }

    textarea {
      height: 50px;
      min-height: 50px !important;
      max-width: 100%;
      padding-top: 15px;
      line-height: 21px !important;
    }

  }


  .css-yk16xz-control {
    background-color: transparent !important;
    border-color: rgba(26, 24, 24, 0.75) !important;

    .css-1uccc91-singleValue {
      color: rgba(26, 24, 24, 0.75) !important;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba(26, 24, 24, 0.75) !important;

  }


  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
    form {
      flex-wrap: wrap;

      h4 {
        margin: 40px 0 40px 0;
      }

      p {
        margin-top: 40px;
      }

      .d-flex {
        flex-wrap: wrap;

      }

      .col-sm-5 {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0
      }
    }
  }


`;

function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
