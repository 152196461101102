export const TEAM = {
    FETCH_TEAM: {
        MAIN: 'FETCH_TEAM',
        SUCCESS: 'FETCH_TEAM_SUCCESS',
        FAILURE: 'FETCH_TEAM_FAILURE'
    },
};

export const DIRECTORS = {
    FETCH_DIRECTORS: {
        MAIN: 'FETCH_DIRECTORS',
        SUCCESS: 'FETCH_DIRECTORS_SUCCESS',
        FAILURE: 'FETCH_DIRECTORS_FAILURE'
    },
};
