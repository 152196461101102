import React, {useEffect} from 'react';
import Banner from './components/DetailBanner';
import Specification from './components/DetailSpecification';
import Gallery from './components/DetailGallery';
import Map from './components/Map';
import {fetchProjectsDetail} from "../../redux/actions/projects";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {baseUrl} from "../../components/BaseUrl";
import {ApiParams} from "../../constants/apiParams";
import ScrollStyle from '../../scrollStyle';
import Footer from '../../components/Footer'
import Overview from './components/DetailOverview';
import Features from './components/DetailFeatures';
import DetailFeaturesAmenities from "./components/DetailFeaturesAmenities";
import {Loader} from "../../components/Loader";


const ProjectDetail = (props) => {
    window.scrollTo(0, 0);
    let {slug} = props.match.params;

    useEffect(() => {
        let params = {
            [ApiParams.project_slug]: slug
        };
        props.getProjectsDetail(params)


    }, []);

    let {projectDetail} = props;
    let {projectDetailLoading} = props;

    let banner = projectDetail ? projectDetail.images ? projectDetail.images.list.find(f => f.is_banner === 'on') ? projectDetail.images.list.find(f => f.is_banner === 'on').path + projectDetail.images.list.find(f => f.is_banner === 'on').img_name : [] : [] : [];
    let productData = projectDetail ? projectDetail.product_data : []
    let category = projectDetail ? projectDetail.category_data : []

    let allPost = projectDetail ? projectDetail.posts ? projectDetail.posts.list : [] : [];

    let filterOverview = allPost ? allPost.find(f => f.data.type === '7') : []
    let filterSpecification = allPost ? allPost.find(f => f.data.type === '8') : []
    let filterGallery = allPost ? allPost.find(f => f.data.type === '9') : []
    let filterFeatures = allPost ? allPost.filter(f => f.data.type === '10') : []
    let filterAmenities = allPost ? allPost.filter(f => f.data.type === '12') : []
    let filterLocation = allPost ? allPost.find(f => f.data.type === '11') : []


    return (
        <>
            {projectDetailLoading ? <Loader/> : null}
            <ScrollStyle/>
            <Banner img={banner && baseUrl + banner} SubTitle={category && category.title}
                    Title={productData && productData.title}/>
            <Overview location={productData && productData.address} data={filterOverview}/>
            <Specification data={filterSpecification}/>
            <Gallery data={filterGallery}/>
            <DetailFeaturesAmenities data={filterFeatures}/>
            <Features data={filterAmenities}/>
            <Map  data={filterLocation}/>
        </>
    )
};


let mapStateToProps = state => {
        return {
            projectDetail: state.projectsReducer.projectDetail,
            projectDetailLoading: state.projectsReducer.projectDetailLoading,
        }
    }
;

let mapDispatchToProps = dispatch => {
    return {
        getProjectsDetail: (params) => dispatch(fetchProjectsDetail(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetail));
