import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import VisibilitySensor from "react-visibility-sensor";
import Title from './Title';


const InnerBanner = ({subTitle, text, image, titleBottom, titleColor, children, height}) => {
    return (
        <StyledBanner height={height} titleColor={titleColor} titleBottom={titleBottom} className="inner-banner">
            <img src={image} alt=""/>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="inner-banner__content">
                            {subTitle ?
                                <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                    {({isVisible}) =>
                                        <p>{subTitle}</p>
                                    }
                                </VisibilitySensor>
                                : ''}

                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                {({isVisible}) =>
                                    <Title fontSize={'50'} lineHeight={'50'} color={titleColor} text={text}/>
                                }
                            </VisibilitySensor>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledBanner>
    )
};

const StyledBanner = styled.div`
  height: ${props => props.height || '0'};
  position: relative;
  padding-top: ${props => !props.height ? 'calc( 500 / 1366 * 100%)' : '0'};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .container {
    position: relative;
    height: 100%;

    .row {
      height: 100%;
    }
  }

  p {
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
    padding-bottom: 20px;
    border-bottom: 1px solid #FFF;
    text-transform: capitalize;
    position: absolute;
    bottom: 50px;
    margin: 0;
    min-width: 250px;
  }

  .inner-banner__content {
    height: 100%;
    display: block;
    position: relative;
    z-index: 2;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }

  .title {
    bottom: ${props => props.titleBottom || '-21'}px;
    left: 0;
    // h2{
      //   color:${props => props.titleColor || '#FFF'} !important;
    // }
  }

  @media (max-width: 768px) {
    overflow: visible !important;
    padding-top: calc(450 / 414 * 100%);
    .container, .container-sm {
      overflow: visible !important;
    }

    .inner-banner__content{
      .title{
        bottom: -16px;
      }
      p {
        bottom: 60px;
      }
    }
    

  }

  @media (max-width: 767px) {
    overflow: visible !important;
    padding-top: calc(591 / 414 * 100%);
    position: relative;
    .container,.row,.col-sm-12{
      height: 100%;
      //position: absolute;
      //top: 0;
      //left: 0;
      //bottom: 0;
      //z-index: 3;
    }
    .inner-banner__content {
      bottom: -15px;
      position: absolute;
      height: fit-content;
      left: 15px;
      right: 15px;
      p{
        position: relative;
        bottom: 30px;
        padding-bottom: 10px;
      }
      .title{
        bottom: 0;
        position: relative;
        
      }
    }
  }

`;

export default InnerBanner;
