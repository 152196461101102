import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import VisibilitySensor from "react-visibility-sensor";
import htmlParser from 'react-html-parser';


const Description = ({post}) => {
    return (
        <StyledDescription className='news-details pt-120 pb-120'>
            <Container>
                <Row>
                    <Col md={12}>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <h1 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{post ? post.data.title.split("").map(function (char, index) {
                                    return <span aria-hidden="true" key={index}>{char}</span>;
                                }) : ''}</h1>
                            }
                        </VisibilitySensor>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <div className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                                    {post ? htmlParser(post.data.description) : ''}

                                </div>
                            }
                        </VisibilitySensor>

                    </Col>
                </Row>
            </Container>

        </StyledDescription>
    )
};
const StyledDescription = styled.section`
  background-color: rgba(238, 238, 238, 0.40);
  font-size: 16px;
  font-weight: 400;
  h1 {
    margin: 0 0 40px 0;
    span{
      font-size: 25px !important;
      font-weight: 500 !important;
    }
  }

  p {
    &:nth-last-of-type(1) {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }
  
  span{
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  
  ul{
    padding-left: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
    
    font-weight: 400;
    li{
      list-style: circle;
      font-size: 15px;
    }
  }
  
  
`;

export default Description;