import {DIRECTORS, TEAM} from "../../constants/team";

export function fetchTeam(params) {
    return {
        type: TEAM.FETCH_TEAM.MAIN,
        params
    }
}

export function fetchDirectors(params) {
    return {
        type: DIRECTORS.FETCH_DIRECTORS.MAIN,
        params
    }
}
