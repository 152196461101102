import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import styled from 'styled-components';
import {fetchProjectsMain} from "../../../redux/actions/projects";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {baseUrl} from "../../../components/BaseUrl";
import plusImg from "../../../assets/images/plus.svg";
import Select from 'react-select';
import projectShadow from '../../../assets/images/project-shadow.svg';
import htmlParser from "react-html-parser";
import {useSelector} from "react-redux";
import {Loader} from "../../../components/Loader";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#5D4E4D' : '#FFF',
        margin: 0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0,

    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};

const optionsOne = [
    {value: 'ongoing', label: 'Ongoing '},
    {value: 'completed', label: 'Completed '},
    {value: 'upcoming', label: 'Upcoming '},
];

const optionsTwo = [
    {value: 1, label: 'Residential'},
    {value: 2, label: 'Commercial '},
];


class Filter extends Component {

    state = {
        innerFilter: '',
        filterData: [],
        category: '',
        location: ''
    };


    componentDidMount() {
        this.props.getProjectsMain();

        // window.scrollTo(0, 0);
    }

    handleType = (data) => {
        this.setState({innerFilter: data})
    };

    handleSelect = (category, value) => {
        this.setState({
            category: value
        });
        this.props.history.push(`/projects/${value}`);
        window.scrollTo(0, 400);
    }

    handleLocation = (location, value) => {
        this.setState({
            location: value
        });
    }

    // onChange = (e) => {
    //     this.props.history.push(`/${e.target.value}`);
    // }

    render() {
        let projectMain = this.props?.projectMain?.list;
        let {category} = this.props.match.params;

        let firstFilter = projectMain && projectMain?.filter(f => f?.category_data?.list?.find(e => e.slug === category));
        let allProjectLocationList = projectMain && projectMain?.filter(f => f.product_data.location);

        const optionsThree = this.props?.projectMain?.location?.map(location => (
            {value: location.location, label: location.location}
        ));

        let cat = firstFilter?.length ? this.state.innerFilter !== '' ? firstFilter?.filter(data => parseInt(data?.product_data?.project_status) === parseInt(this.state.innerFilter)) : firstFilter : [];

        let product = cat ? this.state.location !== '' ? cat.filter(data => data.product_data.location === this.state.location) : cat : [];


        let bannerTitle = cat ? cat[0] ? cat[0].category_data ? cat[0].category_data.title : '' : '' : '';

        return (
            <>
                {this.props.loading && <Loader/>}

                <StyledFilter className="product-filter">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <Select onChange={op => this.handleSelect('category', op.value)} styles={customStyles}
                                        className='select-here files' placeholder={category}
                                        options={optionsOne}/>
                            </Col>
                            <Col sm={4}>
                                <Select onChange={type => this.handleType(type.value)} styles={customStyles}
                                        className='select-here files' placeholder='Select Type' options={optionsTwo}/>
                            </Col>
                            <Col sm={4}>
                                <Select onChange={op => this.handleLocation('location', op.value)} styles={customStyles}
                                        className='select-here files' placeholder='Select Location'
                                        options={optionsThree}/>
                            </Col>
                        </Row>
                    </Container>

                </StyledFilter>
                <StyledProduct className="project-listing">
                    <Container>
                        <Row>
                            {product ? product.map(data => (

                                <Col key={data.product_data.id} sm={4}>
                                    <div className="project-slider__single">
                                        <div className="project-slider__single__inner">
                                            <Link to={`/project/${data.product_data.slug}`}/>
                                            {/*{baseUrl + data.images ? data.images.list.slice(0, 1).find(f => f.is_thumb === 'on').path +  data.images.list.slice(0, 1).find(f => f.is_thumb === 'on').img_name : ''}*/}
                                            <img
                                                src={data.images.list.find(f => f.is_thumb === 'on') ? baseUrl + data.images.list.find(f => f.is_thumb === 'on').path + data.images.list.find(f => f.is_thumb === 'on').img_name : 'no image found'}
                                                alt=""/>
                                            <div className="project-slider__single__inner__content">
                                                <p>{data.product_data.title}</p>
                                            </div>
                                        </div>
                                        <div className="project-slider__single__hover">
                                            <div className="plus">
                                                <img src={plusImg} alt="plusImg"/>
                                            </div>

                                            <div className="content">
                                                <div className="content__inner">
                                                    <p>
                                                        {htmlParser(data.product_data.address)}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )) : 'Loading...'}
                        </Row>
                    </Container>
                </StyledProduct>
            </>
        )
    }
}

const StyledFilter = styled.section`
  background-color: #fff;
  padding: 50px;


  .css-yk16xz-control, .css-1pahdxg-control {
    border-color: #5D4E4D !important;
  }

  .css-1njwew5-menu {
    z-index: 9;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #5d4e4d !important;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
  }

  .css-1uccc91-singleValue {
    color: #5d4e4d !important;
    font-weight: bold;
    font-size: 16px !important;
    cursor: pointer !important;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
    .container {
      overflow: visible;
    }

    .col-sm-4 {
      margin-bottom: 30px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }

`;


const StyledProduct = styled.section`
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #F4F4F4;
  min-height: 80vh;

  .container {
    position: relative;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: '';
    width: 70%;
    height: 70%;
    right: 0;
    top: 0;
    background-image: url(${projectShadow});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg-img {
    position: absolute;
    width: 47%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    object-fit: cover;
  }

  .project-slider {
    padding-left: 105px;

    &__single {
      position: relative;
      margin-bottom: 30px;

      &__inner {
        padding-top: 135.14%;
        position: relative;
        overflow: hidden;

        a {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;
          width: 100%;
        }

        img {
          object-fit: cover;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          transform: scale(1.01);
          transition: transform .6s ease;
        }

        p {
          font-size: 22px;
          line-height: 22px;
          position: absolute;
          bottom: 40px;
          width: 100%;
          padding: 0px 10px 0 40px;
          z-index: 2;
          color: #ffffff;
          margin: 0;
          text-transform: capitalize;

        }

        &:after {
          content: '';
          position: absolute;
          background-color: rgba(26, 24, 24, 0.25);
          z-index: 1;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }

      &__hover {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .plus {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background-color: #A65E58;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .4s ease;

          img {
            object-fit: contain;
            height: 20px;
          }
        }

        .content {
          position: absolute;
          width: 50px;
          left: 23px;
          right: 23px;
          padding-top: 12%;
          border: 1px solid #FFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          margin: auto;
          transition: all .6s ease;
          opacity: 0;

          &__inner {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            display: flex;
            align-items: center;
            padding-left: 20px;
            opacity: 0;
            transition: opacity .4s ease;


            p {
              margin: 0;
              color: #ffffff;
              font-size: 16px;
              line-height: 20px;
              opacity: 0;
              transition-delay: 0s;
              transition: opacity .3s ease;
              width: 50%;
            }
          }

        }
      }

      &:hover {
        .project-slider__single__inner {
          img {
            transform: scale(1.06);
          }
        }

        .project-slider__single__hover {
          .plus {
            width: 250px;
            height: 250px;
            opacity: 0;
          }

          .content {
            width: calc(100% - 46px);
            padding-top: 89%;
            opacity: 1;

            &__inner {
              opacity: 1;

              p {
                opacity: 1;
                transition-delay: .6s;

              }
            }
          }
        }
      }
    }

  }

  .swiper-container {
    padding-right: 80px;
  }


  @media (max-width: 991px) {
    .OurProject__left-text p {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 70px 0px 70px 0px ;
    .OurProject__left-text {
      flex: 0 0 100%;
      max-width: 100%;

      p {
        display: block;
      }

      h2 {
        margin-bottom: 50px;
      }

      br {
        display: none;
      }
    }

    .slider-nav {
      display: none;
    }

    .col-sm-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .project-slider {
      margin-top: 50px;
      padding-left: 0 !important;

      .swiper-container {
        padding-right: 100px !important;
      }
    }

  }


`;

function mapStateToProps(state) {
    return {
        projectMain: state.projectsReducer.projectMain,
        loading: state.projectsReducer.projectMainLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectsMain: () => dispatch(fetchProjectsMain())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
