import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import {BsChevronLeft, BsChevronRight,} from 'react-icons/bs';
import plusWhite from '../../../assets/images/plus-white.svg';
import hovrImg from '../../../assets/images/hover-icon.svg';
import htmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";

const OurProjects = ({data}) => {


    const containerRef = useRef();
    const bgRef = useRef();
    const nextRef = useRef();
    const prevRef = useRef();
    // this.state = {offSet: null};


    let sliderParams = {
        slidesPerView: 1,
        spaceBetween: 20,
        // centeredSlides: true,
        autoplay: {
            delay: 7500,
            disableOnInteraction: false,
            autoplay: true
        },
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            991: {
                spaceBetween: 30,
                slidesPerView: 1,
            },
            992: {
                spaceBetween: 30,
                slidesPerView: 2,
            },

        }
    };


    useEffect(() => {
        let getOffset = containerRef.current.offsetLeft + 30;
        let getWidth = containerRef.current.offsetWidth;
        if (window.screen.width > 768) {
            document.querySelector('.features__left-text').style.paddingLeft = getOffset + 'px';
        }
        //
        nextRef.current.addEventListener('click', () => {
            document.querySelector('.swiper-button-prev').click()
        });

        prevRef.current.addEventListener('click', () => {
            document.querySelector('.swiper-button-next').click()
        });


    }, [])


    return (
        <StyledFeatures>
            {/*<img className='bg-img' src={bgImg} alt=""/>*/}
            <Container ref={containerRef}/>
            <Container fluid className='features p-0'>
                <Row>
                    <Col sm={4} className='features__left-text'>
                        <h4>General Amenities </h4>
                        {/*<TextSlideChildrenAnimation>*/}
                        <p>Our projects speak for themselves! We take pride in our ability to provide managerial,
                            engineering and skilled labours on-demand for minor to major construction projects and that
                            reflects directly on our success to provide excellent services to all our customers in the
                            forms of general amenities.</p>
                        {/*</TextSlideChildrenAnimation>*/}

                        {window.screen.width > 767 &&
                        <ul className="slider-nav">
                            <li ref={nextRef}><span><BsChevronLeft/></span></li>
                            <li ref={prevRef}><span><BsChevronRight/></span></li>
                        </ul>
                        }


                    </Col>


                    <Col sm={8} ref={bgRef}>
                        <div className="features-slider">

                            {data.length && <Swiper  {...sliderParams}>

                                {data && data.map(item => (
                                    <div key={item.data.id} className="features-slider__item">
                                        <div className="features-slider__item__inner">
                                            <div className="features-slider__item__inner__content">
                                                <h4>{item.data.title}</h4>
                                                {htmlParser(item.data.description)}
                                            </div>

                                            <div className="features-slider__item__inner__hover">
                                                <img
                                                    src={item.images && item.images.find(f => f.is_thumb === 'on') ? baseUrl + item.images.find(f => f.is_thumb === 'on').path + item.images.find(f => f.is_thumb === 'on').img_name : hovrImg}
                                                    alt={hovrImg}/>
                                                <div className="circle"><img src={plusWhite} alt={plusWhite}/></div>
                                                <div className="border-radius"/>
                                            </div>

                                            <div className="features-slider__item__inner__img">
                                                <img
                                                    src={item.images && item.images.find(f => f.is_banner === 'on') ? baseUrl + item.images.find(f => f.is_banner === 'on').path + item.images.find(f => f.is_banner === 'on').img_name : ''}
                                                    alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </Swiper>}

                            {window.screen.width < 767 &&
                            <ul className="slider-nav">
                                <li ref={nextRef}><span><BsChevronLeft/></span></li>
                                <li ref={prevRef}><span><BsChevronRight/></span></li>
                            </ul>
                            }

                        </div>


                    </Col>

                </Row>
            </Container>
        </StyledFeatures>
    )

}

const StyledFeatures = styled.section`
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;

  .swiper-button-next, .swiper-button-next {
    opacity: 0;
  }

  .bg-img {
    position: absolute;
    width: 47%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;

    object-fit: cover;
  }

  .features {
    &__left-text {
      position: relative;

      h4 {
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 36px 0;
        padding-bottom: 27px;
        color: #5D4E4D;
        border-bottom: 1px solid #5D4E4D;
      }

      h2 {
        font-size: 30px;
        font-weight: bold;
        margin: 0 0 60px 0;
        color: #5D4E4D;
        line-height: 30px;
      }

      p {
        font-size: 16px;
        color: #958d87;
        line-height: 20px;
        margin: 0;
      }

      .slider-nav {
        margin-top: 60px;
        position: absolute;
        bottom: 0;

        li {
          display: inline-block;
          cursor: pointer;
          height: 50px;
          width: 50px;

          &:nth-of-type(1) {
            margin-right: 20px;
          }

          span {
            height: 50px;
            width: 50px;
            background: #CFCDCC;
            border: 1px solid #CFCDCC;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: all .3s ease;

            &:after {
              content: '';
              height: 0;
              width: 0;
              background-color: #5D4E4D;
              position: absolute;
              border-radius: 50%;
              transition: all .3s ease;
            }

            svg {
              height: 20px;
              transition: color .4s ease;
              position: relative;
              z-index: 3;
              color: #FFF;
            }

            &:hover {
              border-color: transparent;

              &:after {
                height: 100%;
                width: 100%;
              }

              svg {
                color: #FFF;
              }
            }
          }
        }

      }
    }

    .features-slider {
      padding-left: 100px;

      .features-slider__item {
        background-color: #f2e8dc;

        &__inner {
          padding-top: calc(368 / 252 * 100%);
          position: relative;

          &__content {
            position: absolute;
            top: 30px;
            left: 30px;
            right: 30px;

            h4 {
              font-size: 20px;
              font-weight: bold;
              line-height: 22px;
              color: #5d4e4d;
              margin: 0 0 34px 0;
            }

            p {
              font-size: 16px;
              margin: 0;
              line-height: 20px;
              color: #5d4e4d;
              opacity: 0;
              transition-delay: 0s;
              transition: opacity .6s ease;
            }
          }

          &__hover {
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            align-items: center;
            justify-content: center;
            display: flex;
            margin-bottom: 28.33%;
            height: 150px;

            > img {
              object-fit: contain;
              transition: all 0.3s cubic-bezier(0.76, 0, 0.24, 1);
              max-height: 130%;
              max-width: 130%;
              opacity: 1;
              transform: scale(1.01);
            }

            .circle {
              height: 50px;
              width: 50px;
              background-color: #A65E58;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              margin: auto;
              transition: opacity 0.6s cubic-bezier(0.76, 0, 0.24, 1);
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              opacity: 1;
            }

            .border-radius {
              position: absolute;
              height: 0px;
              width: 0px;
              margin: auto;
              border-radius: 50%;
              border: 1px solid #FFF;
              transition: all 0.6s cubic-bezier(0.76, 0, 0.24, 1);
              opacity: 0;
              //z-index: 3;
            }

          }

          &__img {
            padding-top: 48.33%;
            position: absolute;
            bottom: 0;
            width: 100%;

            &:after {
              content: '';
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              background-color: #f2e8dc;
              transition: height 0.6s cubic-bezier(0.76, 0, 0.24, 1);
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
            }
          }


        }

        &:hover {
          .features-slider__item__inner__content {
            p {
              opacity: 1;
              transition-delay: .2s;
            }
          }

          .features-slider__item__inner__hover {
            > img {
              max-height: 0;
              max-width: 0;
              opacity: 0;
            }

            .circle {
              opacity: 0;
            }
          }

          .border-radius {
            height: 200px;
            width: 200px;
            opacity: 1;
          }

          .features-slider__item__inner__img {
            &:after {
              height: 0;
            }
          }
        }
      }

      .slider-nav {
        margin-top: 60px;
        position: absolute;
        bottom: -85px;

        li {
          display: inline-block;
          cursor: pointer;
          height: 50px;
          width: 50px;

          &:nth-of-type(1) {
            margin-right: 20px;
          }

          span {
            height: 50px;
            width: 50px;
            background: #CFCDCC;
            border: 1px solid #CFCDCC;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: all .3s ease;

            &:after {
              content: '';
              height: 0;
              width: 0;
              background-color: #5D4E4D;
              position: absolute;
              border-radius: 50%;
              transition: all .3s ease;
            }

            svg {
              height: 20px;
              transition: color .4s ease;
              position: relative;
              z-index: 3;
              color: #FFF;
            }

            &:hover {
              border-color: transparent;

              &:after {
                height: 100%;
                width: 100%;
              }

              svg {
                color: #FFF;
              }
            }
          }
        }

      }


    }


    .swiper-container {
      padding-right: 80px;
    }


  }

  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 160px;
    .features__left-text, .col-sm-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .features__left-text {
      margin-bottom: 40px;
    }

    .features-slider {
      padding-left: 0 !important;
    }

    .features {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .features-slider__item__inner__hover img {
      max-width: 110% !important;
      max-height: 110% !important;
    }

  }


`;

export default OurProjects;