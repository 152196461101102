import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import locationImg from '../../../assets/images/product-location.svg';
import htmlParser from "react-html-parser";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import VisibilitySensor from "react-visibility-sensor";

const Overview = ({data, location}) => {

    return (
        <StyledOverview id='overview'>
            <Container>

                <Row className='location'>
                    <img src={locationImg} alt=""/> <p>{location || 'Dhaka Bangladesh'}</p>
                </Row>

                <Row className='detail-menu'>
                    <Col>
                        <ul>
                            <li className='active'><a href="#overview">Overview</a></li>
                            <li className=''><a href="#specifications">Specifications</a></li>
                            <li className=''><a href="#gallery">Gallery</a></li>
                            <li className=''><a href="#features">Features and Amenities</a></li>
                            <li className=''><a href="#location">Location</a></li>
                        </ul>
                    </Col>
                </Row>


                <Row className='overview-text'>
                    <Col sm={8}>
                        {/*<TextSlideChildrenAnimation>*/}
                            <h4>{data && htmlParser(data.data.title)}</h4>
                        {/*</TextSlideChildrenAnimation>*/}
                    </Col>
                </Row>

                <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                    {({isVisible}) =>
                        <Row className={`${isVisible ? 'anim-active' : ''} fade-up overview-repeat-text`}>
                            {data && htmlParser(data.data.description)}
                        </Row>
                    }
                </VisibilitySensor>

            </Container>
        </StyledOverview>
    )


}
const StyledOverview = styled.section`
  background-color: #5D4E4D;
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;

  .location {
    position: absolute;
    top: 40px;
    display: flex;
    align-items: center;
    margin-left: 5px;

    img {
      height: 16px;
      margin-right: 16px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      color: #ffffff;
    }
  }

  .detail-menu {
    ul {
      border-bottom: 1px solid #EBE7E7;

      li {
        display: inline-block;
        position: relative;

        span {
          z-index: 2;
        }

        &:after {
          content: '';
          transition: height .4s cubic-bezier(.25, .74, .22, .99);
          height: 0%;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: #EBE7E7;
          position: absolute;
        }

        a {
          height: 77px;
          padding: 0 30px;
          color: #ebe7e7;
          font-size: 18px;
          line-height: 26px;
          border-right: 1px solid #EBE7E7;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .4s ease;
          z-index: 2;
          position: relative;
          &:hover {
            color: #5d4e4d;

          }
        }

        &:hover{
          &:after{
            height: 100%;
          }
        }
        &.active {
          a {
            color: #5d4e4d;
          }
          &:after{
            height: 100%;
          }
        }

        &:nth-last-child(1) a {
          border-right: 0;
        }
      }
    }
  }

  .overview-text {
    margin-top: 100px;

    h4 {
      font-size: 20px;
      font-weight: bold;
      line-height: 22px;
      margin: 0;
      color: #ffffff;
    }
  }

  .overview-repeat-text {
    margin-top: 60px;
    display: flex;

    p {
      font-size: 16px;
      color: #ffffff;
      line-height: 20px;
      margin: 0;
      width: 33.333%;
      padding: 0 15px;
    }
  }

  @media (max-width: 900px) {
    .detail-menu {
      display: none;
    }

    .overview-text {
      margin-top: 0;
    }

  }

  @media (max-width: 767px) {
    padding-top: 90px;
    padding-bottom: 70px;
    overflow: visible;
    .container {
      overflow: visible;
    }

    .location {
      top: 25px;
      right: 15px;
      left: 15px;
      img{
        margin-bottom: 5px;
      }
    }

    .overview-text {
      margin-top: 30px;
    }

    .overview-repeat-text P {
      width: 100%;
      margin-bottom: 23px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }

`;
export default Overview;