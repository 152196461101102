import React, {Component} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import InfoWindowEx from "./InfoWindowEx";
import mapStyle from '../../../components/MapStyle';
import {MAP_API_KEY} from '../../../config/index'


// img
import pointer from '../../../assets/images/map-pointer.svg';
import ReactHtmlParser from "react-html-parser";
import $ from "jquery";

const MapData = [{
    'id': 1,
    'lat': 23.800882789462474,
    'lng': 90.4098678588795,
    'info': `<div class="map-info"><div class="map-info__img"><img src=${'mapImg'} alt=""></div><div class="map-info__content"><h4>Nasa Holdings</h4> <p>Rangs Babylonia, Level 6-9, 246, Bir Uttam Mir Shawkat Road
Tejgaon, Dhaka-1208</p></div></div>`
},{
    'id': 2,
    'lat': 23.798512862102363,
    'lng':  90.40809705812975,
    'info': `<div class="map-info"><div class="map-info__img"><img src=${'mapImg'} alt=""></div><div class="map-info__content"><h4>Nasa Holdings</h4> <p>Rangs Babylonia, Level 6-9, 246, Bir Uttam Mir Shawkat Road
Tejgaon, Dhaka-1208</p></div></div>`
},{
    'id': 3,
    'lat': 23.80354755481853,
    'lng': 90.40637830246213,
    'info': `<div class="map-info"><div class="map-info__img"><img src=${'mapImg'} alt=""></div><div class="map-info__content"><h4>Nasa Holdings</h4> <p>Rangs Babylonia, Level 6-9, 246, Bir Uttam Mir Shawkat Road
Tejgaon, Dhaka-1208</p></div></div>`
}];

export class Maps extends Component {

    componentDidMount() {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.map-right').css('padding-left', getOffset);
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            currLatt: 0,
            currLng: 0,
            showHelpLink: false,
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {},
            windowWidth: 0,
            windowHeight: 0,
            markerIcon: [
                pointer,
            ],
            selectedMarkers: '',
            clickedID: false,
            connectionId: ''
        };
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    onClose = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
                clickedID: false
            });
        }
    };



    render() {
        let zoom = 14;
        let center = {'lat': 23.800882789462474, 'lng':90.40822946037208};

        let {mapData} = this.props;

        // console.log('mapData',mapData)

        return (
            <StyledMap>
                <Container fluid>
                    <Row className='row-eq-height'>
                        <Col sm={12} className="map-left p-0">
                            <Map
                                google={this.props.google}
                                zoom={zoom}
                                initialCenter={center}
                                onClick={this.onClose}
                                mapTypeControl={false}
                                styles={mapStyle}
                                fullscreenControl={false}
                            >
                                {mapData ? mapData.map((data, idx) => {
                                    let pos = {'lat': data.data.extra_deacription_one, 'lng': data.data.extra_deacription_two}
                                    return (
                                        <Marker
                                            key={idx}
                                            position={pos}
                                            title={ReactHtmlParser(data.data.title)}
                                            formatted_address={data.data.id}
                                            name={ReactHtmlParser(data.data.description)}
                                            icon={{
                                                url: `${pointer}`
                                            }}
                                            onClick={this.onMarkerClick}
                                        />
                                    )
                                }):''}
                                <InfoWindowEx
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                    onClick={this.onClose}
                                >
                                    <div className="info-window">
                                        <div>{this.state.selectedPlace.name}</div>
                                    </div>
                                </InfoWindowEx>
                            </Map>
                        </Col>


                    </Row>
                </Container>
            </StyledMap>
        )
    }


};

const StyledMap = styled.div`
     .map-left{
        position: relative;
        height: 700px;
       >div{
          height: 100% !important;
          position: absolute;
          width: 100%;
        }
     }
     .map-right{
        background-color:#D6D6D6;
        position: relative;
        height: 700px;
        padding-left: 110px;
        &:after{
          content: '';
          position: absolute;
          height: 100%;
          left: 0;
          width: 100%;
          top: 0;
          background-color: rgba(0,0,0,0.5);
        }
      >img{
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      &__content{
          height: 100%;
        justify-content: center;
        display: flex;
        position: relative;
        z-index: 1;
        flex-direction: column;
        align-items: flex-start;
       h4{
        font-size: 30px;
        font-weight: 600;
        color: #ffffff;
        line-height: 40px;
        margin: 0;
        //width: 50%;
        text-transform: uppercase;
       }
       &__inner{
        margin-top: 60px;
       }
       p{
       color: #ffffff;
       margin-bottom: 10px;
       &:nth-of-type(1){
        margin-bottom: 30px;
       }
        a{
          display: inline;
          color: #ffffff;
          font-size: 17px;
          line-height: 24px;
        }
        &:nth-last-of-type(1){
        margin-bottom: 0;
        }
       }
       ul{
        display: inline-flex;
        margin-top: 40px;
        li{
          a{
            color: #ffffff;
            font-size: 16px;
            margin-right: 15px;
            padding: 5px;
          }
        }
       }
      }
     }
     
     @media(max-width:991px){
        .map-right{
          padding-left: 30px;
        }
     }
     
     @media(max-width:767px){
       .map-right,.map-left{
        max-width: 100%;
        flex: 0 0 100%;
        padding: 60px 15px;
        height: auto;
       }
       .map-right__content h4{
        font-size: 20px;
        line-height: 27px;
       }
       
       .map-left{
        height: 500px;
       }
       
       .map-right__content__inner {
            margin-top: 40px;
            p a{
              font-size: 16px;
              line-height: 20px;
            }
        }
        
     }
     
     
     .footer-menu{
     display: block !important;
      li{
      display: inline-block !important;
        a{
        position: relative;
        padding-right: 20px !important;
        display: inline-block;
        margin-right: 10px !important;
        text-transform: capitalize;
          &:after{
            position: absolute;
            content: '|';
            color: rgba(255,255,255,0.38);
            right: 0;
            bottom: 6px;
          }
        }
        &:nth-last-of-type(1) a {
            margin-right: 0;
            &:after{
              display: none;
            }
          }
      }
      
     }
     .footer-logo{
      position: relative;
      height: 60px;
     }
   
`;

export default GoogleApiWrapper({
    apiKey: MAP_API_KEY
})(Maps);
