import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {NEWS} from "../../constants/news";
import {ApiServices} from "../../../network/ApiServices";

function* fetchNews(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: NEWS.FETCH_NEWS.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)
        yield put({
            type: NEWS.FETCH_NEWS.FAILURE
        })
    }
}


function* fetchNewsDetails(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_POST_BY_SLUG,
            actions.params
        )

        yield put({
            type: NEWS.FETCH_NEWS_DETAILS.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)
        yield put({
            type: NEWS.FETCH_NEWS_DETAILS.FAILURE
        })
    }
}

export default function* newsSaga() {
    yield takeLatest(NEWS.FETCH_NEWS.MAIN, fetchNews)
    yield takeLatest(NEWS.FETCH_NEWS_DETAILS.MAIN, fetchNewsDetails)
}
