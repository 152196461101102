import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {BUYERS} from "../../constants/buyers";
import {ApiServices} from "../../../network/ApiServices";

function* fetchBuyers(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        );

        yield put({
            type: BUYERS.FETCH_BUYERS.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: BUYERS.FETCH_BUYERS.FAILURE
        })
    }
}


export default function* buyersSaga() {
    yield takeLatest(BUYERS.FETCH_BUYERS.MAIN, fetchBuyers)
}
